import {
  Button,
  ButtonBase,
  CardHeader,
  Divider,
  Grid,
  Hidden,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AppSelect from "components/AppSelect";
import CustomTable, { ColumFormat } from "components/CustomTable";
import Iconify from "components/Iconify";
import { NumberInput } from "components/input-fields/NumberInput";
import useAxios from "hooks/useAxios";
import useUserSections from "hooks/useUserSections";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { QueriesKey } from "utils/constants";
import { useAccount } from "wagmi";

type Point = {
  point: number,
  type: string,
  createdAt: string,
  volume: string
}

export const Points = () => {
  const theme = useTheme()
  const { address } = useAccount();
  const { userSections } = useUserSections();
  const { callApi } = useAxios();

  const { data: userPoint, isLoading } = useQuery({
    enabled: !!address && !!userSections?.accessToken,
    queryKey: [QueriesKey.UserPoint, address, userSections?.accessToken],
    queryFn: async () => {
      const response = await callApi<
        any,
        {
          totalPoint: number;
          points: Point[];
        }
      >(`get`, `auth/user-point`);
      return response;
    },
  });

  return (
    <Stack>
      <Divider />
      <Divider />
      <StyledContainer width={'100vw'} mt={{ xs: 1, md: 5 }} maxWidth={1000} margin={'auto'}>
        <Stack mb={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <CardHeader
            sx={{ p: 0 }}
            title={<Typography variant="h5">{t("Your points")}</Typography>}
            subheader={<Typography color={'text.secondary'}>{t("Total points you have accumulated in this campaign")}</Typography>}
          />
        </Stack>
        <Divider />
        <Grid container my={{ xs: 0, sm: 5 }}>
          <Grid item xs={4}>
            <Typography>{t("Total points")}</Typography>
            <Typography variant="h3">{userPoint?.totalPoint}</Typography>
          </Grid>
          <Grid item xs={4} textAlign={'center'}>
            <Typography>{t("Rank")}</Typography>
            <Typography variant="h3">--</Typography>
          </Grid>
          <Grid item xs={4} textAlign={'right'}>
            <Typography>{t("League")}</Typography>
            <Typography variant="h3">--</Typography>
          </Grid>

        </Grid>
        <Grid container>
          <Grid xs={6} pr={2} >
            <TradingVolume />
          </Grid>
          <Grid xs={6} pl={2}>
            <Referral />
          </Grid>
        </Grid>
      </StyledContainer>
    </Stack>
  );
};

const TradingVolume = () => {
  const theme = useTheme()
  const defaultHeader: ColumFormat[] = [
    { id: 'week', label: <AppSelect btnSx={{ background: 'none !important', padding: 0, fontSize: 'inherit', fontWeight: 500, color: theme.palette.text.disabled }} options={[{ key: 'Week', label: 'Week' }]} />, render: (row: Point | any) => <Typography>Week 1 <span style={{ color: theme.palette.text.disabled }}>--/--/-- to --/--/--</span></Typography> },
    { id: 'points', label: `${t("Points")}`, render: (row: Point | any) => row.points },
    { id: 'volume', align: 'right', label: `${t("Volume")}`, render: (row: Point | any) => row.volume },
  ]
  return <StyledCard py={2}>
    <Typography px={2} textAlign={'left'} fontWeight={500}>{t("Trading Volume")}</Typography>
    <Typography mb={2} px={2} textAlign={'left'} color={'text.disabled'}>{t("You will earn 1 point for every 10,000.0 trade volume")}</Typography>
    <CustomTable
      header={defaultHeader}
      data={[]}
      headerSx={{
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.06)}`
      }}
    />
  </StyledCard>
}

const Referral = () => {
  const theme = useTheme()
  const defaultHeader: ColumFormat[] = [
    { id: 'week', label: <AppSelect btnSx={{ background: 'none !important', padding: 0, fontSize: 'inherit', fontWeight: 500, color: theme.palette.text.disabled }} options={[{ key: 'Week', label: 'Week' }]} />, render: (row: Point | any) => <Typography>Week 1 <span style={{ color: theme.palette.text.disabled }}>--/--/-- to --/--/--</span></Typography> },
    { id: 'points', label: `${t("Points")}`, render: (row: Point | any) => row.points },
    { id: 'volume', align: 'right', label: `${t("Volume")}`, render: (row: Point | any) => row.volume },
  ]
  return <StyledCard py={2}>
    <Typography px={2} textAlign={'left'} fontWeight={500}>{t("Referral Volume")}</Typography>
    <Typography mb={2} px={2} textAlign={'left'} color={'text.disabled'}>--</Typography>
    <CustomTable
      header={defaultHeader}
      data={[]}
      headerSx={{
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.06)}`
      }}
    />
  </StyledCard>
}

const StyledContainer = styled(Stack)(({ theme }) => ({
  width: 'calc(100vw - 40px)',
  maxWidth: 1000
}));

const StyledCard = styled(Stack)(({ theme }) => ({
  background: alpha(theme.palette.background.default, 0.02),
  borderRadius: '0.4rem',
  textAlign: 'center',
  height: '100%',
  border: `1px solid ${theme.palette.divider}`
}));
