import { useQuery } from "@tanstack/react-query";
import { useAccount, useChainId, usePublicClient } from "wagmi";
import useUserSections from "./useUserSections";
import { QueriesKey } from "utils/constants";
import useAxios from "./useAxios";
import useSettings from "./useSettings";
import { SettingsContext } from "contexts/settingsContext";
import { useContext } from "react";
import { erc20Abi, getContract } from "viem";
import { ZeroAddress } from "ethers";

const useCapitalInterest = () => {
    const { address } = useAccount();
    const chainId = useChainId()
    const { callApi } = useAxios()
    const { userSections } = useUserSections();

    const { data: capitalInterest } = useQuery({
        enabled: true,
        queryKey: [QueriesKey.CapitalInterest],
        queryFn: async () => {
            const response = await callApi<
                any,
                any
            >("get", "dashboard/capital-interest", {
                chainId: chainId?.toString() ?? '42161'
            });
            return response;
        },
    });
    return capitalInterest;
};

export default useCapitalInterest;
