import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Divider,
  OutlinedInput,
  Stack,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  styled,
  useTheme,
  ButtonBase
} from "@mui/material";
import FlexRowAlign from "components/flexbox/FlexRowAlign"

import Loader from "components/Loader";
import { SettingsContext } from "contexts/settingsContext";
import Decimal from "decimal.js";
import { Form, FormikProvider, useFormik } from "formik";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import {
  fCurrency
} from "utils/format";

import { delay } from "utils/utils";
import {
  useChainId, usePublicClient,
  useWalletClient
} from "wagmi";
import * as Yup from "yup";
import Iconify from "components/Iconify";
import { QueriesKey } from "utils/constants";
import GradientButton from "components/buttons/GradientButton";
import useCapitalInfo from "hooks/useCapitalInfo";
import { getContract } from "viem";
import { capitalProviderABI } from "contracts/abis/capitalProvider";
import { getCapitalProvider } from "contracts/contractAddress";
import { useQueryClient } from "@tanstack/react-query";
import AppModal from "components/AppModal";
import LoadingButton from "@mui/lab/LoadingButton";
import { NumberInput } from "components/input-fields/NumberInput";
import GradientText from "components/GradientText"
import PrimaryButton from "components/buttons/PrimaryButton"




export const WithdrawCapitalForm = ({ onClose, address }: { onClose?: () => void, address: `0x${string}` }) => {
  const theme = useTheme();
  const chainId = useChainId();
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useContext(SettingsContext);
  const { data: walletClient } = useWalletClient({ account: address });
  const [submitting, setSubmitting] = useState(false);
  const [confirmStep, setConfirmStep] = useState(0);
  const publicClient = usePublicClient();
  const { currentPair } = settings;
  const capitalInfo = useCapitalInfo();
  const queryClient = useQueryClient();
  const max = new Decimal(capitalInfo?.capitalBalance?.toString() ?? 0)
    .div(Decimal.pow(10, 6))
    .toNumber();
  const yieldBalance = new Decimal(capitalInfo?.capitalBalance?.toString() ?? 0)
    .div(Decimal.pow(10, 6))
    .toNumber();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const Schema = Yup.object().shape({
    amount: Yup.number()
      .required(`${t("Please input amount as a number.")}`)
      .min(1)
      .max(parseFloat(max.toString() || "0")),
  });


  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Schema,
    validateOnBlur: false,
    onSubmit: async () => {
      if (
        !walletClient ||
        !publicClient ||
        !address
      )
        return;
      setSubmitting(true);
      try {
        setConfirmStep(-1);
        setShowConfirm(true);
      } catch (e) {
        setConfirmStep(0);
      } finally {
        setSubmitting(false);
        setConfirmStep(-1);
      }
    },
  });



  const handleConfirmPopUp = async () => {
    if (!walletClient || !publicClient || !address) return;
    setShowConfirm(true);
    try {
      if (new Decimal(formik.values.amount).greaterThan(max)) {
        enqueueSnackbar("Insufficient balance", {
          variant: "error",
          autoHideDuration: 2000,
        });
        return;
      } else {
        setConfirmStep(1);

        let amount = BigInt(
          new Decimal(formik.values.amount)
            .mul(Decimal.pow(10, 6))
            .toString(),
        )
        const capitalProviderContract = getContract({
          abi: capitalProviderABI,
          address: getCapitalProvider(chainId) as `0x${string}`,
          client: {
            wallet: walletClient,
          },
        });

        await capitalProviderContract.write.withdraw([amount], {
          gas: BigInt(1_000_000)
        });

        await delay(3000);

        enqueueSnackbar("Your withdrawal have been success", {
          variant: "success",
          autoHideDuration: 2000,
        });
        queryClient.invalidateQueries({
          queryKey: [QueriesKey.CapitalProviderInfo, QueriesKey.UserQuoteBalance],
          exact: false,
        });
      }
    } catch (e: any) {
      enqueueSnackbar(e.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    } finally {
      setShowConfirm(false);
      setConfirmStep(-1);
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setConfirmStep(-1);
  };

  const handleRejectConfirm = () => {
    setShowConfirm(false);
    setConfirmStep(0);
  };

  const { handleSubmit, getFieldProps, errors, handleReset } = formik;

  useEffect(() => {
    handleReset({ amount: "" });
  }, [handleReset]);

  const steps = [
    {
      label: "Verify information",
      description: t("Please confirm on your wallet"),
    },
    {
      label: "Withdraw progress",
      description: t("Please wait"),
    },
  ];

  return <>
    <Stack
      flexGrow={1}
      alignItems={"center"}
      p={onClose ? 2 : 0}
      spacing={5}
      sx={{ position: "relative" }}
    >
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Stack minWidth={"100%"} spacing={2}>
            <Stack spacing={2}>
              <FlexRowAlign justifyContent={'flex-end'} gap={1}>
                <Typography color={'text.secondary'}>Your balance:</Typography>
                <Typography>{fCurrency(yieldBalance, 6)} USDC</Typography>
              </FlexRowAlign>
              <FormControl>
                <NumberInput
                  placeholder="0.00"
                  {...getFieldProps("amount")}
                  InputProps={{
                    sx: {
                      height: 80,
                      borderRadius: 0,
                      '& input': {
                        fontSize: '1.7rem',
                        pl: 3
                      }
                    },
                    endAdornment: <Stack spacing={1} height={'100%'} justifyContent={'center'} alignItems={'flex-end'}>
                      <Typography whiteSpace={'nowrap'} color={'text.secondary'}>Available: {max} USDC</Typography>
                      <ButtonBase onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          amount: max.toString() || "",
                        })
                      } sx={{ background: theme.palette.background.button, width: 'fit-content', padding: '5px 1.5rem', borderRadius: '0.3rem' }}>
                        <GradientText

                          text="MAX"
                          background="linear-gradient(25deg,#FA00FF,#B580FF 25%,#FFFFFF 35%,#FFFFFF 80%)"
                          fontSize={'1rem'}
                        />
                      </ButtonBase>
                    </Stack>
                  }}
                />
                <FormHelperText error>{errors.amount}</FormHelperText>
                <Divider sx={{
                            color: theme.palette.text.primary,
                            opacity: 1,
                            fontStyle: 'italic',
                            '&:before, &:after': {
                                border: `1px solid ${theme.palette.text.primary}`,
                            }
                        }} >Cautious! Withdrawal will cost you points.</Divider>
              </FormControl>
              
            </Stack>
          
            <GradientButton loading={submitting} disabled={submitting} sx={{ height: onClose ? 45 : 50, fontSize: onClose ? '' : '1rem', textTransform: onClose ? '' : 'uppercase' }} type="submit">
              {t("Withdraw")}
            </GradientButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Loader open={submitting} />
    </Stack>
    <AppModal open={showConfirm} onOpen={() => { }} onClose={() => { }}>
      <Stack spacing={3}>
        <Stack spacing={1} pt={2}>
          <Typography variant="h6" textAlign={"center"} color={"text.primary"}>
            {t("Withdraw")} USDC
          </Typography>
          <Typography variant="h4" textAlign={"center"} color={"text.primary"}>
            {fCurrency(formik.values.amount, 2)}
          </Typography>
          <Typography textAlign={'center'} color={"text.disabled"}>
            {"Please confirm your request"}
          </Typography>
        </Stack>
        <Collapse in={confirmStep >= 0}>
          <Divider />
          <StyledStepper activeStep={confirmStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={StepIcon}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontWeight={600} className="title" pl={1}>
                      {step.label}
                    </Typography>
                    <Iconify
                      className="completed"
                      icon={"line-md:confirm"}
                      sx={{ color: theme.palette.success.main }}
                    />
                  </Stack>
                </StepLabel>
                <StepContent>
                  <Typography variant="body2" color={"text.secondary"}>
                    {step.description}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </StyledStepper>
        </Collapse>
        <Stack direction={"row"} alignItems={"center"} width='100%' spacing={2}>
          <PrimaryButton
            onClick={() => handleCloseConfirm()}
            shadowSx={{
              display: 'none'
            }}
            sx={{
              background: theme.palette.background.button,
              width: '100%',
              '&:hover': {
                background: theme.palette.background.buttonActive
              }
            }}
          >
            {t("Cancel")}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleConfirmPopUp()}
            loading={confirmStep >= 0}
          >
            {t("Confirm")}
          </PrimaryButton>
        </Stack>
      </Stack>
    </AppModal>
  </>
}

type ConfirmProps = {
  open: boolean;
  background?: string;
  info?: {
    // asset: string | undefined;
    amount: number | string | undefined;
    fee?: number | undefined;
    // margin?: string;
  };
  activeStep: number;
};

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
  background: theme.palette.warning.main,
  height: 45,
  borderRadius: "3px",
  color: "#191818",
  fontSize: "0.9rem",
  fontWeight: 600,
  width: "100%",
  "&:hover": {
    background: theme.palette.warning.dark,
  },
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const theme = useTheme();

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <Iconify
        icon="material-symbols-light:order-approve-rounded"
        size={20}
        sx={{ color: `text.${active ? "primary" : "secondary"}` }}
      />
    ),
    2: (
      <Iconify
        icon="mdi:sign"
        size={20}
        sx={{ color: `text.${active ? "primary" : "secondary"}` }}
      />
    ),
    3: (
      <Iconify
        icon="fa6-solid:download"
        size={15}
        sx={{ color: `text.${active ? "primary" : "secondary"}` }}
      />
    ),
  };

  return (
    <StyledStepIcon className={active ? "active" : ""}>
      {icons[String(props.icon)]}
    </StyledStepIcon>
  );
}

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  height: 45,
  background: theme.palette.background.input,
  borderRadius: "3px",
  overflow: "hidden",
  fontWeight: 500,
  border: "1px solid",
  borderColor: "transparent",
  "&:hover, &.Mui-focused": {
    borderColor: theme.palette.primary.main,
  },
  "&.Mui-disabled": {
    borderColor: "transparent",
  },
  "& fieldset": {
    border: "none",
  },
  "& .iconify": {
    width: 20,
    height: 20,
  },
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  width: "100%",
  padding: "2rem 1rem",
  "& .MuiStepConnector-root, & .MuiStepContent-root": {
    marginLeft: "19px",
    borderLeft: `2px solid ${theme.palette.divider}`,
    paddingLeft: "2.2rem",
    "& .MuiStepConnector-line": {
      border: "none",
    },
  },
  "& .MuiStepContent-last ": {
    borderLeft: "none !important",
  },
  "& .iconify.completed": {
    opacity: 0,
  },
  "& .Mui-completed": {
    "& .title": {
      opacity: 0.4,
    },
    "& .iconify.completed": {
      opacity: 1,
    },
  },
}));

const StyledStepIcon = styled(Stack)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 40,
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.action.hover,
  position: "relative",
  "&.active": {
    background: theme.palette.primary.dark,
    color: "#fff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 0.6s infinite ease-in-out",
      border: `2px solid ${theme.palette.primary.main}`,
      content: '""',
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.9)",
        opacity: 0.4,
      },
      "100%": {
        transform: "scale(1.4)",
        opacity: 0,
      },
    },
  },
}));
