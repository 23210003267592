import { createConfig, http } from "wagmi";
import {
    arbitrum,
    sepolia,
    arbitrumSepolia,
    base
} from "wagmi/chains";
import { getDefaultConfig } from "connectkit";


export const SupportedChains = [base,arbitrum ,arbitrumSepolia];

export const config = createConfig(
    getDefaultConfig({
        // Required API Keys
        walletConnectProjectId: "5c9adeb706990ce6cabec63da61270fa",
        //Chains
        cacheTime: 10_000,
        chains: [base,arbitrum ,arbitrumSepolia],
        transports: {
            // RPC URL for each chain
            [sepolia.id]: http(
                `https://eth-sepolia.g.alchemy.com/v2/_8hM_H2lFo-7ub_l5x8x01AhpdUMkRJm`,
            ),
            [arbitrumSepolia.id]: http(
                `https://arbitrum-sepolia.blockpi.network/v1/rpc/f3144180d974705270447c0712fc667a4ceee981`,
            ),
            [arbitrum.id]: http(
                `https://arb-mainnet.g.alchemy.com/v2/pjImOtW83VEcpMWAlYFRXmV51qJqrIni`,
            ),
            [base.id]: http(
                `https://base-mainnet.g.alchemy.com/v2/lEuCs1doCIcuyU67LnUBprKTJm618tVc`,
            )
        },
        // Required
        appName: "Flaex Flash",
        batch: {
            multicall: true,
        },
        // Optional
        appDescription: "flaex | DEX | decentralized exchange",
        appUrl: "https://family.co", // your app's url
        appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    })
);
