import { useQuery } from "@tanstack/react-query";
import { useAccount, usePublicClient } from "wagmi";
import useUserSections from "./useUserSections";
import { QueriesKey } from "utils/constants";
import useAxios from "./useAxios";
import useSettings from "./useSettings";
import { SettingsContext } from "contexts/settingsContext";
import { useContext } from "react";
import { erc20Abi, getContract } from "viem";
import { ZeroAddress } from "ethers";

const useUserBalance = (quoteTokenAddress?: `0x${string}` | undefined) => {
  const { address } = useAccount();
  const { settings } = useContext(SettingsContext);
  const { currentPair } = settings;
  const publicClient = usePublicClient();
  const quoteToken = quoteTokenAddress ?? currentPair?.quoteTokenAddress;

  const { data: userBalance, isLoading } = useQuery({
    enabled: !!address && !!quoteToken,
    queryKey: [QueriesKey.UserQuoteBalance, quoteToken],
    queryFn: async () => {
      if (!publicClient || !address || !quoteToken) return BigInt(0);
      const contract = getContract({
        abi: erc20Abi,
        address: quoteToken || ZeroAddress,
        client: publicClient,
      });

      const balance = contract.read.balanceOf([address]);
      return balance;
    },
  });
  return userBalance;
};

export default useUserBalance;
