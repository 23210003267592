import { combineReducers } from 'redux';
import UserReducer, { UserState } from './UserReducer';
import TradeReducer, { TradeState } from './TradeReducer';

export type RootState = {
    user: UserState,
    trade: TradeState
}

const rootReducer = combineReducers({
    user: UserReducer,
    trade: TradeReducer
    // Add more reducers here if needed
});

export default rootReducer;
