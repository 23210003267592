import useLocalStorage from "hooks/useLocalStorage";
import { createContext, ReactNode } from "react";
import { tradeSettingsTypes } from "theme";

export const PriceModes = [
  {
    key: "MARKET",
    title: "Market",
  },
  {
    key: "LIMIT",
    title: "Limit",
  },
];

const initialSettings: tradeSettingsTypes = {
  priceMode: "MARKET",
  currentTradeMode: 'CROSS',
  leverages: [],
  hideOtherSymbols: false,
};

export const TradeSettingsContext = createContext({
  tradeSettings: initialSettings,
  saveTradeSettings: (arg: tradeSettingsTypes) => {},
});

// component props type
type SettingsProviderProps = {
  children: ReactNode;
};

const TradeSettingsProvider = ({ children }: SettingsProviderProps) => {
  const { data: tradeSettings, storeData: setStoreSettings } = useLocalStorage(
    "tradeSettings",
    initialSettings
  );

  const saveTradeSettings = (updateSettings: tradeSettingsTypes) => {
    setStoreSettings(updateSettings);
  };

  return (
    <TradeSettingsContext.Provider value={{ tradeSettings, saveTradeSettings }}>
      {children}
    </TradeSettingsContext.Provider>
  );
};

export default TradeSettingsProvider;
