import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import LoadingScreen from "components/LoadingScreen";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import { LeaderBoard } from "pages/LeaderBoard";
import { CapitalProvider } from "pages/CapitalProvider";
import { Points } from "pages/Points";
import DepositCapitalProviderPage from "pages/deposit-capital-provider";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Trading = Loadable(lazy(() => import("./pages/Trading")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const Earn = Loadable(lazy(() => import("./pages/earn")));
const AppAnalysis = Loadable(lazy(() => import("./pages/AppAnalysis")));
const TradingFund = Loadable(lazy(() => import("./pages/TradingFund")));

const Error = Loadable(lazy(() => import("./pages/404")));

const ActiveLayout = () => {
  const { settings } = useSettings();
  switch (settings.activeLayout) {
    case "layout3":
      return <LayoutV3 />;
    default:
      return <LayoutV3 />;
  }
};

const routes = () => {
  return [
    // {
    //   path: "",
    //   element: <ComingSoon />,
    // },
    ...authRoutes,
    {
      path: "dashboard",
      element: <ActiveLayout />,
      children: dashboardRoutes,
    },
    {
      path: "trading",
      element: <ActiveLayout />,
      children: tradingRoutes,
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};

const authRoutes = [{ path: "/", element: <Navigate to="/trading" /> }];

const tradingRoutes = [
  { path: "", element: <Trading /> },
  { path: "leaderboard", element: <LeaderBoard /> },
  { path: "capital-provider", element: <DepositCapitalProviderPage /> },
  { path: "points", element: <Points /> },
];
const dashboardRoutes = [
  { path: "", element: <Dashboard /> },
  { path: "stats", element: <TradingFund /> },
  // { path: "trading-fund", element: <TradingFund /> },
  { path: "earn", element: <Earn /> },
];

export default routes;
