
const Local = {
    API: process.env.REACT_APP_IS_LOCAL === 'true' ? "http://localhost:2000/" : 'https://api.dev.flaex.io/',
    CAPTCHA_KEY: "",
    SOCKET: process.env.REACT_APP_IS_LOCAL === 'true' ? "http://localhost:2000/" : 'https://api.dev.flaex.io/',
};

const Production = {
    API: "https://api.flaex.io/",
    CAPTCHA_KEY: "",
    SOCKET: 'https://api.dev.flaex.io/',
};

/// env here...
export const isProduction = process.env.REACT_APP_IS_PRODUCTION;

export const API = isProduction ? Production.API : Local.API;

export const CAPTCHA_KEY = isProduction
    ? Production.CAPTCHA_KEY
    : Local.CAPTCHA_KEY;

export const SOCKET = isProduction ? Production.SOCKET : Local.SOCKET;