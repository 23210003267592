import {
  alpha,
  Modal,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  styled,
  TableHeadProps,
  TableContainerProps,
} from "@mui/material";
import { BoxProps } from "@mui/system";
import { FC, ReactNode, useEffect, useState } from "react";
import { lightTheme } from "../constants";
import { t } from "i18next";
import Iconify from "./Iconify";

// ---------------------------------------------------------
type CustomTableProps = {
  header: ColumFormat[];
  data?: any[];
  onSort?: (param: SortParameter | undefined) => void;
  disableColumn?: string[];
  rowBg?: string;
  maxHeight?: number | string;
  disabledHeader?: boolean;
  headerSx?: TableHeadProps["sx"];
  sx?: TableContainerProps["sx"];
};
export type ColumFormat = {
  id: string;
  label?: string | ReactNode;
  align?: "left" | "center" | "right";
  render?: (row: any) => void;
};
export type SortParameter = {
  key: string;
  ordering: string;
};
// ---------------------------------------------------------
const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  position: "relative",
  minHeight: 300,
}));
const HeaderStyled = styled(TableHead)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.background.paper}`,
  borderBottom: `1px solid ${theme.palette.background.paper}`,
}));
const HeaderCellStyled = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.disabled,
  padding: "0.5rem 1rem",
  paddingLeft: "1rem !important",
  paddingRight: "1rem !important",
  cursor: "pointer",
  "&.active": {
    background: alpha(theme.palette.divider, 0.05),
  },
}));
const NotfoundRow = styled(TableCell)(({ theme }) => ({
  height: 150,
  color: theme.palette.text.disabled,
  opacity: 0.5,
}));

const OrderingIcon = styled(Iconify)(({ theme }) => ({
  position: "absolute",
  right: 0,
  width: 17,
  height: 17,
}));

const SortOrdering = [
  { key: "none", icon: "" },
  { key: "asc", icon: <OrderingIcon icon="majesticons:arrow-up" /> },
  { key: "desc", icon: <OrderingIcon icon="majesticons:arrow-down" /> },
];

const CustomTable: FC<CustomTableProps & TableProps> = ({
  header = [],
  data = [],
  onSort,
  disableColumn = [],
  rowBg = "",
  maxHeight = 300,
  disabledHeader,
  headerSx,
  sx,
  ...props
}) => {
  const [sortKey, setSortKey] = useState("");
  const [orderIndex, setOrderIndex] = useState(0);
  const ordering = SortOrdering[orderIndex % 3];
  const handleChangeSortKey = (key: string) => {
    setSortKey(key);
    if (sortKey === key) {
      setOrderIndex(orderIndex + 1);
    } else {
      setOrderIndex(1);
    }
  };
  useEffect(() => {
    if (onSort) {
      onSort(
        ordering.key !== "none"
          ? { key: sortKey, ordering: ordering.key }
          : undefined
      );
    }
  }, [sortKey, orderIndex]);

  const aligns = { right: "flex-end", left: "flex-start", center: "center" };

  return (
    <TableContainerStyled sx={{ maxHeight: maxHeight, ...sx }}>
      <Table stickyHeader>
        {!disabledHeader && (
          <HeaderStyled sx={{ ...headerSx }}>
            <TableRow>
              {header.map(
                (col) =>
                  !disableColumn.includes(col.id) && (
                    <HeaderCellStyled
                      sx={{
                        textAlign: col.align,
                        background: "none",
                        ...headerSx,
                      }}
                      key={col.id}
                      onClick={() => onSort && handleChangeSortKey(col.id)}
                      className={
                        sortKey === col.id && ordering?.key !== "none"
                          ? "active"
                          : ""
                      }
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={aligns[`${col.align || "left"}`]}
                        sx={{ position: "relative" }}
                      >
                        {col.label || col.id}
                        {sortKey === col.id && ordering?.icon}
                      </Stack>
                    </HeaderCellStyled>
                  )
              )}
            </TableRow>
          </HeaderStyled>
        )}
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:nth-of-type(odd)": {
                  backgroundColor: rowBg,
                },
                // hide last border
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              {header.map(
                (col) =>
                  !disableColumn.includes(col.id) && (
                    <TableCell
                      sx={{
                        textAlign: col.align,
                        justifyContent: col.align,
                        padding: "0.4rem 1rem !important",
                      }}
                      key={`data-${col.id}`}
                    >
                      {col.render ? col.render(row) : row[col.id]}
                    </TableCell>
                  )
              )}
            </TableRow>
          ))}
          {data.length === 0 && (
            <TableRow>
              <NotfoundRow align="center" colSpan={header.length}>
                {t("Not found")}
              </NotfoundRow>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};

export default CustomTable;
