import {
  IconButton,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  styled,
  useTheme,
} from "@mui/material";
import Iconify from "components/Iconify";
import { FC, ReactNode, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// component props types
type Address = {
  address?: string;
  size?: number;
  sx?: StackProps["sx"];
  other?: any;
  allowCopy?: boolean;
  iconSize?: number;
  textSx?: TypographyProps["sx"];
  children?: ReactNode;
};

const CopyButton = styled(Iconify)(({ theme }) => ({
  color: theme.palette.text.disabled,
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.warning.main,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Address: FC<Address> = ({
  address = "",
  size = 0,
  sx,
  allowCopy,
  iconSize,
  textSx,
  children,
  ...props
}) => {
  const theme = useTheme();
  const truncatedText = `${address.slice(0, size)}...${address.slice(-size)}`;
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return address ? (
    <Stack
      flexGrow={1}
      sx={{ ...sx }}
      {...props}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {children ? (
        children
      ) : (
        <Typography sx={{ ...textSx }}>
          {size > 0 ? truncatedText : address}
        </Typography>
      )}
      {allowCopy && (
        <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
          <IconButton sx={{ padding: 0, background: "none !important" }}>
            <CopyButton
              size={iconSize}
              sx={{
                color: copied ? `${theme.palette.success.main} !important` : "",
              }}
              icon={copied ? "line-md:confirm" : "ph:copy-simple-duotone"}
            />
          </IconButton>
        </CopyToClipboard>
      )}
    </Stack>
  ) : null;
};

export default Address;
