import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Hidden,
  IconButton,
  Stack,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useState } from "react";
import SettingPopover from "./popovers/SettingPopover";
import ProfilePopover from "./popovers/ProfilePopover";
import LiveTransaction from "sections/transactions/LiveTransaction";
import Iconify from "components/Iconify";
import { useLocation, useNavigate } from "react-router-dom";
import { navigation } from "./navigation";
import { useChainId, useWalletClient } from "wagmi";
import { getContract } from "viem";
import { erc20ABI } from "contracts/abis/erc20";
import { useSnackbar } from "notistack";
import { MainMenu } from "./MainMenu";
import useAxios from "hooks/useAxios";
import useUserSections from "hooks/useUserSections";
import { useQueryClient } from "@tanstack/react-query";
import { QueriesKey } from "utils/constants";

// ------------------------------------------------
type DashboardHeaderProps = {
  setShowSideBar: () => void;
  setShowMobileSideBar: () => void;
};
// ------------------------------------------------

// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  padding: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
}));

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: 40,
  height: 40,
  color: theme.palette.text.secondary,
  "&:hover": { backgroundColor: theme.palette.action.active },
}));

const DashboardHeader: FC<DashboardHeaderProps> = (props) => {
  const navigate = useNavigate();
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();
  const chainId = useChainId();
  const { data: walletClient } = useWalletClient();
  const [isFaceting, setIsFaceting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userSections } = useUserSections();
  const queryClient = useQueryClient();

  const { callApi } = useAxios();
  const faucet = async () => {
    if (!walletClient || !chainId || !userSections.accessToken) return;
    setIsFaceting(true);
    try {
      const contract = getContract({
        abi: erc20ABI,
        address: "0x6689C7C91Eb8dDc19fb9C04bBE7aC23f3c8fa6A7" as `0x${string}`,
        client: {
          wallet: walletClient,
        },
      });
      const data = await callApi<any, any>("post", "trading/faucet", {
        chainId: chainId,
      });
      if (data.success === false) {
        enqueueSnackbar(data.message, {
          variant: "error",
          autoHideDuration: 1000,
        });
        return;
      }

      await contract.write.mint();

      enqueueSnackbar("Our tUSDC have been transferred to your wallet", {
        variant: "success",
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({
        queryKey: [QueriesKey.UserQuoteBalance],
      });
    } catch (e) {
      enqueueSnackbar("You have minted tUSDC token", {
        variant: "error",
        autoHideDuration: 1000,
      });
    } finally {
      setIsFaceting(false);
    }
  };

  return (
    <DashboardHeaderRoot position={"relative"}>
      <StyledToolBar>
        <Hidden mdDown>
          <img
            onClick={() => navigate("/trading")}
            src={`/logo.png`}
            alt="logo-full"
            style={{ height: "25px", cursor: "pointer" }}
          />
        </Hidden>
        <MainMenu />
        <Box flexGrow={1} ml={1} />
        <Stack direction={"row"} spacing={1}>
          {chainId === 421614 ?  <Button
            disabled={isFaceting}
            sx={{ height: 38, borderRadius: 40, minWidth: 100, color: "#000" }}
            variant="contained"
            color="warning"
            onClick={faucet}
          >
            Faucet
          </Button> : <></>}
          <LiveTransaction background={theme.palette.background.default}>
            <StyledIconButton>
              <Iconify icon="ri:token-swap-fill" />
            </StyledIconButton>
          </LiveTransaction>
          <ProfilePopover />
          {upSm && (
            <Stack direction={"row"} spacing={0.5}>
              <SettingPopover />
            </Stack>
          )}
        </Stack>
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
