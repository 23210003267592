import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { alpha, ButtonProps, styled } from "@mui/material";

type GradientButtonProps = {
  children?: any;
} & ButtonProps & LoadingButtonProps;

const CustomButton = styled(LoadingButton)(({ theme }) => ({
  background: "linear-gradient(90deg, #8001FE 0%, #FDA9E5 100%)",
  color: "#000",
  fontSize: '0.85rem',
  padding: "10px 40px",
  textTransform: "none", // Prevent uppercase transformation
  border: "none",
  borderRadius: "0",
  clipPath:
    "polygon( 2.949% 100%,94.766% 100%,100% 15.179%,97.051% 0%,5.234% 0%,0% 84.821%,2.949% 100% )", // Hexagonal shape with two cut corners
  "&:hover": {
    background: "linear-gradient(90deg, #8001FE 0%, #FDA9E5 100%)",
    opacity: 0.9,
  },
  '& .MuiCircularProgress-root': {
    color: '#000'
  },
  '&.Mui-disabled': {
    background: `${alpha(theme.palette.text.disabled, 0.2)} !important`
  }
}));

export default function GradientButton({
  children,
  ...props
}: GradientButtonProps) {
  return <CustomButton {...props}>{children}</CustomButton>;
}
