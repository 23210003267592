import { Box, IconButton, Stack, Tooltip, Typography, alpha, colors, styled, useTheme } from "@mui/material";
import Iconify from "components/Iconify";
import { SettingsContext } from "contexts/settingsContext";
import useLocalStorage from "hooks/useLocalStorage";
import { t } from "i18next";
import { FC, useContext, useEffect } from "react";
import { AdvancedChart } from 'react-tradingview-embed';
import { Pair } from "types/tradePairs";


// ------------------------------------------------------------------

type FavoriteButtonProps = {
    pair: Pair,
    disabled?: boolean,
    size?: number
};

const LikeButton = styled(Iconify)(({ theme }) => ({
    color: theme.palette.text.disabled,
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.warning.main
    }
}));

const FavoriteButton: FC<FavoriteButtonProps> = ({ pair, disabled, size = 18 }) => {
    const theme = useTheme()
    const { data: favoritePairs, storeData: setFavoritePairs } = useLocalStorage('favoritePairs', [])
    const isLiked = Boolean(favoritePairs?.find((item: string) => item === pair.label))
    const handleTrigger = () => {
        var output = favoritePairs?.filter((item: string) => item !== pair.label) || []
        if (!isLiked && pair?.label) {
            output?.push(pair?.label)
        }
        setFavoritePairs(output)
    }
    return (
        <Tooltip arrow title={`${isLiked ? t("Remove from favorite list.") : t("Add to favorite list.")}`}>
            <IconButton disabled={disabled} onClick={handleTrigger} sx={{ padding: disabled ? 0 : '' }}>
                <LikeButton
                    icon={'iconoir:star-solid'}
                    sx={{
                        color: isLiked ? `${theme.palette.warning.main} !important` : '',
                        width: `${size}px !important`,
                        height: `${size}px !important`
                    }}
                />
            </IconButton>
        </Tooltip>

    );
};

export default FavoriteButton;
