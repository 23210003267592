import { alpha } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteColor {
    100: string;
    200: string;
    300: string;
    400: string;
    red?: string;
    purple?: string;
    yellow?: string;
  }
  interface TypeBackground {
    input: string
    button: string,
    buttonActive: string
  }
}

const primaryMain = "#DB7BEB";

export const primary = {
  light: "#E5F3FD",
  main: primaryMain,
  100: alpha(primaryMain, 0.08),
  200: alpha(primaryMain, 0.2),
  300: alpha(primaryMain, 0.3),
  400: alpha(primaryMain, 0.4),
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
};

const secondaryMain = "#23C657";

export const secondary = {
  light: "#E3F0FF",
  main: secondaryMain,
  dark: "#011E3D",
  100: alpha(secondaryMain, 0.08),
  200: alpha(secondaryMain, 0.2),
  300: alpha(secondaryMain, 0.3),
  400: alpha(secondaryMain, 0.4),
};

export const info = {
  light: "#F4F4FF",
  main: "#8C8DFF",
  dark: "#0C53B7",
};
export const success = {
  light: "#EAFBF4",
  main: "#00FFC2",
  dark: "#1e3f31",
};
export const warning = {
  light: "#FFFAF2",
  main: "#fbc02c",
  dark: "#9b750d",
};
export const error = {
  light: "#ed9ea9",
  main: "#FD3D52",
  dark: "#4f2d30",
};

// For light theme
export const greyLight = {
  50: "#f9f9f9",
  100: "#eff3f5",
  200: "#D3E6F3",
  300: "#DDE1EE",
  400: "#8CA3BA",
  500: "#5F748D",
  600: "#455A79",
  700: "#2F4365",
  800: "#1E2E51",
  900: "#121F43",
};

// For dark theme
export const greyDark = {
  900: "#1D1C3C",
  800: "#1d1c3c",
  700: "#b4b9c1",
  600: "#a1a6af",
  500: "#5F748D",
  400: "#666b72",
  300: "#3d4a59",
  200: "#303135",
  100: "#242528",
  50: "#17191c",
};

// For Light theme
export const textLight = {
  primary: greyLight[900],
  secondary: greyLight[500],
  disabled: greyLight[400],
};

// For Dark theme
export const textDark = {
  primary: "#f0f0f0",
  secondary: '#6E698D',
  disabled: '#C0BCCC',
};

// For Light theme
export const actionLight = {
  activatedOpacity: 0.12,
  active: alpha(greyLight[900], 0.08),
  disabled: greyLight[300],
  disabledBackground: alpha(greyLight[900], 0.06),
  disabledOpacity: 0.38,
  focus: alpha(greyLight[900], 0.12),
  focusOpacity: 0.12,
  hover: alpha(greyLight[900], 0.04),
  hoverOpacity: 0.04,
  selected: greyLight[100],
  selectedOpacity: 0.08,
};
// For Light theme
export const actionDark = {
  activatedOpacity: 0.12,
  active: alpha(greyDark[900], 0.08),
  disabled: alpha(greyDark[900], 0.1),
  disabledBackground: alpha(greyDark[900], 0.05),
  disabledOpacity: 0.38,
  focus: alpha(greyDark[900], 0.12),
  focusOpacity: 0.12,
  hover: alpha(greyDark[900], 0.04),
  hoverOpacity: 0.04,
  selected: greyDark[100],
  selectedOpacity: 0.08,
};

// Common colors
const palette = {
  info,
  error,
  primary,
  success,
  warning,
  secondary,
};

export const lightPalette = {
  ...palette,
  mode: "light",
  grey: greyLight,
  text: textLight,
  action: actionLight,
  divider: greyLight[200],
  background: {
    default: "#fff",
    paper: "#F4F4F8",
    input: greyLight[900],
  },
};

export const darkPalette = {
  ...palette,
  mode: "dark",
  grey: greyDark,
  text: textDark,
  action: actionDark,
  background: {
    default: "#000000",
    paper: "#0d0b16",
    input: '#211B2E',
    button: '#1D1C3C',
    buttonActive: '#232344'
  },
};
