import { BoxProps, Box, Stack, StackProps } from "@mui/material";

type GradientBorderProps = {
  children: React.ReactNode;
  gradient?: string;
  background?: string;
  borderWidth?: string;
} & StackProps;

export default function GradientBorder({
  children,
  gradient,
  background = "#000",
  borderWidth = "1px",
  sx,
  ...props
}: GradientBorderProps) {
  return (
    <Stack
      sx={{
        background:
          gradient ||
          "linear-gradient(30deg,#FA00FF,#B580FF 15%,#FFFFFF 35%,#FFFFFF 80%,#4475F3)" || background,
        padding: borderWidth,
        borderRadius: props.borderRadius,
        height: '100%',
        ...sx,
      }}
    >
      <Stack sx={{ background, borderRadius: props.borderRadius, overflow: 'hidden', height: '100%' }} {...props}>
        {children}
      </Stack>
    </Stack>
  );
}
