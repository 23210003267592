import React, { FC, ReactNode, forwardRef } from "react";
import { CustomContentProps, SnackbarProvider, useSnackbar } from 'notistack';
import { Alert, Button, IconButton, SnackbarContent, Stack, Typography, alpha, styled, useTheme } from "@mui/material";
import Iconify from "components/Iconify";
import { t } from "i18next";

type SnackBarProps = { children?: ReactNode };

const SnackBar: FC<SnackBarProps> = ({ children }) => {
    return <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ horizontal: 'center', vertical: "top" }}
        Components={{
            error: ErrorComponent,
            success: SuccessComponent,
            info: InfoComponent
        }}
    >
        {children}
    </SnackbarProvider>
};

export default SnackBar;

const SnackBarContentStyle = styled(Stack)(({ theme }) => ({
    background: theme.palette.background.paper,
    borderRadius: `5px`,
    padding: '1rem 1.5rem',
    boxShadow: '0px 0px 3px rgba(0,0,0,0.1)',
    position: 'relative',
    "& .iconify": {
        top: '0px',
        right: '0px',
        width: 25,
        height: 25,
        minWidth: 25,
        minHeight: 25,
    },
    '& .error': {
        color: theme.palette.error.main
    },
    '& .success': {
        color: theme.palette.success.main
    },
    '& .message': {
        color: theme.palette.text.secondary
    },
}))

const ErrorComponent = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ ...props }, ref) => {
        const { closeSnackbar } = useSnackbar()
        const theme = useTheme()
        const { id, message } = props
        return (
            <SnackBarContentStyle className="error" ref={ref} key={id}>
                <Stack direction={'row'} spacing={2}>
                    <Iconify className="error msg-icon" icon="pajamas:warning-solid" />
                    <Stack>
                        <Typography fontWeight={600} className="error">{t("Error")}</Typography>
                        <Typography className="message">{message}</Typography>
                    </Stack>
                </Stack>
                <IconButton onClick={() => closeSnackbar(id)} size="small" sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                    <Iconify icon='iconamoon:close-light' sx={{ color: theme.palette.text.disabled, width: '20px', height: '20px' }} />
                </IconButton>
            </SnackBarContentStyle>
        );
    }
);

const SuccessComponent = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ ...props }, ref) => {
        const { closeSnackbar } = useSnackbar()
        const theme = useTheme()
        const { id, message } = props

        return (
            <SnackBarContentStyle className="success" ref={ref} key={id}>
                <Stack direction={'row'} spacing={2}>
                    <Iconify className="success msg-icon" icon="ep:success-filled" />
                    <Stack>
                        <Typography fontWeight={600} className="success">{t("Success")}</Typography>
                        <Typography className="message">{message}</Typography>
                    </Stack>
                </Stack>
                <IconButton onClick={() => closeSnackbar(id)} size="small" sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                    <Iconify icon='iconamoon:close-light' sx={{ color: theme.palette.text.disabled, width: '20px', height: '20px' }} />
                </IconButton>
            </SnackBarContentStyle>
        );
    }
);

const InfoComponent = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ ...props }, ref) => {
        const { closeSnackbar } = useSnackbar()
        const theme = useTheme()
        const { id, message } = props

        return (
            <SnackBarContentStyle className="info" ref={ref} key={id}>
                <Stack direction={'row'} spacing={2}>
                    <Iconify className="msg-icon" icon="mdi:information" />
                    <Stack>
                        <Typography fontWeight={600} >{t("Information")}</Typography>
                        <Typography >{message}</Typography>
                    </Stack>
                </Stack>
                <IconButton onClick={() => closeSnackbar(id)} size="small" sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                    <Iconify icon='iconamoon:close-light' sx={{ color: theme.palette.text.disabled, width: '20px', height: '20px' }} />
                </IconButton>
            </SnackBarContentStyle>
        );
    }
);