import React from "react";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SettingsProvider from "contexts/settingsContext";
import UserSectionsProvider from "contexts/userContext";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/stores";

import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import TradeSettingsProvider from "contexts/tradeSettingsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <TradeSettingsProvider>
          <SettingsProvider>
            <UserSectionsProvider>
              <App />
            </UserSectionsProvider>
          </SettingsProvider>
        </TradeSettingsProvider>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>
);
