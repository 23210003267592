import { ReactNode } from "react";
import { OpenOrder, PnlAnalysis, PnlAnalysis_GetRes } from "types/user";

export const UPDATE_BALANCE = 'UPDATE_BALANCE'
export const UPDATE_PNL_ANALYSIS = 'UPDATE_PNL_ANALYSIS'
export const FETCH_OPEN_ORDER = "FETCH_OPEN_ORDER"

type Action = {
    type: string;
    payload: any;
}
type Balance = {
    available: number,
    unrealized: number,
    change24h?: number,
    change24hPercent?: number,
    asset: string,
    decimal: number
}

export type UserState = {
    balances: Balance[],
    pnlAnalysis: PnlAnalysis | PnlAnalysis_GetRes | null,
    openOrders: OpenOrder[] | null
}

const initialState: UserState = {
    balances: [
        {
            asset: 'USDT',
            change24h: 12.438,
            change24hPercent: 15,
            available: 74.2,
            unrealized: 25.82,
            decimal: 2
        }
    ],
    pnlAnalysis: null,
    openOrders: null
};

const UserReducer = (state = initialState, action: Action) => {
    const { balances } = state
    const { payload } = action
    switch (action.type) {
        case UPDATE_BALANCE:
            var currentBalance = balances.find(item => item.asset === payload.asset)
            return state;
        case UPDATE_PNL_ANALYSIS:
            return { ...state, pnlAnalysis: payload }
        case FETCH_OPEN_ORDER:
            return { ...state, openOrders: payload }

        default:
            return state;
    }
};

export default UserReducer;
