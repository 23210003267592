import {
  Badge,
  BadgeProps,
  Box, ButtonBase,
  CardHeader,
  Divider,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Iconify from "components/Iconify";
import TabPanel from "components/TabPanel";
import { UserContext } from "contexts/userContext";
import useSocket from "hooks/useSocket";
import { t } from "i18next";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import SwipeableViews from "react-swipeable-views";
import { useAccount } from "wagmi";
import { Transaction, TransactionStatus } from "web3/Types";
import { StatusColor } from "../../constants";
import Address from "web3/Address";
import { Link } from "react-router-dom";
import { EventMessage } from "types/socketMessage";
import { ErrorList, QueriesKey } from "utils/constants";
import { useSnackbar } from "notistack";
import History from "./History";

// ------------------------------------------------------------------

type LiveTransactionProps = {
  children?: ReactNode;
  background?: string;
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 3,
    top: 5,
    padding: "0 4px",
  },
}));

const LiveTransaction: FC<LiveTransactionProps> = ({
  children,
  background,
}) => {
  const { userSections, saveUserSections } = useContext(UserContext);
  const { liveTransactions } = userSections;
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { isConnected } = useAccount();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const { on, off } = useSocket();
  const queryClient = useQueryClient();
  const handleEvent = useCallback(
    async (eventTransactions: EventMessage[]) => {
      saveUserSections({
        ...userSections,
        liveTransactions: liveTransactions?.map((transaction) => {
          return {
            ...transaction,
            transaction:
              eventTransactions.find((event) => event.id === transaction.id)
                ?.transactionHash ?? transaction.transaction,
            status:
              eventTransactions.find((event) => event.id === transaction.id)
                ?.status ?? transaction.status,
          };
        }),
      });

      queryClient.invalidateQueries({
        queryKey: [QueriesKey.MarginBalance],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: [QueriesKey.TradingPosition],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: [QueriesKey.OpenOrder],
        exact: false,
      });

      queryClient.invalidateQueries({
        queryKey: [QueriesKey.UserQuoteBalance],
        exact: false,
      });
      const failedList = eventTransactions.filter((t) => !!t.errorCode);
      if (failedList.length) {
        if (
          !!failedList[0].errorCode &&
          Object.keys(ErrorList).includes(failedList[0].errorCode.toString())
        ) {
          const errorCode = failedList[0].errorCode.toString();
          enqueueSnackbar(`Error: ${ErrorList[errorCode]}`, {
            variant: "error",
            autoHideDuration: 1000,
          });
        }
      } else {
        enqueueSnackbar("Your transaction have been processed", {
          variant: "success",
          autoHideDuration: 1000,
        });
      }
    },
    [
      enqueueSnackbar,
      liveTransactions,
      queryClient,
      saveUserSections,
      userSections,
    ]
  );

  useEffect(() => {
    on("TransactionNotify", handleEvent);
    return () => {
      off("TransactionNotify", handleEvent);
    };
  }, [
    enqueueSnackbar,
    handleEvent,
    liveTransactions,
    off,
    on,
    queryClient,
    saveUserSections,
    userSections,
  ]);

  const waitingTxCount = liveTransactions?.filter((item) =>
    ["PENDING", "QUEUED"].includes(item.status)
  )?.length;

  return (
    isConnected && (
      <Stack>
        <StyledBadge
          badgeContent={waitingTxCount}
          color="error"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiBadge-badge": {
              border: `3px solid ${background ? background : theme.palette.background.paper
                }`,
            },
          }}
        >
          <Tooltip title={t("Live transactions")} arrow>
            <Box onClick={() => handleOpenModal()}>{children}</Box>
          </Tooltip>
        </StyledBadge>
        <LayoutDrawer
          open={open}
          onClose={() => handleCloseModal()}
          anchor="right"
          variant="temporary"
          paperSx={{
            border: "none",
            width: "400px",
            background: background || theme.palette.background.default,
            position: "fixed",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pr={2}
          >
            <Typography p={2} variant="h6">
              {t("Live transactions")}
            </Typography>
            <History />
          </Stack>
          <Stack direction={"row"} p={2} pt={0} spacing={1}>
            <WalletButton
              onClick={() => setTabIndex(0)}
              className={tabIndex === 0 ? "active" : ""}
            >
              {t("Waiting")}
            </WalletButton>
            <WalletButton
              onClick={() => setTabIndex(1)}
              className={tabIndex === 1 ? "active" : ""}
            >
              {t("Completed")}
            </WalletButton>
          </Stack>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={Number(tabIndex)}
          >
            <TabPanel
              value={tabIndex}
              sx={{ padding: 0 }}
              index={0}
              dir={theme.direction}
            >
              <TransactionList
                data={liveTransactions || []}
                status={["PENDING", "QUEUED"]}
              />
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              sx={{ padding: `0px !important` }}
              dir={theme.direction}
            >
              <TransactionList
                data={liveTransactions || []}
                status={["SUCCESS", "FAILED"]}
              />
            </TabPanel>
          </SwipeableViews>
        </LayoutDrawer>
      </Stack>
    )
  );
};

export default LiveTransaction;

type TransactionListType = {
  data: Transaction[];
  status: TransactionStatus[];
};

const TransactionList: FC<TransactionListType> = ({ ...props }) => {
  const theme = useTheme();
  const { data = [], status } = props;
  const filteredList = data.filter((item) => status.includes(item.status));
  return (
    <>
      {filteredList.length > 0 ? (
        filteredList.map((item) => <TransactionItem {...item} key={item.id} />)
      ) : (
        <Stack flexGrow={1} pb={3} alignItems={"center"} justifyContent={"center"}>
          <Typography color={theme.palette.text.disabled}>
            {t("Not found")}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const TransactionItem: FC<Transaction> = ({ ...props }) => {
  const { type, token, amount, status, transaction } = props;
  const theme = useTheme();

  return (
    <TransactionItemStyled direction={"row"} spacing={2}>
      <CardHeader
        sx={{ flexGrow: 1 }}
        title={
          <Typography
            lineHeight={"27px"}
            fontSize={"0.8rem"}
            fontWeight={500}
            color={"text.disabled"}
          >
            {type}
          </Typography>
        }
        subheader={<Typography>{`${amount} ${token}`}</Typography>}
      />

      <CardHeader
        sx={{ justifyContent: "flex-end" }}
        title={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{
              color: theme.palette.text.disabled,
              fontWeight: 500,
              textTransform: "capitalize",
              marginRight: "-0.6rem",
            }}
          >
            {t(`${status === "SUCCESS" ? "SUCCESS" : status}`)}
            <Stack
              sx={{
                position: "relative",
                marginBottom: "2px",
                marginLeft: "5px",
              }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Iconify
                icon={"radix-icons:dot-filled"}
                sx={{ color: StatusColor[status || "PENDING"] }}
              />
              {status === "PENDING" && (
                <Iconify
                  icon={"svg-spinners:pulse-ring"}
                  sx={{
                    color: StatusColor[status || "PENDING"],
                    position: "absolute",
                    transform: "scale(1.4)",
                  }}
                />
              )}
            </Stack>
          </Stack>
        }
        subheader={
          !["PENDING", "QUEUED"].includes(status) && (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={1}
              divider={<Divider orientation="vertical" sx={{ height: 13 }} />}
            >
              <Address
                address={transaction}
                sx={{ color: theme.palette.text.secondary }}
                allowCopy
                size={5}
                iconSize={15}
                textSx={{
                  color: theme.palette.text.disabled,
                  marginRight: "10px",
                  marginBottom: "-2px",
                }}
              />
              <Link to={"#"}>
                <Typography
                  color={"text.disabled"}
                  variant="body2"
                  sx={{ "&:hover": { color: theme.palette.warning.main } }}
                >
                  Explorer
                </Typography>
              </Link>
            </Stack>
          )
        }
      />
    </TransactionItemStyled>
  );
};

const TransactionItemStyled = styled(Stack)<BadgeProps>(({ theme }) => ({
  cursor: "pointer",
  padding: "0.5rem 1rem",
  "&:hover": {
    background: theme.palette.action.active,
  },
  "& .iconify": {
    width: 30,
    height: 30,
    "&.receive": {
      color: theme.palette.success.main,
    },
  },
  "& .MuiCardHeader-root": {
    padding: 0,
    "& .MuiCardHeader-title": {
      fontSize: "0.8rem",
      fontWeight: 500,
      color: theme.palette.text.disabled,
    },
    "& .MuiCardHeader-subheader": {
      fontWeight: 500,
    },
  },
}));

const WalletButton = styled(ButtonBase)(({ theme }) => ({
  height: 25,
  padding: "0px 0.5rem",
  fontWeight: 600,
  borderRadius: 2,
  color: theme.palette.text.disabled,
  "&:hover": {
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.text.secondary,
  },
  "&.active": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.text.primary
        : theme.palette.warning.main,
    backgroundColor: theme.palette.action.active,
  },
}));
