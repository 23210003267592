import { ButtonBase, Stack, Typography, styled, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Iconify from "components/Iconify";
import useAxios from "hooks/useAxios";
import useUserEmail from "hooks/useUserEmail";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { QueriesKey } from "utils/constants";
import Address from "web3/Address";
// component props types
type UserRefCode = {
  refCode?: string;
  size?: number;
  other?: any;
  allowCopy?: boolean;
  iconSize?: number;
};

const CopyButton = styled(Iconify)(({ theme }) => ({
  color: theme.palette.text.disabled,
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.warning.main,
  },
}));

const UserRefCode: FC<UserRefCode> = ({ ...props }) => {
  const theme = useTheme();
  const userEmail = useUserEmail();
  const { callApi } = useAxios();
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleGenerateCode = async () => {
    if (!userEmail || !userEmail?.[0]?.email) {
      enqueueSnackbar("Please verify your email first", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }
    const resp = await callApi<any, any>("post", "auth/ref_link");
    if (resp) {
      queryClient.invalidateQueries({
        queryKey: [QueriesKey.UserEmail],
      });
    }
  };

  return (
    <Stack
      py={1}
      px={1.5}
      pl={2.5}
      sx={{
        background: theme.palette.action.disabledBackground,
        borderRadius: "0.3rem",
      }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography color={"text.disabled"}>{t("Referral code")}</Typography>

        {userEmail?.[0]?.refCode ? (
          <Address
            address={`${window.location.href}?refCode=${userEmail?.[0].refCode}`}
            allowCopy
          >
            {userEmail?.[0].refCode}
          </Address>
        ) : (
          <Stack
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>--</Typography>
            <ButtonBase
              onClick={() => handleGenerateCode()}
              sx={{ color: theme.palette.warning.main }}
            >
              {t("Generate code")}
            </ButtonBase>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default UserRefCode;
