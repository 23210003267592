import { ChainId } from "utils/constants";
import { Address } from "viem";

const contractAddresses = {
    isolatedMargin: {
        [ChainId.SEPOLIA]: '0x9fcfF5Aa6397Cd042d1090c8549de1377f6800dc',
        [ChainId.ARBITRUM_SEPOLIA]: '0xD58158079Ac551907aCC56a620687e3dE054039c',
        [ChainId.ARBITRUM_ONE]: '0x8Fa5AB94F22665972862ef7F75904df248d1FA00',
        [ChainId.BASE]: '0x9C6c6105cD6EE7fa17902878Cf685bEDfE698Ec6',
    },
    permit2: {
        [ChainId.SEPOLIA]: '0x0406734070D4a5745588F8BED512b175CB16B93F',
        [ChainId.ARBITRUM_SEPOLIA]: '0x59585AF4D19945872df8605c6308230955e50599',
        [ChainId.ARBITRUM_ONE]: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
        [ChainId.BASE]: '0x000000000022D473030F116dDEE9F6B43aC78BA3',
    },
    signatureExecutor: {
        [ChainId.SEPOLIA]: '0xB3A76a2FBD39D28eA55f38885b5d877558e826cc',
        [ChainId.ARBITRUM_SEPOLIA]: '0x30A6136254f83D87F441fe561d42d6004d2F017D',
        [ChainId.ARBITRUM_ONE]: '0x5FaC21BB20360508bAcDA58A81CD36510Fcb1afB',
        [ChainId.BASE]: '0x996bA5F5DC87D1696727D7dBf3e5985d52FF8160',
    },
    aavePool: {
        [ChainId.SEPOLIA]: '0xa10C8e2A1636BefAb1304DF4301760E6820075CD',
        [ChainId.ARBITRUM_SEPOLIA]: '0x840b95fCD4c06C810F0DcA6d682af526f28aFCf8',
        [ChainId.ARBITRUM_ONE]: '0x794a61358D6845594F94dc1DB02A252b5b4814aD',
        [ChainId.BASE]: '0xA238Dd80C259a72e81d7e4664a9801593F98d1c5',
    },
    crossMargin: {
        [ChainId.SEPOLIA]: '0xe47b4722c20654a63b00ccABF20073C07d06044D',
        [ChainId.ARBITRUM_SEPOLIA]: '0x6804E7163aFAB8E1EaFaAbb972b2D92f4C812A08',
        [ChainId.ARBITRUM_ONE]: '0xbB2160b80f1636054cdEe41E5595fA48aBAC88a2',
        [ChainId.BASE]: '0x6999C081E4842f871aebE05815878B69aBBb60C1',
    },
    factoryAddressV3: {
        [ChainId.SEPOLIA]: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        [ChainId.ARBITRUM_SEPOLIA]: '0x248AB79Bbb9bC29bB72f7Cd42F17e054Fc40188e',
        [ChainId.ARBITRUM_ONE]: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
        [ChainId.BASE]: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
    },
    quoterv2Address: {
        [ChainId.SEPOLIA]: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
        [ChainId.ARBITRUM_SEPOLIA]: '0x2779a0CC1c3e0E44D2542EC3e79e3864Ae93Ef0B',
        [ChainId.ARBITRUM_ONE]: '0x61fFE014bA17989E743c5F6cB21bF9697530B21e',
        [ChainId.BASE]: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a',
    },
    usdcAddress: {
        [ChainId.SEPOLIA]: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
        [ChainId.ARBITRUM_SEPOLIA]: '0x6689C7C91Eb8dDc19fb9C04bBE7aC23f3c8fa6A7',
        [ChainId.ARBITRUM_ONE]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        [ChainId.BASE]: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    },
    flTokenAddress: {
        [ChainId.SEPOLIA]: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
        [ChainId.ARBITRUM_SEPOLIA]: '0x33509917D24603b839c0DfC04A98c4B8Cd89C856',
        [ChainId.ARBITRUM_ONE]: '0xeEa8E5F5403f0e10A3dC854A508762E4B9FCdBC0',
        [ChainId.BASE]: '0xb3c329BCb7BFEddD04937F93667Ede7C6739E00D',
    },
    capitalProvider: {
        [ChainId.SEPOLIA]: '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3',
        [ChainId.ARBITRUM_SEPOLIA]: '0x6BA646D7B5B0F202230681Bd3b0F01ebc090befb',
        [ChainId.ARBITRUM_ONE]: '0x3623Dd7d826c69cf619dbc5C757aaE03089F0042',
        [ChainId.BASE]: '0x8376387a0A802B9A4B73A4eB23AFFFf418dFc5d0',
    }
} as const satisfies Record<string, Record<number, `0x${string}`>>

export type Addresses = {
    [chainId in ChainId]?: Address
}


export const getAddressFromMap = (address: Addresses, chainId?: ChainId): `0x${string}` | undefined => {
    return chainId && address[chainId] ? address[chainId] : address[ChainId.SEPOLIA]
}


export const getIsolatedMarginAddress = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.isolatedMargin, chainId)
}

export const getCrossMarginAddress = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.crossMargin, chainId)
}

export const getPermit2Address = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.permit2, chainId)
}


export const getQuoterV2Address = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.quoterv2Address, chainId)
}

export const getFactoryV3Address = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.factoryAddressV3, chainId)
}

export const signatureExecutorAddress = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.signatureExecutor, chainId)
}

export const getAAVEPoolAddress = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.aavePool, chainId)
}

export const getUSDCAddress = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.usdcAddress, chainId)
}

export const getFlTokenAddress = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.flTokenAddress, chainId)
}

export const getCapitalProvider = (chainId?: number) => {
    return getAddressFromMap(contractAddresses.capitalProvider, chainId)
}



export default contractAddresses;
