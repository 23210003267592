import { useDispatch } from "react-redux";
import { trade_FetchMarkets } from "../redux/actions";
import { Pair_GetReq, Pair_GetRes } from "types/tradePairs";
import { useContext } from "react";
import { SettingsContext } from "contexts/settingsContext";
import useAxios from "./useAxios";
import { getTradeMods } from "utils/utils";
import { TradeSettingsContext } from "contexts/tradeSettingsContext";
import { useQuery } from "@tanstack/react-query";
import { useChainId } from "wagmi";

const useTrade = () => {
  const dispatch = useDispatch();
  const { tradeSettings } = useContext(TradeSettingsContext);
  const { settings, saveSettings } = useContext(SettingsContext);
  const { currentPair } = settings;

  const { callApi } = useAxios();
  const chainId = useChainId();

  const { data: pairs, isLoading } = useQuery({
    queryKey: ["getPairs", chainId],
    queryFn: async () => {
      const response = await callApi<Pair_GetReq, Pair_GetRes[]>(
        `get`,
        `trade-pair`,
        { chainId: chainId }
      );
      dispatch(trade_FetchMarkets(response));
      return response;
    },
  });

  return {
    pairs,
    tradeSettings,
  };
};

export default useTrade;
