import { useEffect, useRef } from "react";
import io, { Socket } from "socket.io-client";
import { SOCKET } from "utils/config";
import useUserSections from "./useUserSections";

interface SocketEventHandlers {
  [eventName: string]: (...args: any[]) => void;
}

const useSocket = (url?: string) => {
  const socketRef = useRef<Socket | null>(null);
  const { userSections } = useUserSections();

  useEffect(() => {
    // Connect to WebSocket server
    if (!userSections.accessToken) return;
    socketRef.current = io(SOCKET, {
      auth: {
        authorization: userSections.accessToken,
      },
    });

    // Renew connection if disconnected
    const renewConnection = () => {
      if (socketRef.current && !socketRef.current.connected) {
        socketRef.current.connect();
      }
    };

    const renewInterval = setInterval(renewConnection, 50000000); // Renew connection every 5 seconds

    // Disconnect WebSocket when unmounting
    return () => {
      clearInterval(renewInterval);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [url, userSections.accessToken]);

  // Function to listen for events
  const on = (eventName: string, callback: (...args: any[]) => void) => {
    if (socketRef.current) {
      socketRef.current.on(eventName, callback);
    }
  };
  const off = (eventName: string, callback: (...args: any[]) => void) => {
    if (socketRef.current) {
      socketRef.current.off(eventName, callback);
    }
  };
  return { on, off };
};

export default useSocket;
