import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState, ReactNode } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import { MobileMenu } from "layouts/layout-parts/MobileMenu";
import { Hidden } from "@mui/material";

// --------------------------------------------
type DashboardLayoutProps = {
  children?: ReactNode;
};
// --------------------------------------------

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const [sidebarCompact, setSidebarCompact] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);
  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state);

  return (
    <Fragment>
      <LayoutBodyWrapper>
        <DashboardHeader
          setShowSideBar={handleCompactToggle}
          setShowMobileSideBar={handleMobileDrawerToggle}
        />

        {children || <Outlet />}
        <Hidden smUp>
          <MobileMenu />
        </Hidden>
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
