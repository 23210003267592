import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonBase,
  CardHeader,
  Fade,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import AppModal from "components/AppModal";
import AppTextField from "components/input-fields/AppTextField";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import useAxios from "hooks/useAxios";
import { QueriesKey } from "utils/constants";
import { useQueryClient } from "@tanstack/react-query";
import useUserEmail from "hooks/useUserEmail";
import { useSnackbar } from "notistack";
import PrimaryButton from "components/buttons/PrimaryButton";
import FlexBetween from "components/flexbox/FlexBetween";

export const VerifyEmail = ({ children, title }: { children?: any, title?: string }) => {
  const theme = useTheme();
  const inputRef = useRef();
  const { callApi } = useAxios();
  const [open, setOpen] = useState(false);
  const [hadSend, setHadSend] = useState(false);
  const [nextTime, setNextTime] = useState<Date>(new Date());
  const handleRequestOTP = async () => {
    try {
      const existEmail = await callApi<any, { isExisted: boolean }>(
        "get",
        "auth/check-email",
        {
          email: formik.values.email,
        }
      );
      if (existEmail.isExisted === true) {
        enqueueSnackbar(
          `Email ${formik.values.email} has been linked to another address!`,
          {
            variant: "error",
            autoHideDuration: 1000,
          }
        );
        setNextTime(moment().add(5, "second").toDate())
        return;
      }
      await callApi<any, any>("post", "auth/link/email", {
        email: formik.values.email,
      });
    } catch (e) {
      enqueueSnackbar("Send email failed!", {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
    const temp = moment().add(50, "second").toDate();
    setNextTime(temp);
    setHadSend(true);
  };

  const userEmail = useUserEmail();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleVerify = async () => {
    try {
      const existEmail = await callApi<any, { isExisted: boolean }>(
        "get",
        "auth/check-email",
        {
          email: formik.values.email,
        }
      );
      if (existEmail.isExisted === true) {
        enqueueSnackbar(
          `Email ${formik.values.email} has been linked to another address!`,
          {
            variant: "error",
            autoHideDuration: 1000,
          }
        );
        return;
      }
      await callApi<any, any>("post", "auth/link/email-verify", {
        email: formik.values.email,
        otp: formik.values.otp,
      });
      enqueueSnackbar("Your account has been linked to email successfully!", {
        variant: "success",
        autoHideDuration: 1000,
      });
      queryClient.invalidateQueries({ queryKey: [QueriesKey.UserEmail] });
      queryClient.invalidateQueries({ queryKey: [QueriesKey.UserPoint] });
      setOpen(false);
    } catch (e) {
      enqueueSnackbar("Some thing wrong!", {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  };

  const handleCloseForm = () => {
    setOpen(false);
  };

  const Schema = Yup.object().shape({
    email: Yup.string().email(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
    },
    validationSchema: Schema,
    validateOnBlur: false,
    onSubmit: async (values) => { },
  });

  const { getFieldProps, errors, values } = formik;

  return (
    <>
      {!userEmail ||
        !userEmail?.[0]?.email ||
        userEmail?.[0]?.status !== "VERIFIED" ? (
        <>
          {
            children ? <ButtonBase onClick={() => setOpen(true)} sx={{ width: '100%' }}>
              <Stack width={'100%'} sx={{ pointerEvents: 'none' }}>
                {children}
              </Stack>
            </ButtonBase> : <Alert
              severity="error"
              sx={{ "& .MuiAlert-icon": { color: theme.palette.error.light } }}
            >
              <AlertTitle>{t("You have an unverified email")}</AlertTitle>
              <Typography color={"text.secondary"} maxWidth={300}>
                {t(
                  "Verify to receive Trading Position Info & Airdrop Confirmation"
                )}
              </Typography>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="warning"
                sx={{ height: 30, mt: 2, mb: 1, color: "#000" }}
              >
                {t("Verify Email")}
              </Button>
            </Alert>
          }
        </>
      ) : (
        children ? children : <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CardHeader
            sx={{ p: 0 }}
            title={
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Typography>Email</Typography>
              </Stack>
            }
            subheader={
              <Typography variant="caption" color={"success.main"}>
                {" "}
                {t("Verified email")}
              </Typography>
            }
          />
          <Typography color={"text.disabled"}>
            {userEmail?.[0]?.email}
          </Typography>
        </Stack>
      )}

      <AppModal open={open} onClose={() => { }} onOpen={() => setOpen(true)}>
        <FormikProvider value={formik}>
          <Form>
            <Stack spacing={2} sx={{ position: 'relative' }}>
              <Typography variant="h5" textTransform={'uppercase'} textAlign={'center'}>{title || t("Verify Email")}</Typography>
              <Typography textAlign={'center'} color={"text.secondary"}>
                Please enter your email to get OTP
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <CustomInput
                  placeholder={t("Your email") || ""}
                  sx={{
                    borderRadius: 0
                  }}
                  {...getFieldProps("email")}
                  inputProps={{
                    ref: inputRef,
                  }}
                  InputProps={{
                    endAdornment: (
                      <CustomCountDown
                        disabled={Boolean(
                          errors.email || values.email.length === 0
                        )}
                        nextTime={nextTime}
                        handleCallback={() => handleRequestOTP()}
                      />
                    ),
                  }}
                />
                <FormHelperText error>{errors.email}</FormHelperText>
              </FormControl>
              {hadSend && (
                <FormControl sx={{ width: "100%" }}>
                  <CustomInput
                    sx={{ borderRadius: 0 }}
                    placeholder={t("OTP") || ""}
                    {...getFieldProps("otp")}
                  />
                  <FormHelperText error>{errors.otp}</FormHelperText>
                </FormControl>
              )}
              <FlexBetween gap={2} sx={{ width: '100%' }} position={'relative'}>
                <PrimaryButton
                  onClick={() => handleCloseForm()}
                  shadowSx={{
                    display: 'none !important'
                  }}
                  sx={{
                    background: theme.palette.background.button,
                    width: '100%',
                    '&:hover': {
                      background: theme.palette.background.buttonActive
                    }
                  }}
                >
                  {t("Cancel")}
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    hadSend ? handleVerify() : handleRequestOTP();
                  }}
                  disabled={Boolean(
                    errors.email ||
                    values.email.length === 0 ||
                    values.otp.length === 0
                  )}
                >
                  {t("Confirm")}
                </PrimaryButton>
              </FlexBetween>
            </Stack>
          </Form>
        </FormikProvider>
      </AppModal>
    </>
  );
};

type CountDownProps = {
  handleCallback: () => void;
  nextTime: Date;
  disabled: boolean;
};

const CustomCountDown = ({
  handleCallback,
  nextTime,
  disabled,
}: CountDownProps) => {
  const theme = useTheme();
  const [completed, setCompleted] = useState(true);
  const [countdownTime, setCountdownTime] = useState(-1);
  const handleClick = () => {
    if (handleCallback) handleCallback();
    setCompleted(false);
  };
  useEffect(() => {
    if (nextTime) {
      const temp = moment(nextTime).unix() - moment().unix();
      if (temp > 0) {
        setCountdownTime(temp);
        setCompleted(false);
      }
    }
  }, [nextTime]);

  return (
    <Box pr={1}>
      {completed ? (
        <Fade in={completed}>
          <Typography
            width={100}
            variant="body2"
            color={disabled ? theme.palette.text.disabled : "primary"}
            fontWeight={600}
            sx={{ cursor: "pointer" }}
            onClick={() => !disabled && handleClick()}
          >
            {t("Request OTP")}
          </Typography>
        </Fade>
      ) : (
        <>
          {countdownTime >= 0 && (
            <Countdown
              date={Date.now() + countdownTime * 1000}
              onComplete={() => setCompleted(true)}
              renderer={({ seconds }) => (
                <Typography pr={1} color={"text.secondary"}>
                  {seconds}
                </Typography>
              )}
            />
          )}
        </>
      )}
    </Box>
  );
};

const CustomInput = styled(AppTextField)(({ theme }) => ({
  background: "none",
  borderRadius: "0.3rem",
  overflow: "hidden",
  fontWeight: 500,
  border: "1px solid",
  borderColor: theme.palette.divider,
  width: "100%",
  "& input": {
    padding: "0.8rem 1rem",
    textAlign: "left",
  },
  "&:hover, &.Mui-focused": {
    borderColor: theme.palette.warning.dark,
  },
  "&.Mui-disabled": {
    borderColor: "transparent",
  },
  "& fieldset": {
    border: "none",
  },
  "& .iconify": {
    width: 20,
    height: 20,
  },
}));
