import { FC, ReactNode } from "react";
import { WagmiProvider } from "wagmi";
import { ConnectKitProvider } from "connectkit";
import { config } from "./config";
import { useTheme } from "@mui/material";
// component props types
export type Web3Provider = { children?: ReactNode };

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Web3Provider: FC<Web3Provider> = ({ children }) => {
  const theme = useTheme();
  const modeTheme = {
    "--ck-font-family": theme.typography.fontFamily,
    "--ck-border-radius": `10px`,
    "--ck-primary-button-border-radius": "7px",
    "--ck-secondary-button-border-radius": "7px",
    "--ck-tertiary-button-border-radius": "7px",
    "--ck-qr-border-radius": "7px",
    "--ck-connectbutton-border-radius": "7px",
  };
  return (
    <WagmiProvider config={config}>
      <ConnectKitProvider
        theme={theme.palette.mode === "dark" ? "midnight" : "nouns"}
        mode={theme.palette.mode}
        customTheme={modeTheme}
        options={{
          overlayBlur: 2,
        }}
      >
        {children}
      </ConnectKitProvider>
    </WagmiProvider>
  );
};

export default Web3Provider;
