import {
  ButtonBase,
  Divider,
  styled,
  Typography,
  Stack,
  useTheme,
  Grid,
  Hidden,
  Avatar,
  IconButton,
  Backdrop,
  alpha,
} from "@mui/material";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChainIcon, ConnectKitButton, useModal } from "connectkit";
import {
  useAccount,
  useChainId,
  useDisconnect,
  useSignMessage,
  useSwitchAccount,
  useSwitchChain,
  useWalletClient,
} from "wagmi";
import { watchAccount } from "@wagmi/core";
import Address from "web3/Address";
import { t } from "i18next";
import { SiweMessage } from "siwe";
import Jazzicons from "components/Jazzicons";
import Iconify from "components/Iconify";
import ChainSelector from "web3/ChainSelector";
import BalanceOverview from "../balance/BalanceOverview";
import Web3LiveTransaction from "web3/Web3LiveTransaction";
import LayoutDrawer from "../LayoutDrawer";
import UserFunction from "sections/UserFunction";
import axios from "axios";
import useUserSections from "hooks/useUserSections";
import { VerifyEmail } from "sections/dashboards/VerifyEmail";
import useUserEmail from "hooks/useUserEmail";
import UserRefCode from "./UserReferenceCode";
import { SupportedChains } from "web3/config";

const guestAxios = axios.create({
  baseURL:
    process.env.REACT_APP_IS_LOCAL === "true"
      ? "http://localhost:2000"
      : "https://api.dev.flaex.io",
});

// styled components
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.background.button,
  height: 40,
  padding: "0px 0.7rem",
  borderRadius: 40,
  color: theme.palette.text.secondary,
  "&:hover": { backgroundColor: theme.palette.background.buttonActive },
}));

const StyledActionButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  padding: "0px",
  borderRadius: 10,
  height: 50,
  width: 50,
  "&:hover": { backgroundColor: theme.palette.action.active },
  "& .iconify": {
    width: 20,
    height: 20,
    color: theme.palette.text.secondary,
  },
}));

const CustomAddress = styled(Address)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontWeight: 600,
    letterSpacing: 1,
    padding: "0px 1rem",
  },
}));

const DrawerPaperStyle: any = {
  position: "absolute",
  width: "100%",
  border: "none",
};

const DrawerHeader = styled(Stack)(({ theme }) => ({
  height: 45,
  borderBottom: `0.5px solid ${alpha(theme.palette.divider, 0.05)}`,
  padding: "0px 1.5rem 0px 0.5rem",
  "& .MuiTypography-root": {
    fontWeight: 600,
    color: theme.palette.text.disabled,
    textAlign: "center",
    flexGrow: 1,
  },
}));
const DrawerContainer = styled(Stack)(({ theme }) => ({
  position: "relative",
  flexGrow: 1,
}));

const ChainItem = styled(ButtonBase)(({ theme }) => ({
  height: 50,
  padding: "0px 1rem",
  borderRadius: 0,
  "& .MuiTypography-root": {
    fontWeight: 500,
    textAlign: "left",
    flexGrow: 1,
    padding: "0px 1rem",
  },
  "&:hover": { backgroundColor: theme.palette.action.disabledBackground },
}));
const ConnectedStatus = styled(Stack)(({ theme }) => ({
  "& .MuiTypography-root": {
    padding: "0px 0.5rem",
    color: theme.palette.text.disabled,
  },
}));

const ProfilePopover: FC = () => {
  const theme = useTheme();
  const { address } = useAccount();
  const { login, logout, saveUserSections, userSections } = useUserSections();
  const { switchChain, isPending } = useSwitchChain();
  const anchorRef = useRef(null);
  const chainId = useChainId();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openChain, setOpenChain] = useState(false);
  const { signMessageAsync } = useSignMessage();
  const { disconnect } = useDisconnect();

  const { data: walletClient } = useWalletClient();
  const web3Modal = useModal({
    onConnect(data) {
      signIn(data?.address as string);
    },
    onDisconnect() {
      logout();
    },
  });

  const handleOpenModal = (isConnected: boolean) => {
    isConnected ? setOpen(true) : web3Modal.setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDisconnect = () => {
    setOpen(false);
    setTimeout(() => {
      disconnect();
    }, 200);
  };

  const handleSwitchChain = (chainId: number) => {
    switchChain?.({ chainId: chainId });
  };

  const userEmail = useUserEmail();

  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const signIn = useCallback(
    async (address: string | `0x${string}` | undefined) => {
      try {
        const nonceRes = await guestAxios.get("/auth/siwe/nonce");
        const nonce = nonceRes.data?.nonce;
        // Create SIWE message with pre-fetched nonce and sign with wallet
        const message = new SiweMessage({
          domain: window?.location?.host,
          address: address,
          statement: "Welcome To Flaex",
          uri: window?.location?.origin,
          version: "1",
          chainId,
          nonce: nonce,
        });
        const signature = await signMessageAsync({
          message: message.prepareMessage(),
        });
        await login(message, signature, address || "");
      } catch (error) {
        handleDisconnect();
      }
    },
    [chainId, login, signMessageAsync]
  );

  useEffect(() => {
    if (userSections && userSections.address) {
      if (address !== userSections.address) {
        signIn(address);
      }
    }
    if (userSections.accessToken && !userSections.address) {
      if (address) {
        saveUserSections({
          ...userSections,
          address: address,
        });
      }
    }
  }, [address, userSections]);

  console.log({ openChain });

  return (
    <Fragment>
      <StyledButtonBase onClick={() => setOpenChain(true)}>
        <Avatar
          sx={{ width: 25, height: 25, background: theme.palette.divider }}
        >
          <ChainIcon id={chainId} />
        </Avatar>
        <Iconify
          icon="iconamoon:arrow-down-2-light"
          sx={{ width: 20, height: 20, margin: "0px 0.3rem" }}
        />
      </StyledButtonBase>

      <LayoutDrawer
        open={openChain}
        onClose={() => setOpenChain(false)}
        anchor="right"
        sx={{ width: "300px" }}
      >
        <DrawerHeader direction={"row"} alignItems={"center"}>
          <IconButton sx={{ padding: 0.3 }} onClick={() => setOpenChain(false)}>
            <Iconify
              icon={"iconamoon:arrow-left-2-light"}
              sx={{ width: 30, height: 30, color: theme.palette.text.disabled }}
            />
          </IconButton>
          <Typography>{t("Select Network")}</Typography>
        </DrawerHeader>
        <DrawerContainer>
          <Stack>
            {SupportedChains.map((chain) => (
              <ChainItem
                key={chain.id}
                onClick={() => handleSwitchChain(chain.id)}
                disabled={Boolean(chainId === chain.id)}
              >
                <Avatar
                  sx={{
                    width: 31,
                    height: 31,
                    background: theme.palette.divider,
                  }}
                >
                  <ChainIcon id={chain.id} size={30} />
                </Avatar>
                <Typography>{chain.name}</Typography>
                {chainId === chain.id && (
                  <ConnectedStatus direction={"row"} alignItems={"center"}>
                    <Typography variant="caption">{t("Connected")}</Typography>
                    <Iconify
                      icon={"octicon:dot-fill-24"}
                      sx={{ color: theme.palette.success.main }}
                    />
                  </ConnectedStatus>
                )}
              </ChainItem>
            ))}
          </Stack>
          <Backdrop
            open={isPending}
            sx={{
              position: "absolute",
              backdropFilter: "blur(2px)",
              background: "none",
            }}
          >
            <Stack alignItems={"center"}>
              <Iconify
                icon="svg-spinners:pulse-ring"
                sx={{
                  width: 30,
                  height: 30,
                  color: theme.palette.warning.main,
                }}
              />
              <Typography fontWeight={600} color={theme.palette.text.disabled}>
                {t("Connecting")}
              </Typography>
            </Stack>
          </Backdrop>
        </DrawerContainer>
      </LayoutDrawer>
      <ConnectKitButton.Custom>
        {({ isConnected, isConnecting, address }) => {
          return (
            <StyledButtonBase
              disabled={isConnecting}
              disableRipple
              ref={anchorRef}
              onClick={() => handleOpenModal(isConnected)}
            >
              {isConnected ? (
                <>
                  <Jazzicons seed={`${address}`} />
                  <Hidden mdDown>
                    <CustomAddress address={address} size={5} />
                    <Iconify
                      icon="iconamoon:arrow-down-2-light"
                      sx={{ width: 25, height: 25 }}
                    />
                  </Hidden>
                </>
              ) : (
                <Typography px={2} fontWeight={600}>
                  {t("Connect Wallet")}
                </Typography>
              )}
            </StyledButtonBase>
          );
        }}
      </ConnectKitButton.Custom>
      <LayoutDrawer open={open} onClose={handleCloseModal} anchor="right">
        <Stack
          minWidth={350}
          minHeight={400}
          flexGrow={1}
          sx={{ position: "relative" }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            p={2}
            pb={0}
          >
            <BalanceOverview />
            {/* <ChainSelector /> */}
          </Stack>
          <Divider />
          <Stack minWidth={350} sx={{ position: "relative" }}>
            <Stack pt={2} px={3}>
              <VerifyEmail />
            </Stack>
            <UserFunction />
            <Grid container columns={16} px={2}>
              <Grid item xs={4}>
                <Stack spacing={1} alignItems={"center"}>
                  <StyledActionButton
                    onClick={() => handleMenuItem("/dashboard")}
                  >
                    <Iconify icon={"mingcute:dashboard-fill"} />
                  </StyledActionButton>
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color={theme.palette.text.disabled}
                  >
                    {t("Dashboard")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1} alignItems={"center"}>
                  <StyledActionButton onClick={() => handleDisconnect()}>
                    <Iconify icon={"ion:power"} />
                  </StyledActionButton>
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color={theme.palette.text.disabled}
                  >
                    {t("Disconnect")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Stack spacing={2} mt={2} p={3}>
              <Stack
                py={1}
                px={1.5}
                pl={2.5}
                sx={{
                  background: theme.palette.action.disabledBackground,
                  borderRadius: "0.3rem",
                }}
              >
                <Address
                  address={address}
                  sx={{ color: theme.palette.text.secondary }}
                  allowCopy
                  size={13}
                />
              </Stack>
              <UserRefCode />
            </Stack>
          </Stack>

          {/* <Divider />
          <Stack p={2} flexGrow={1}>
            <Web3LiveTransaction address={address} />
          </Stack> */}
        </Stack>
      </LayoutDrawer>
    </Fragment>
  );
};

export default ProfilePopover;
