import { useQuery } from "@tanstack/react-query";
import { erc20Abi, getContract } from "viem";
import { usePublicClient } from "wagmi";

const useTokenAllowance = (
  tokenAddress: `0x${string}`,
  owner: `0x${string}` | undefined,
  spender: `0x${string}` | undefined
) => {
  const publicClient = usePublicClient();
  const { data: allowance, isLoading } = useQuery({
    queryKey: ["getTokenAllowance"],
    staleTime: 3000,
    enabled: !!publicClient,
    queryFn: async () => {
      if (!publicClient || !owner || !spender) return BigInt(0);
      const contract = getContract({
        abi: erc20Abi,
        address: tokenAddress,
        client: publicClient,
      });

      const allowance = contract.read.allowance([owner, spender]);
      return allowance;
    },
  });
  return allowance;
};

export default useTokenAllowance;
