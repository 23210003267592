
import { Box } from "@mui/material";
const TabPanel = (props: any) => {
    const { children, value, index, sx, ...other } = props;

    return <div
    // {...other}
    >
        {value === index && (
            <Box sx={{ ...sx }}>
                {children}
            </Box>
        )}
    </div>;
};

export default TabPanel;