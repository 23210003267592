import {
  getAAVEPoolAddress,
  getCapitalProvider,
  getFlTokenAddress,
  getUSDCAddress,
} from "contracts/contractAddress";
import { useAccount, useChainId, usePublicClient } from "wagmi";
import useUserBalance from "./useUserBalance";
import { useQuery } from "@tanstack/react-query";
import { QueriesKey } from "utils/constants";
import { getContract } from "viem";
import { capitalProviderABI } from "contracts/abis/capitalProvider";
import { flTokenABI } from "contracts/abis/flToken";
import { aaveABI } from "contracts/abis/aavePool";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";

const useCapitalInfo = () => {
  const chainId = useChainId();

  const usdcAddress = getUSDCAddress(chainId);
  const userBalance = useUserBalance(getUSDCAddress(chainId));
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const { data: capitalInfo, isLoading } = useQuery({
    enabled: !!publicClient,
    queryKey: [QueriesKey.CapitalProviderInfo, chainId, address],
    queryFn: async () => {
      if (!publicClient || !usdcAddress) return;
      const capitalContract = getContract({
        abi: capitalProviderABI,
        address: getCapitalProvider(chainId) as `0x${string}`,
        client: {
          public: publicClient,
        },
      });

      const flContract = getContract({
        abi: flTokenABI,
        address: getFlTokenAddress(chainId) as `0x${string}`,
        client: {
          public: publicClient,
        },
      });

      const aavePoolContract = getContract({
        abi: aaveABI,
        address: getAAVEPoolAddress(chainId) as `0x${string}`,
        client: {
          public: publicClient,
        },
      });

      const results = await publicClient.multicall({
        contracts: [
          {
            ...capitalContract,
            functionName: "getCapitalProviderInfo",
            args: [address ?? ADDRESS_ZERO],
          },
          {
            ...flContract,
            functionName: "balanceOf",
            args: [address ?? ADDRESS_ZERO],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveData",
            args: [usdcAddress as `0x${string}`],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveNormalizedIncome",
            args: [usdcAddress as `0x${string}`],
          },
        ],
      });

      return {
        capitalBalance: results[0].result,
        flBalance: results[1].result,
        usdcReserveData: results[2].result,
        usdcNormalizedIncome: results[3].result,
      };
    },
  });

  return capitalInfo;
};

export default useCapitalInfo;
