// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function Iconify({ icon, sx, onClick, className, size, ...other }: { icon: any, onClick?: any, sx?: any, className?: string, size?: number }) {
  return <Box component={Icon} icon={icon} sx={{ ...sx, width: `${size || 20}px !important`, height: `${size || 20}px !important` }} className={className} {...other} />;
}
