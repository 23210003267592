import { Pair_GetRes } from "types/tradePairs";

export const UPDATE_MARKET = 'UPDATE_MARKET'
export const FETCH_MARKET = 'FETCH_MARKET'

type Action = {
    type: string;
    payload: any;
}

export type TradeState = {
    markets: Pair_GetRes[],
}

const initialState: TradeState = {
    markets: [],
};

const TradeReducer = (state = initialState, action: Action) => {
    const { markets } = state
    const { payload } = action
    switch (action.type) {
        case FETCH_MARKET:
            return { ...state, markets: payload };
        case UPDATE_MARKET:
            return state;
        default:
            return state;
    }
};

export default TradeReducer;
