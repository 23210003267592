import {
  ButtonBase,
  ButtonProps,
  styled,
  Box,
  Stack,
  alpha,
  StackProps,
} from "@mui/material";
import {
  ClipboardEventHandler,
  FC,
  Fragment,
  Ref,
  SVGProps,
  useEffect,
  useRef,
  useState,
} from "react";
import $ from "jquery";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

type PrimaryButtonProps = {
  children?: any;
  sx?: ButtonProps["sx"];
  shadow?: any[];
  shadowSx?: ButtonProps["sx"];
  contentSx?: StackProps["sx"];
};

const PrimaryButton: FC<
  PrimaryButtonProps & LoadingButtonProps & SVGProps<SVGSVGElement>
> = (props) => {
  const buttonRef = useRef(null);
  const { sx, children, shadow, shadowSx, contentSx, disabled } = props;
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(50);
  useEffect(() => {
    if (buttonRef.current) {
      setWidth($(buttonRef.current).innerWidth() || 0);
      setHeight($(buttonRef.current).innerHeight() || 0);
    }
  }, [buttonRef.current]);

  return (
    <Stack
      sx={{
        ...contentSx,
        position: "relative",
        transition: "all 0.1s ease-in-out",
        cursor: "pointer",
        width: "100%",
        // @ts-ignore
        "&:after": {
          ...shadowSx,
          content: disabled ? null : '""',
          position: "absolute",
          width: width - 50,
          height: height - 10,
          borderRadius: "50%",
          top: 5,
          left: 25,
          boxShadow: "0px 0px 40px #fff",
          zIndex: -1,
          opacity: 0,
          transition: "all 0.15s ease-in-out",
        },
        "&:hover": {
          transform: shadow ? `translateX(-${shadow ? 8 : 0}px)` : "",
          "& #button-shadow": {
            transform: `translateY(-${shadow ? 2 : 0}px) translateX(${
              shadow ? 10 : 0
            }px)`,
            opacity: 0.4,
          },
          "&:after": {
            opacity: 1,
          },
        },
      }}
    >
      <CustomButton {...props} ref={buttonRef} sx={{ ...sx }}>
        {children}
      </CustomButton>
      <svg
        width={0}
        height={0}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="button-outline-1">
          <path
            d={`M10  ${height}H${width - 40}L${width} 10L${width - 10} 0H40L0 ${
              height - 10
            }L10 ${height}Z`}
            fill="none"
          />
          <path
            d={`M10  ${height}H${width - 40}L${width} 10L${width - 10} 0H40L0 ${
              height - 10
            }L10 ${height}Z`}
            fill="none"
            stroke="#FF66C4"
            strokeWidth="2"
          />
        </clipPath>
      </svg>
      {shadow && (
        <svg
          {...props}
          id="button-shadow"
          style={{
            position: "absolute",
            transition: "all 0.1s ease-in-out",
            cursor: "pointer",
            marginTop: `${5}px`,
            marginLeft: "-20px",
          }}
          width={width}
          height={height}
          viewBox={`-3 0 ${width + 6} ${height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M10  ${height}H${width - 40}L${width} 10L${width - 10} 0H40L0 ${
              height - 10
            }L10 ${height}Z`}
            fill="none"
            stroke="url(#gradient)"
            strokeWidth="1"
          />
          <linearGradient id="gradient" x1="40%" y1="100%" x2="100%" y2="0%">
            {shadow.map((item, index) => (
              <stop
                key={index}
                offset={`${item?.[1]}%`}
                stopColor={item?.[0]}
              />
            ))}
          </linearGradient>
        </svg>
      )}
    </Stack>
  );
};

export default PrimaryButton;

const CustomButton = styled(LoadingButton)(({ theme }) => ({
  clipPath: "url(#button-outline-1)",
  minWidth: 100,
  minHeight: 45,
  overflow: "hidden",
  width: "100%",
  background: "linear-gradient(90deg, #8001FE 0%, #FDA9E5 100%)",
  fontSize: "0.9rem",
  fontWeight: 400,
  "& .MuiLoadingButton-loadingIndicator": {
    opacity: 1,
    color: "#fff",
  },
  "&.Mui-disabled": {
    background: alpha(theme.palette.text.secondary, 0.1),
    "&:after": {
      display: "none",
    },
  },
}));
