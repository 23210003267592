import IUniswapV3PoolABI from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";
import QuoterV2 from "@uniswap/v3-periphery/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
import { computePoolAddress } from "@uniswap/v3-sdk";
import { useCallback, useEffect, useState } from "react";

import { Token } from "@uniswap/sdk-core";
import { Decimal } from "decimal.js";
import { useBlockNumber, useChainId, usePublicClient, useWalletClient } from "wagmi";
import { BigNumber } from "@ethersproject/bignumber";
import { PublicClient, getContract } from "viem";
import { useQuery } from "@tanstack/react-query";
import { getFactoryV3Address, getQuoterV2Address } from "contracts/contractAddress";
// const READABLE_FORM_LEN = 4;

export function fromReadableAmount(amount: number, decimals: number): string {
  return new Decimal(amount).mul(new Decimal(10).pow(decimals)).toHex();
}

export function toReadableAmount(
  rawAmount: bigint,
  decimals: number,
): string {
  return new Decimal(rawAmount.toString())
    .div(new Decimal(10).pow(decimals))
    .toString()
}

// Addresses



export interface QuoterReturn {
  priceExactOutputToken1: string,
  priceExactInputToken0: string,
  lastPrice: number
}

const useQuoter = (
  tokenIn: string | undefined,
  tokenOut: string | undefined,
  amountIn: number = 1,
  tokenInDecimals: number | undefined,
  tokenOutDecimals: number | undefined,
  fee: number | undefined,
) => {

  const provider = usePublicClient();
  const chainId = useChainId()
  const POOL_FACTORY_CONTRACT_ADDRESS = getFactoryV3Address(chainId) as `0x${string}`;
  const QUOTER_CONTRACT_ADDRESS = getQuoterV2Address(chainId) as `0x${string}`;

  const { data: quoter, isLoading } = useQuery({
    enabled: !!tokenIn && !!tokenOut,
    queryKey: ["getQuoter", chainId, tokenIn, tokenOut],
    staleTime: 3000,
    queryFn: async () => {
      if (!tokenIn || !tokenOut || !tokenInDecimals || !tokenOutDecimals) return;
      if (!provider) return
      try {
        const result1 = await provider.simulateContract({
          abi: QuoterV2.abi,
          address: QUOTER_CONTRACT_ADDRESS,
          functionName: 'quoteExactOutputSingle',
          args: [
            [
              tokenOut,
              tokenIn,
              fromReadableAmount(amountIn, tokenInDecimals),
              fee,
              0
            ]
          ]
        })
        const result2 = await provider.simulateContract({
          abi: QuoterV2.abi,
          address: QUOTER_CONTRACT_ADDRESS,
          functionName: 'quoteExactInputSingle',
          args: [
            [
              tokenIn,
              tokenOut,
              fromReadableAmount(amountIn, tokenInDecimals),
              fee,
              0
            ]
          ]
        })

        const priceExactOutputToken1 = toReadableAmount(result1.result[0] as bigint, tokenOutDecimals);
        const priceExactInputToken0 = toReadableAmount(result2.result[0] as bigint, tokenOutDecimals);
        const lastPrice = Decimal.add(priceExactOutputToken1 || 0, priceExactInputToken0 || 0).div(2).toNumber()
        return { priceExactOutputToken1, priceExactInputToken0, lastPrice }
      } catch (e) {
        console.log(e);
      }
    }
  })
  return quoter;
};

export default useQuoter;

export async function getPoolConstants(
  _provider: PublicClient,
  _tokenIn: Token,
  _tokenOut: Token,
  _fee: number,
  _chainId: number
) {
  const currentPoolAddress = computePoolAddress({
    factoryAddress: getFactoryV3Address(_chainId) as string,
    tokenA: _tokenIn,
    tokenB: _tokenOut,
    fee: _fee,
  });

  const poolContract = getContract({
    address: currentPoolAddress as `0x${string}`,
    abi: IUniswapV3PoolABI.abi,
    client: {
      public: _provider
    },
  })

  const [token0, token1, fee] = await Promise.all([
    poolContract.read.token0(),
    poolContract.read.token1(),
    poolContract.read.fee(),
  ]);

  return {
    token0,
    token1,
    fee,
  };
}
