import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      CRM: "CRM",
      Shop: "Shop",
      Chat: "Chat",
      Sales: "Sales",
      Results: "Results",
      Invoice: "Invoice",
      Payment: "Payment",
      Profile: "Profile",
      Account: "Account",
      Pricing: "Pricing",
      Checkout: "Checkout",
      Sessions: "Sessions",
      Ecommerce: "Ecommerce",
      Dashboards: "Dashboards",
      "Enrolled Courses": "Enrolled Courses",
      "Course Completed": "Course Completed",
      "Course in Progress": "Course in Progress",
      "Learning Management": "Learning Management",
      "Welcome Back! Watson": "Welcome Back! Watson",
      "Job Management": "Job Management",
      "User & Contact": "User & Contact",
      "User List": "User List",
      "User Grid": "User Grid",
      "Contact List": "Contact List",
      "Contact Grid": "Contact Grid",
      "Invoice List": "Invoice List",
      "Invoice Details": "Invoice Details",
      "Create Invoice": "Create Invoice",
      "Product Details": "Product Details",
      "Billing Address": "Billing Address",
      "Payment Complete": "Payment Complete",
      "Admin Ecommerce": "Admin Ecommerce",
      "Product List": "Product List",
      "Product Grid": "Product Grid",
      "Create Product": "Create Product",
      "Upcoming Task": "Upcoming Task",
      "Study Time Last Week": "Study Time Last Week",
      "Your Downloads": "Your Downloads",
      "Course Status": "Course Status",
      "Allowed Slippage": "Allowed Slippage",
      "Theme settings": "Theme settings",
      "Dark mode": "Dark mode",
      "Connect Wallet": "Connect Wallet",
      "Select Network": "Select Network",
      "Connected": "Connected",
      "Connecting": "Connecting",
      "Funding": "Funding",
      "Wallet": "Wallet",
      "Total balance": "Total balance",
      "Deposit": "Deposit",
      "Withdraw": "Withdraw",
      "Swap": "Swap",
      "More": "More",
      "Transaction": "Transaction",
      "Explorer": "Explorer",
      "Not found": "Not found",
      "Disconnect": "Disconnect",
      "Dashboard": "Dashboard",
      "Symbol, pair": "Symbol, pair",
      "Add to favorite list.": "Add to favorite list.",
      "Remove from favorite list.": "Remove from favorite list.",
      "Indicators": "Indicators",
      "Save": "Save",
      "Margin Balance": "Margin Balance",
      "Available": "Available",
      "Price Mode": "Price Mode",
      "Trade Market": "Trade Market",
      "Market order is immediately matched to the best available market price.": "Market order is immediately matched to the best available market price.",
      "A limit order is an order to buy or sell at a specific price or better. Limit orders are not guaranteed to execute.": "A limit order is an order to buy or sell at a specific price or better. Limit orders are not guaranteed to execute.",
      "Margin Mode": "Margin Mode",
      "Switching of margin modes only applies to the selected contract": "Switching of margin modes only applies to the selected contract",
      "Adjust Leverage": "Adjust Leverage",
      "Maximum position at current leverage: -- ": "Maximum position at current leverage: -- ",
      "Please note that leverage changing will also apply to open positions and open orders.": "Please note that leverage changing will also apply to open positions and open orders.",
      "Selecting higher leverage such as[10x] increase your liquidation risk. Always manage your risk levels.": "Selecting higher leverage such as[10x] increase your liquidation risk. Always manage your risk levels.",
      "Amount": "Amount",
      "Price": "Price",
      "Market": "Market",
      "Max Buy": "Max Buy",
      "Long": "Long",
      "Max Sell": "Max Sell",
      "Short": "Short",
      "Cost": "Cost",
      "Entry": "Entry",
      "Fees (Rebated) and Price Impact": "Fees (Rebated) and Price Impact",
      "Open Interest Balance": "Open Interest Balance",
      "Direction": "Direction",
      "Type": "Type",
      "Asset": "Asset",
      "Debt": "Debt",
      "Filled": "Filled",
      "Trigger Condition": "Trigger Condition",
      "Positions": "Positions",
      "Open orders": "Open orders",
      "Margin": "Margin",
      "Entry Price": "Entry Price",
      "Mark Price": "Mark Price",
      "Liquidation Price": "Liquidation Price",
      "Margin Radio": "Margin Radio",
      "24h Change": "24h Change",
      "24h High": "24h High",
      "24h Low": "24h Low",
      "24h Volume": "24h Volume",
      "Scan address to receive payment": "Scan address to receive payment",
      "Deposit usually takes 5-15 seconds. Please wait until your account balance increases before submitting another transaction": "Deposit usually takes 5-15 seconds. Please wait until your account balance increases before submitting another transaction.",
      "Select Token": "Select Token",
      "Minimum": "Minimum",
      "Deposit to Funding": "Deposit to Funding",
      "Or from another wallet": "Or from another wallet",
      "Please input amount as a number.": "Please input amount as a number.",
      "Error": "Error",
      "Success": "Success",
      "Information": "Information",
      "Live transactions": "Live transactions",
      "Waiting": "Waiting",
      "The transaction has been created. Please wait!": "The transaction has been created. Please wait!",
      "Balance": "Balance",
      "Today's PNL": "Today's PNL",
      "Available balance": "Available balance",
      "Unrealized PNL": "Unrealized PNL",
      "Live Positions": "Live Positions",
      "Profit and Loss Analysis": "Profit and Loss Analysis",
      "Total profit": "Total profit",
      "Average profit": "Average profit",
      "Win rate": "Win rate",
      "Total loss": "Total loss",
      "Average loss": "Average loss",
      "Profit/Loss ratio": "Profit/Loss ratio",
      "Net profit/loss": "Net profit/loss",
      "Date": "Date",
      "Daily PNL": "Daily PNL",
      "The Interest Rate that is being automatically accrued to USDC in your Funding Account": "The Interest Rate that is being automatically accrued to USDC in your Funding Account",
      "Cumulative PNL": "Cumulative PNL",
      "Net Transfer": "Net Transfer",
      "Completed": "Completed",
      "History": "History",
      "Select pair": "Select pair",
      "Default Order Lifetime": "Default Order Lifetime",
      "Time in Force": "Time in Force",
      "(Good Till Cancel): Order will continue to work until filled or canceled": "(Good Till Cancel): Order will continue to work until filled or canceled",
      "(Good Till Date): GTD (Good Till Date) orders remain active till its specified date, unless it has already been fulfilled or canceled. If not executed, the order will be automatically canceled": "(Good Till Date): GTD (Good Till Date) orders remain active till its specified date, unless it has already been fulfilled or canceled. If not executed, the order will be automatically canceled",
      "Please input price as a number": "Please input price as a number",
      "Market price": "Market price",
      "Deadline": "Deadline",
      "Your order will be valid until the": "Your order will be valid until the",
      "Pair": "Pair",
      "Status": "Status",
      "Confirm": "Confirm",
      "Est. Fee": "Est. Fee",
      "Approval permit2": "Approval permit2",
      "Sign message": "Sign message",
      "Deposit progress": "Deposit progress",
      "Please confirm on your wallet": "Please confirm on your wallet",
      "Please wait": "Please wait",
      "Confirm order": "Confirm order",
      "Create order": "Create order",
      "Please input an address": "Please input an address",
      "Address": "Address",
      "Confirm withdraw": "Confirm withdraw",
      "To": "To",
      "Symbol": "Symbol",
      "Size": "Size",
      "Close position": "Close position",
      "Position Amount": "Position Amount",
      "Close Amount": "Close Amount",
      "Est. PNL": "Est. PNL",
      "Confirm close position": "Confirm close position",
      "Position closing": "Position closing",
      "Closing Price": "Closing Price",
      "You have an unverified email": "You have an unverified email",
      "Verify Email": "Verify Email",
      "Your email": "Your email",
      "Positions history": "Positions history",
      "Opened": "Opened",
      "Closed": "Closed",
      "Close Price": "Close Price",
      "Closed Size": "Closed Size",
      "Closing PNL": "Closing PNL",
      "Closed Margin": "Closed Margin",
      "TP/SL for position": "TP/SL for position",
      "Take Profit": "Take Profit",
      "Trigger Price": "Trigger Price",
      "When the price reaches": "When the price reaches",
      "it will trigger Take Profit Market order to close this position. Estimated PNL will be": "it will trigger Take Profit Market order to close this position. Estimated PNL will be",
      "it will trigger Stop Market order to close this position. Estimated PNL will be": "it will trigger Stop Market order to close this position. Estimated PNL will be",
      "Invest": "Invest",
      "Enjoy double real-yield on your stable coin": "Enjoy double real-yield on your stable coin",
      "Eligible Stable Coin": "Eligible Stable Coin",
      "Liq. Price": "Liq. Price",
      "Margin Ratio": "Margin Ratio",
      "Total Yield Earned": "Total Yield Earned",
      "Claim": "Claim",
      "Verified email": "Verified email",
      "Points": "Points",
      "Referral code": "Referral code",
      "Point": "Point",
      "Generate code": "Generate code",
      "Your points": "Your points",
      "Total points": "Total points",
      "You will earn 0.1 points for every your introducee's 10,000.0 trade volume": "You will earn 0.1 points for every your introducee's 10,000.0 trade volume",
      "Introduce more user to get more points!": "Introduce more user to get more points!",
      "Total points you have accumulated in this campaign": "Total points you have accumulated in this campaign",
      "Verify your email to get 200 points": "Verify your email to get 200 points",
      "Start trading now to earn points!": "Start trading now to earn points!",
      "You will earn 1 point for every 10,000.0 trade volume": "You will earn 1 point for every 10,000.0 trade volume",
      "Rank": "Rank",
      "League": "League",
      "Trading Volume": "Trading Volume",
      "Overview": "Overview",
      "Total Trade Volume": "Total Trade Volume",
      "Total Fee Collected": "Total Fee Collected",
      "Total PnL of Closed Position": "Total PnL of Closed Position",
      "Total Trades": "Total Trades",
      "Total Margin Users": "Total Margin Users",
      "Total User (Wallet & Trade)": "Total User (Wallet & Trade)",
      "Total AA Wallet": "Total AA Wallet",
      "Transaction Covered Gas by Paymaster": "Transaction Covered Gas by Paymaster",
      "Send & Swap action": "Send & Swap action",
      "Trade action": "Trade action",
      "Other action": "Other action",
      "Transaction Paid Gas in Other Tokens": "Transaction Paid Gas in Other Tokens",
      "Total Sponsored AA Wallet Creation": "Total Sponsored AA Wallet Creation",
      "Gas Fee Paid in Other Tokens": "Gas Fee Paid in Other Tokens",
      "Gas Fee Sponsored by Paymaster": "Gas Fee Sponsored by Paymaster",
      "User Paying Gas with HOLD token": "User Paying Gas with HOLD token",
      "Existing User": "Existing User",
      "New User": "New User",
      "Analytics": "Analytics",
      "Daily Active User (Wallet & Trade)": "Daily Active User (Wallet & Trade)",
      "AA Action": "AA Action",
      "Trade": "Trade",
      "Vault Deposit": "Vault Deposit",
      "Daily Quest": "Daily Quest",
      "Daily Volume": "Daily Volume",
      "Cumulative Volume": "Cumulative Volume",
      "Daily Margin User": "Daily Margin User",
      "Old User": "Old User",
      "Cumulative User": "Cumulative User",
      "Cumulative Action": "Cumulative Action",
      "Daily Liq. Volume": "Daily Liq. Volume",
      "Cumulative Liq. Amount": "Cumulative Liq. Amount",
      "Daily Number of Trade": "Daily Number of Trade",
      "Cumulative Number of Trade": "Cumulative Number of Trade",
      "Cumulative User and User Action (Wallet & Trade)": "Cumulative User and User Action (Wallet & Trade)",
      "Liquidation Amount": "Liquidation Amount",
      "Daily Trade": "Daily Trade",
      "Daily PnL of Closed Position": "Daily PnL of Closed Position",
      "Daily PnL Against Vault": "Daily PnL Against Vault",
      "Cumulative PnL Against Vault": "Cumulative PnL Against Vault",
      "Trading now": "Trading now",
    },
  },
  es: {
    translation: {
      CRM: "CRM",
      Chat: "Chat",
      Shop: "Tienda",
      Sales: "Ventas",
      Payment: "Pago",
      Account: "Cuenta",
      Profile: "Perfil",
      Pricing: "Precios",
      Invoice: "Factura",
      Sessions: "Sesiones",
      Results: "Resultados",
      Checkout: "Verificar",
      Dashboards: "Cuadros de mando",
      Ecommerce: "Comercio electrónico",
      "Upcoming Task": "Próxima tarea",
      "Your Downloads": "Tus descargas",
      "Course Status": "Estado del curso",
      "Course in Progress": "Curso en curso",
      "Enrolled Courses": "Cursos inscritos",
      "Course Completed": "Curso completado",
      "Job Management": "Gestión de trabajos",
      "User & Contact": "Usuario y contacto",
      "User List": "Lista de usuarios",
      "User Grid": "Cuadrícula de usuario",
      "Contact List": "Lista de contactos",
      "Contact Grid": "Cuadrícula de contacto",
      "Invoice List": "Lista de facturas",
      "Create Invoice": "Crear factura",
      "Billing Address": "Dirección de Envio",
      "Payment Complete": "Pago completo",
      "Product List": "Lista de productos",
      "Create Product": "Crear producto",
      "Product Details": "Detalles de producto",
      "Product Grid": "Cuadrícula de productos",
      "Invoice Details": "Detalles de la factura",
      "Learning Management": "Gestión del aprendizaje",
      "Welcome Back! Watson": "¡Bienvenido de nuevo! Watson",
      "Admin Ecommerce": "Administrador de comercio electrónico",
      "Study Time Last Week": "Tiempo de estudio la semana pasada",
      "Allowed Slippage": "Allowed Slippage",
      "Default Order Lifetime": "Default Order Lifetime",
      "Closed Size": "Closed Size",
      "Margin": "Margin",
      "Total Invested": "Total Invested",
      "Current Health Factor": "Factor de salud actual",
      "Available to Invest": "Disponible para invertir",
      "Introduce more user to get more points!": "Introduce more user to get more points!",
      "The Interest Rate that is being automatically accrued to USDC in your Funding Account": "La tasa de interés que se acumula automáticamente al USDC en su cuenta de fondos",
      "Your points": "Your points",
      "Total points": "Total points",
      "You will earn 0.1 points for every your introducee's 10,000.0 trade volume": "You will earn 0.1 points for every your introducee's 10,000.0 trade volume",
      "Total points you have accumulated in this campaign": "Total points you have accumulated in this campaign",
      "Verify your email to get 200 points": "Verify your email to get 200 points",
      "Start trading now to earn points!": "Start trading now to earn points!",
      "You will earn 1 point for every 1000 trade volume": "You will earn 1 point for every 1000 trade volume"
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});
