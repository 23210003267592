import CryptoJS from "crypto-js";

//--------------------------------------------------------------------------

export const ACCESS_TOKEN_KEY = "CBt3gpbqeMdWPNG1";


export const Encrypt = (message: string, privateKey: string) =>
  CryptoJS.AES.encrypt(message, privateKey).toString();

export const Decrypt = (ciphertext: string, privateKey: string) => {
  var bytes = CryptoJS.AES.decrypt(ciphertext, privateKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const searchByName = (listData: any[], searchValue: string) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};

export const getRoute = (pathname: string) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(
    ACCESS_TOKEN_KEY,
    Encrypt(accessToken, ACCESS_TOKEN_KEY)
  );
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!accessToken) return null;
  return Decrypt(accessToken, ACCESS_TOKEN_KEY);
};

export const isLoggedIn = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY) != null;
};

export const jsonToUrlParams = (json: Record<string, any>): string => {
  return Object.keys(json)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
    .join('&');
}

export const supportedMargin = (marginType: string | undefined) => {
  if (marginType === 'ISOLATED') {
    return 1
  } else if (marginType === 'CROSS') {
    return 2
  } else if (marginType === 'CROSS/ISOLATED') {
    return 3
  }
  return 1;
}

export interface TradeMods {
  key: 'ISOLATED' | 'CROSS',
  title: string,
  disabled: boolean,
}
export const getTradeMods = (marginType: string | undefined): TradeMods[] => {
  if (process.env.REACT_APP_IS_LOCAL === 'true') {
    return [
      {
        key: "ISOLATED",
        title: "Isolated",
        disabled: false
      },
      {
        key: "CROSS",
        title: "Cross",
        disabled: false
      },
    ]
  } else
    if (supportedMargin(marginType) === 3) {
      return [
        {
          key: "ISOLATED",
          title: "Isolated",
          disabled: false
        },
        {
          key: "CROSS",
          title: "Cross",
          disabled: false
        },
      ]
    } else if (supportedMargin(marginType) === 1) {
      return [
        {
          key: "ISOLATED",
          title: "Isolated",
          disabled: false
        },
        {
          key: "CROSS",
          title: "Cross",
          disabled: true
        },
      ]
    } else if (supportedMargin(marginType) === 2) {
      return [
        {
          key: "CROSS",
          title: "Cross",
          disabled: false
        },
        {
          key: "ISOLATED",
          title: "Isolated",
          disabled: true
        }
      ]
    } else {
      return [
        {
          key: "ISOLATED",
          title: "Isolated",
          disabled: false
        },
        {
          key: "CROSS",
          title: "Cross",
          disabled: false
        },
      ]
    }

}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const queryStringToJson = (queryString: string): Record<string, string> => {
  // Remove the '?' character from the beginning of the query string
  const queryStringWithoutQuestionMark = queryString.slice(1);

  // Split the query string into key-value pairs
  const keyValuePairs = queryStringWithoutQuestionMark.split('&');

  // Initialize an empty object to store the key-value pairs
  const result: Record<string, string> = {};

  // Loop through each key-value pair
  keyValuePairs.forEach(keyValuePair => {
    // Split the key-value pair into key and value
    const [key, value] = keyValuePair.split('=');

    // Decode the key and value (in case they are URL-encoded)
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    // Add the key-value pair to the result object
    result[decodedKey] = decodedValue;
  });

  // Return the resulting JSON object
  return result;
}

export function dataURLtoBlob(dataURL: any) {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}