import { ButtonBase, Collapse, Divider, FormHelperText, Stack, Step, StepContent, StepIconProps, StepLabel, Stepper, styled, Typography, useTheme } from "@mui/material"
import GradientButton from "components/buttons/GradientButton"
import FlexRowAlign from "components/flexbox/FlexRowAlign"
import GradientText from "components/GradientText"
import { NumberInput } from "components/input-fields/NumberInput"
import { getCapitalProvider, getPermit2Address, getUSDCAddress } from "contracts/contractAddress"
import useUserBalance from "hooks/useUserBalance"
import { useAccount, useChainId, usePublicClient, useWalletClient } from "wagmi"
import { fCurrency } from "utils/format"
import { useState } from "react"
import { ConnectKitButton, useModal } from "connectkit"
import axios from "axios"
import AppModal from "components/AppModal"
import { t } from "i18next"
import Iconify from "components/Iconify"
import { useQueryClient } from "@tanstack/react-query"
import Decimal from "decimal.js"
import { getContract } from "viem"
import { erc20ABI } from "contracts/abis/erc20"
import { MaxUint256 } from "ethers"
import { PermitTransferFrom, SignatureTransfer } from "utils/signature"
import { delay } from "utils/utils";
import { useSnackbar } from "notistack"
import { capitalProviderABI } from "contracts/abis/capitalProvider"
import { QueriesKey } from "utils/constants"
import LoadingButton from "@mui/lab/LoadingButton"
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup";
import { Form } from "react-router-dom"
import useCapitalInfo from "hooks/useCapitalInfo"
import PrimaryButton from "components/buttons/PrimaryButton"

const guestAxios = axios.create({
    baseURL:
        process.env.REACT_APP_IS_LOCAL === "true"
            ? "http://localhost:2000"
            : "https://api.dev.flaex.io",
});

export const DepositForm = () => {
    const [open, setOpen] = useState(false);
    const { isConnected } = useAccount()
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmStep, setConfirmStep] = useState(-1);
    const chainId = useChainId();
    const { enqueueSnackbar } = useSnackbar();
    const { data: walletClient } = useWalletClient();
    const { address } = useAccount();
    const publicClient = usePublicClient();


    const web3Modal = useModal({});
    const handleOpenModal = (isConnected: boolean) => {
        isConnected ? setOpen(true) : web3Modal.setOpen(true);
    };
    const userBalance = useUserBalance(getUSDCAddress(chainId));
    const theme = useTheme()

    const queryClient = useQueryClient();


    const max = new Decimal(userBalance?.toString() ?? 0)
        .div(Decimal.pow(10, 6))
        .toNumber();

    const capitalInfo = useCapitalInfo();

    const yieldBalance = new Decimal(capitalInfo?.capitalBalance?.toString() ?? 0)
        .div(Decimal.pow(10, 6))
        .toNumber();



    const handleConfirm = async () => {
        if (!walletClient || !publicClient || !address) return;
        setConfirmStep(-1);
        setShowConfirm(true);
    };

    const handleConfirmPopup = async () => {
        if (!walletClient || !publicClient || !address) return;
        setConfirmStep(0);
        try {
            if (new Decimal(formik.values.amount).greaterThan(max)) {
                enqueueSnackbar("Insufficient balance", {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                return;
            } else {
                const usdcContract = getContract({
                    abi: erc20ABI,
                    address: getUSDCAddress(chainId) as `0x${string}`,
                    client: {
                        public: publicClient,
                        wallet: walletClient,
                    },
                });

                const allowance = await usdcContract.read.allowance([
                    address,
                    getPermit2Address(chainId) as `0x${string}`,
                ]);
                if (
                    new Decimal(allowance.toString()).greaterThan(
                        new Decimal(formik.values.amount).mul(Decimal.pow(10, 6))
                    )
                ) {
                    setConfirmStep(1);
                } else {
                    await usdcContract.write.approve(
                        [getPermit2Address(chainId) as `0x${string}`, MaxUint256],
                        {
                            gas: BigInt(1_000_000),
                        }
                    );
                    setConfirmStep(1);
                }
                const usdcAddress = getUSDCAddress(chainId) as `0x${string}`;
                const formatedBigIntAmount = BigInt(
                    new Decimal(formik.values.amount)
                        .mul(Decimal.pow(10, 6))
                        .floor()
                        .toString()
                );
                const nonce = BigInt(Date.now());
                const deadline = (BigInt(Date.now()) + 10000000000n) / 1000n;
                const message: PermitTransferFrom = {
                    permitted: {
                        token: usdcAddress,
                        amount: formatedBigIntAmount,
                    },
                    spender: getCapitalProvider(chainId) as `0x${string}`,
                    nonce: BigInt(nonce),
                    deadline: BigInt(deadline),
                };

                const permitData = await SignatureTransfer.getPermitData(
                    message,
                    getPermit2Address(chainId) as `0x${string}`,
                    chainId
                );

                const signature = await walletClient?.signTypedData({
                    account: address,
                    domain: permitData.domain,
                    types: permitData.types,
                    message: {
                        permitted: {
                            token: usdcAddress,
                            amount: formatedBigIntAmount,
                        },
                        spender: getCapitalProvider(chainId) as `0x${string}`,
                        nonce: nonce,
                        deadline: deadline,
                    },
                    primaryType: "PermitTransferFrom",
                });

                await delay(1000);

                setConfirmStep(2);

                const capitalProviderContract = getContract({
                    abi: capitalProviderABI,
                    address: getCapitalProvider(chainId) as `0x${string}`,
                    client: {
                        wallet: walletClient,
                    },
                });

                await capitalProviderContract.write.supply([
                    formatedBigIntAmount,
                    nonce,
                    deadline,
                    signature,
                ], {
                    gas: BigInt(1_000_000)
                });

                await delay(3000);

                enqueueSnackbar("Your investment have been success", {
                    variant: "success",
                    autoHideDuration: 2000,
                });
                queryClient.invalidateQueries({
                    queryKey: [QueriesKey.CapitalProviderInfo, QueriesKey.UserQuoteBalance],
                    exact: false,
                });
            }
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: "error",
                autoHideDuration: 2000,
            });
        } finally {
            setShowConfirm(false);
            setConfirmStep(-1);
        }
    }

    const handleCloseConfirm = () => {
        setShowConfirm(false);
        setConfirmStep(-1);
    };

    const steps = [
        {
            label: t("Approval permit2"),
            description: t("Please confirm on your wallet"),
        },
        {
            label: "Sign message",
            description: t("Please confirm on your wallet"),
        },
        {
            label: "Invest progress",
            description: t("Please wait"),
        },
    ];

    const Schema = Yup.object().shape({
        amount: Yup.number()
            .required(`${t("Please input amount as a number.")}`)
            .min(1)
            .max(max),
    });

    const formik = useFormik({
        initialValues: {
            amount: "",
        },
        validationSchema: Schema,
        onSubmit: async () => {
            handleConfirm();
            await delay(1000);
        },
    });

    const handleMaxAmount = () => {
        formik.setValues({
            ...formik.values,
            amount: max.toString(),
        });
    };
    const { handleSubmit, getFieldProps, errors, handleReset } = formik;


    return (
        <>
            <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <FlexRowAlign justifyContent={'flex-end'} gap={1}>
                            <Typography color={'text.secondary'}>Your balance:</Typography>
                            <Typography>{fCurrency(yieldBalance, 6)} USDC</Typography>
                        </FlexRowAlign>
                        <NumberInput
                            placeholder="0.00"
                            {...getFieldProps("amount")}
                            InputProps={{
                                sx: {
                                    height: 80,
                                    borderRadius: 0,
                                    '& input': {
                                        fontSize: '1.7rem',
                                        pl: 3
                                    }
                                },
                                endAdornment: <Stack spacing={1} height={'100%'} justifyContent={'center'} alignItems={'flex-end'}>
                                    <Typography whiteSpace={'nowrap'} color={'text.secondary'}>Available: {max} USDC</Typography>
                                    <ButtonBase onClick={handleMaxAmount} sx={{ background: theme.palette.background.button, width: 'fit-content', padding: '5px 1.5rem', borderRadius: '0.3rem' }}>
                                        <GradientText

                                            text="MAX"
                                            background="linear-gradient(25deg,#FA00FF,#B580FF 25%,#FFFFFF 35%,#FFFFFF 80%)"
                                            fontSize={'1rem'}
                                        />
                                    </ButtonBase>
                                </Stack>
                            }}
                        />
                        {errors.amount && (
                            <FormHelperText error>{errors.amount}</FormHelperText>
                        )}
                        <Divider sx={{
                            color: theme.palette.text.primary,
                            opacity: 1,
                            fontStyle: 'italic',
                            '&:before, &:after': {
                                border: `1px solid ${theme.palette.text.primary}`,
                            }
                        }} >Deposit now to earn yields & points</Divider>



                        {!isConnected ? <ConnectKitButton.Custom>
                            {({ isConnected, isConnecting, address }) => {
                                return (
                                    <GradientButton onClick={() => { handleOpenModal(isConnected) }} sx={{ textTransform: 'uppercase', fontSize: '1rem' }}>Connect Wallet</GradientButton>
                                );
                            }}
                        </ConnectKitButton.Custom>
                            :
                            <GradientButton type="submit" sx={{ textTransform: 'uppercase', fontSize: '1rem' }}>Deposit</GradientButton>
                        }
                    </Stack>
                </Form>
            </FormikProvider>
            <AppModal open={showConfirm} onOpen={() => { }} onClose={() => { }}>
                <Stack spacing={3}>
                    <Stack spacing={1} pt={2}>
                        <Typography variant="h6" textAlign={"center"} color={"text.primary"}>
                            {t("Invest")} USDC
                        </Typography>
                        <Typography variant="h4" textAlign={"center"} color={"text.primary"}>
                            {fCurrency(formik.values.amount, 2)}
                        </Typography>
                        <Typography textAlign={'center'} color={"text.disabled"}>
                            {"Please confirm your investment"}
                        </Typography>
                    </Stack>
                    <Collapse in={confirmStep >= 0}>
                        <Divider />
                        <StyledStepper activeStep={confirmStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel StepIconComponent={StepIcon}>
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                        >
                                            <Typography fontWeight={600} className="title" pl={1}>
                                                {step.label}
                                            </Typography>
                                            <Iconify
                                                className="completed"
                                                icon={"line-md:confirm"}
                                                sx={{ color: theme.palette.success.main }}
                                            />
                                        </Stack>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography variant="body2" color={"text.secondary"}>
                                            {step.description}
                                        </Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </StyledStepper>
                    </Collapse>
                    <Stack direction={"row"} alignItems={"center"} width='100%' spacing={2}>
                        <PrimaryButton
                            onClick={() => handleCloseConfirm()}
                            shadowSx={{
                                display: 'none'
                            }}
                            sx={{
                                background: theme.palette.background.button,
                                width: '100%',
                                '&:hover': {
                                    background: theme.palette.background.buttonActive
                                }
                            }}
                        >
                            {t("Cancel")}
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => handleConfirmPopup()}
                            loading={confirmStep >= 0}
                        >
                            {t("Confirm")}
                        </PrimaryButton>
                    </Stack>
                </Stack>
            </AppModal>
        </>
    )

}


function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const theme = useTheme();

    const icons: { [index: string]: React.ReactElement } = {
        1: (
            <Iconify
                icon="material-symbols-light:order-approve-rounded"
                size={20}
                sx={{ color: `text.${active ? "primary" : "secondary"}` }}
            />
        ),
        2: (
            <Iconify
                icon="mdi:sign"
                size={20}
                sx={{ color: `text.${active ? "primary" : "secondary"}` }}
            />
        ),
        3: (
            <Iconify
                icon="fa6-solid:download"
                size={15}
                sx={{ color: `text.${active ? "primary" : "secondary"}` }}
            />
        ),
    };

    return (
        <StyledStepIcon className={active ? "active" : ""}>
            {icons[String(props.icon)]}
        </StyledStepIcon>
    );
}

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
    background: theme.palette.warning.main,
    height: 45,
    borderRadius: "3px",
    color: "#191818",
    fontSize: "0.9rem",
    fontWeight: 600,
    width: "100%",
    "&:hover": {
        background: theme.palette.warning.dark,
    },
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
    width: "100%",
    padding: "2rem 1rem",
    "& .MuiStepConnector-root, & .MuiStepContent-root": {
        marginLeft: "19px",
        borderLeft: `2px solid ${theme.palette.divider}`,
        paddingLeft: "2.2rem",
        "& .MuiStepConnector-line": {
            border: "none",
        },
    },
    "& .MuiStepContent-last ": {
        borderLeft: "none !important",
    },
    "& .iconify.completed": {
        opacity: 0,
    },
    "& .Mui-completed": {
        "& .title": {
            opacity: 0.4,
        },
        "& .iconify.completed": {
            opacity: 1,
        },
    },
}));

const StyledStepIcon = styled(Stack)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: 40,
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.action.hover,
    position: "relative",
    "&.active": {
        background: theme.palette.primary.dark,
        color: "#fff",
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 0.6s infinite ease-in-out",
            border: `2px solid ${theme.palette.primary.main}`,
            content: '""',
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.9)",
                opacity: 0.4,
            },
            "100%": {
                transform: "scale(1.4)",
                opacity: 0,
            },
        },
    },
}));
