import { Box, BoxProps, styled } from "@mui/material";
import { FC, Fragment, ReactNode } from "react";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  transition: "all 0.3s",
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginLeft: 0,
  },
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  [theme.breakpoints.up("lg")]: { margin: "auto" },
}));

// --------------------------------------------
type LayoutBodyWrapperProps = {
  sx?: BoxProps["sx"];
  children: ReactNode;
};
// --------------------------------------------

const LayoutBodyWrapper: FC<LayoutBodyWrapperProps> = ({ children, sx }) => {
  return (
    <Fragment>
      <Wrapper sx={sx}>
        <InnerWrapper>{children}</InnerWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default LayoutBodyWrapper;
