import { BigNumberish } from "@ethersproject/bignumber";
import { FeeAmount } from "@uniswap/v3-sdk";
import { signatureExecutorAddress } from "contracts/contractAddress";
import { EIP712Domain } from "viem/zksync";
import { Address, TypedData, TypedDataDomain, hashTypedData } from 'viem';
import { MaxSigDeadline, MaxSignatureTransferAmount, MaxUnorderedNonce } from "./constants";
import invariant from 'tiny-invariant';

export interface Witness {
    witness: any
    witnessTypeName: string
    witnessType: TypedData
}

export interface TokenPermissions {
    token: Address
    amount: bigint
}

export interface PermitTransferFrom {
    permitted: TokenPermissions
    spender: Address
    nonce: bigint
    deadline: bigint
}

export interface PermitBatchTransferFrom {
    permitted: TokenPermissions[]
    spender: Address
    nonce: bigint
    deadline: bigint
}

export type PermitTransferFromData = {
    domain: TypedDataDomain
    types: TypedData
    values: PermitTransferFrom
}

export type PermitBatchTransferFromData = {
    domain: TypedDataDomain
    types: TypedData
    values: PermitBatchTransferFrom
}

const TOKEN_PERMISSIONS = [
    { name: 'token', type: 'address' },
    { name: 'amount', type: 'uint256' },
] as const

const PERMIT_TRANSFER_FROM_TYPES = {
    TokenPermissions: TOKEN_PERMISSIONS,
    PermitTransferFrom: [
        { name: 'permitted', type: 'TokenPermissions' },
        { name: 'spender', type: 'address' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
    ],
} as const

const PERMIT_BATCH_TRANSFER_FROM_TYPES = {
    TokenPermissions: TOKEN_PERMISSIONS,
    PermitBatchTransferFrom: [
        { name: 'permitted', type: 'TokenPermissions[]' },
        { name: 'spender', type: 'address' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
    ],
} as const

function isPermitTransferFrom(permit: PermitTransferFrom | PermitBatchTransferFrom): permit is PermitTransferFrom {
    return !Array.isArray(permit.permitted)
}


const PERMIT2_DOMAIN_NAME = 'Permit2'

export function permit2Domain(permit2Address: Address, chainId: number): TypedDataDomain {
    return {
        name: PERMIT2_DOMAIN_NAME,
        chainId,
        verifyingContract: permit2Address,
    }
}


export abstract class SignatureTransfer {
    /**
     * Cannot be constructed.
     */
    private constructor() { }

    public static getPermitTransferData(permit: PermitTransferFrom, permit2Address: Address, chainId: number) {
        invariant(MaxSigDeadline >= permit.deadline, 'SIG_DEADLINE_OUT_OF_RANGE')
        invariant(MaxUnorderedNonce >= permit.nonce, 'NONCE_OUT_OF_RANGE')

        const domain = permit2Domain(permit2Address, chainId) as TypedDataDomain

        validateTokenPermissions(permit.permitted)

        return {
            domain,
            types: PERMIT_TRANSFER_FROM_TYPES,
            values: permit,
            primaryType: "PermitTransferFrom"
        }
    }

    public static getPermitBatchTransferData(permit: PermitBatchTransferFrom, permit2Address: Address, chainId: number) {
        invariant(MaxSigDeadline >= permit.deadline, 'SIG_DEADLINE_OUT_OF_RANGE')
        invariant(MaxUnorderedNonce >= permit.nonce, 'NONCE_OUT_OF_RANGE')

        const domain = permit2Domain(permit2Address, chainId)

        permit.permitted.forEach(validateTokenPermissions)

        return {
            domain,
            types: PERMIT_BATCH_TRANSFER_FROM_TYPES,
            values: permit,
        }
    }

    // return the data to be sent in a eth_signTypedData RPC call
    // for signing the given permit data
    public static getPermitData(
        permit: PermitTransferFrom | PermitBatchTransferFrom,
        permit2Address: Address,
        chainId: number,
        _witness?: Witness
    ): PermitTransferFromData | PermitBatchTransferFromData {
        if (isPermitTransferFrom(permit)) {
            return this.getPermitTransferData(permit, permit2Address, chainId)
        } else {
            return this.getPermitBatchTransferData(permit, permit2Address, chainId)
        }
    }

    public static hash(
        permit: PermitTransferFrom | PermitBatchTransferFrom,
        permit2Address: Address,
        chainId: number,
        _witness?: Witness
    ): string {
        if (isPermitTransferFrom(permit)) {
            const { domain, types, values } = SignatureTransfer.getPermitTransferData(permit, permit2Address, chainId)

            return hashTypedData({
                domain,
                types,
                primaryType: 'PermitTransferFrom',
                message: values,
            })
        } else {
            const { domain, types, values } = SignatureTransfer.getPermitBatchTransferData(permit, permit2Address, chainId)

            return hashTypedData({
                domain,
                types,
                primaryType: 'PermitBatchTransferFrom',
                message: values,
            })
        }
    }
}

function validateTokenPermissions(permissions: TokenPermissions) {
    invariant(MaxSignatureTransferAmount >= permissions.amount, 'AMOUNT_OUT_OF_RANGE')
}



export const ORDER_DOMAIN_NAME = 'SignatureExecutor';
export function orderDomain(executor: string, chainId: number) {
    return {
        name: ORDER_DOMAIN_NAME,
        chainId: chainId,
        verifyingContract: executor
    } as TypedDataDomain;
}

export const LONG_ORDER_TYPES = {
    SigOpenLongParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'leverage',
        type: 'uint16'
    }, {
        name: 'maxQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const WITHDRAW_TYPES = {
    SigWithdrawFundParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'amountToWithdraw',
        type: 'uint256'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;



export const WITHDRAW_CROSS_TYPES = {
    SigWithdrawFundCrossParams: [{
        name: 'trader',
        type: 'address'
    }, {
        name: 'amountToWithdraw',
        type: 'uint256'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const LONG_CROSS_ORDER_TYPES = {
    SigOpenLongCrossParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'leverage',
        type: 'uint16'
    }, {
        name: 'maxQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const SHORT_ORDER_TYPES = {
    SigOpenShortParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'leverage',
        type: 'uint16'
    }, {
        name: 'minQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const SHORT_CROSS_ORDER_TYPES = {
    SigOpenShortCrossParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'leverage',
        type: 'uint16'
    }, {
        name: 'minQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const CLOSE_SHORT_TYPES = {
    SigCloseShortParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'maxQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const CLOSE_CROSS_SHORT_TYPES = {
    SigCloseShortCrossParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'maxQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;




export const CLOSE_LONG_TYPES = {
    SigCloseLongParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'minQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;


export const CLOSE_CROSS_LONG_TYPES = {
    SigCloseLongCrossParams: [{
        name: 'pairId',
        type: 'uint16'
    }, {
        name: 'trader',
        type: 'address'
    }, {
        name: 'baseAmount',
        type: 'uint256'
    }, {
        name: 'minQuoteAmount',
        type: 'uint256'
    }, {
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
    }, {
        name: 'uniFee',
        type: 'uint24'
    }, {
        name: 'nonce',
        type: 'uint256'
    }, {
        name: 'deadline',
        type: 'uint256'
    }],
} as const satisfies TypedData;

export type Order = {
    pairId: BigNumberish;
    trader: string;
    baseAmount: BigNumberish;
    leverage: BigNumberish;
    maxQuoteAmount?: BigNumberish;
    minQuoteAmount?: BigNumberish;
    sqrtPriceLimitX96: BigNumberish; // to ensure the swap doesn't slip too far away
    uniFee: FeeAmount;
    nonce: BigNumberish;
    deadline: BigNumberish;
}

export type CloseOrder = {
    pairId: BigNumberish;
    trader: `0x${string}`;
    baseAmount: BigNumberish;
    minQuoteAmount?: BigNumberish;// this will be used for limit orders
    maxQuoteAmount?: BigNumberish;// this will be used for limit orders
    sqrtPriceLimitX96: BigNumberish;// to ensure the swap doesn't slip too far away
    uniFee: BigNumberish;
    nonce: BigNumberish;
    deadline: BigNumberish;
}

export type WithdrawData = {
    pairId?: BigNumberish;
    trader: `0x${string}`;
    amountToWithdraw: BigNumberish;
    nonce: BigNumberish;
    deadline: BigNumberish;
}





export async function getOpenOrderData(chainId: number, order: Order, marginType: 'ISOLATED' | 'CROSS' = 'ISOLATED') {
    const domain = orderDomain(signatureExecutorAddress(chainId) as `0x${string}`, chainId)
    const isLong = !!order.maxQuoteAmount
    const types = isLong ? (marginType === 'ISOLATED' ? LONG_ORDER_TYPES : LONG_CROSS_ORDER_TYPES) : (marginType === 'ISOLATED' ? SHORT_ORDER_TYPES : SHORT_CROSS_ORDER_TYPES)
    const primaryType = isLong ? (marginType === 'ISOLATED' ? "SigOpenLongParams" : 'SigOpenLongCrossParams') : (marginType === 'ISOLATED' ? "SigOpenShortParams" : 'SigOpenShortCrossParams')
    return { domain, types, primaryType, message: order } as unknown as EIP712Domain<typeof types>
}

export async function getOpenCrossOrderData(chainId: number, order: Order) {
    const domain = orderDomain(signatureExecutorAddress(chainId) as `0x${string}`, chainId)
    const isLong = !!order.maxQuoteAmount
    const types = isLong ? LONG_CROSS_ORDER_TYPES : SHORT_CROSS_ORDER_TYPES;
    const primaryType = isLong ? "SigOpenLongCrossParams" : "SigOpenShortCrossParams"
    return { domain, types, primaryType, message: order } as unknown as EIP712Domain<typeof types>
}


export async function getCloseOrderData(chainId: number, closeOrder: CloseOrder, marginType: 'ISOLATED' | 'CROSS') {
    const domain = orderDomain(signatureExecutorAddress(chainId) as `0x${string}`, chainId)
    const isLong = !!closeOrder.minQuoteAmount
    const types = isLong ? (marginType === 'ISOLATED' ? CLOSE_LONG_TYPES : CLOSE_CROSS_LONG_TYPES) : (marginType === 'ISOLATED' ? CLOSE_SHORT_TYPES : CLOSE_CROSS_SHORT_TYPES)
    const primaryType = isLong ? (marginType === 'ISOLATED' ? 'SigCloseLongParams' : 'SigCloseLongCrossParams') : (marginType === 'ISOLATED' ? 'SigCloseShortParams' : 'SigCloseShortCrossParams')
    return { domain, types, primaryType, message: closeOrder } as unknown as EIP712Domain<typeof types>
}

export async function getWithdrawSignatureData(chainId: number, withdraw: WithdrawData, marginType: 'ISOLATED' | 'CROSS') {
    const domain = orderDomain(signatureExecutorAddress(chainId) as `0x${string}`, chainId)
    const types = marginType === 'ISOLATED' ? WITHDRAW_TYPES : WITHDRAW_CROSS_TYPES;
    const primaryType = marginType === 'ISOLATED' ? "SigWithdrawFundParams" : "SigWithdrawFundCrossParams"
    return { domain, types, primaryType, message: withdraw } as unknown as EIP712Domain<typeof types>
}




