import { Box, Button, Divider, Drawer, Hidden, IconButton, Stack, Typography, colors, styled, useTheme } from "@mui/material"
import { navigation } from "./navigation";
import { useNavigate } from "react-router-dom";
import Iconify from "components/Iconify";
import LayoutDrawer from "./LayoutDrawer";
import { useState } from "react";

export const MainMenu = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const handleClickMenu = (item: typeof navigation[0]) => {
        navigate(item.path)
        setOpen(false)
    }
    return <>
        <Hidden mdDown>
            <Stack ml={5} direction={'row'} alignItems={"center"} spacing={3}>
                {navigation.map((item) => (
                    <MenuItem
                        onClick={() => handleClickMenu(item)}
                        disableRipple
                        key={item.path}
                        className={window.location.pathname === item.path ? 'active' : ''}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Stack>
        </Hidden>
        <Hidden mdUp>
            <IconButton onClick={() => setOpen(true)}>
                <Iconify icon={'mingcute:menu-line'} size={24} sx={{ color: theme.palette.text.primary }} />
            </IconButton>
            <Drawer anchor="left" open={open} PaperProps={{ sx: { width: '100%' } }}>
                <Stack p={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <img
                        onClick={() => navigate("/trading")}
                        src={`/logo.png`}
                        alt="logo-full"
                        style={{ height: "35px", cursor: "pointer" }}
                    />
                    <IconButton onClick={() => setOpen(false)}>
                        <Iconify icon={'oui:arrow-right'} size={20} sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                </Stack>
                <Divider />
                <Stack mt={2} alignItems={"center"}>
                    {navigation.map((item) => (
                        <MenuItem
                            onClick={() => handleClickMenu(item)}
                            disableRipple
                            key={item.path}
                            className={window.location.pathname === item.path ? 'active' : ''}
                            sx={{ height: 40, alignItems: 'center', justifyContent: 'flex-start', width: '100%', paddingX: '1rem' }}
                        >
                            {item.icon && <Stack width={40} alignItems={'flex-start'} justifyContent={'center'}>
                                <Iconify icon={item.icon} size={25} sx={{ marginTop: '-2px' }} />
                            </Stack>}
                            {item.name}
                        </MenuItem>
                    ))}
                </Stack>
            </Drawer>
        </Hidden>
    </>
}

const MenuItem = styled(Button)(({ theme }) => ({
    fontWeight: 500,
    fontSize: "0.85rem",
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    minWidth: 'unset',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    "&:hover, &.active": {
        color: theme.palette.primary.main,
    },
}));