import { forwardRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";

export type CustomTextFieldProps = {
  decimal?: number;
  endAdornment?: any
} & TextFieldProps;

export const NumberInput = forwardRef(function NumberInput(
  props: CustomTextFieldProps,
  ref
) {
  const { decimal = 18 } = props;
  const handleKeyDown = (e: any) => {
    const code = e.keyCode;
    const dotCount = e.target.value.indexOf(".");
    const decimalLength = e.target.value.split(".")[1]?.length + 1;
    if (
      (code < 58 && code > 47 && !e.shiftKey) ||
      (code < 100 && code > 88 && !e.shiftKey) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      e.metaKey ||
      code === 39 ||
      (code === 190 && dotCount < 0)
    ) {
      if (
        ((code < 58 && code > 47) || (code < 100 && code > 88)) &&
        decimalLength > decimal
      ) {
        e.preventDefault();
      }
      return;
    } else e.preventDefault();
  };
  return (
    <TextField
      {...props}
      inputProps={{
        inputMode: "numeric",
      }}
      onKeyDown={handleKeyDown}
    />
  );
});
