import { createTheme, responsiveFontSizes } from "@mui/material";
import merge from "lodash/merge";
import { THEMES } from "../constants";
import components from "./components";
import { shadows } from "./shadows";
import themesOptions from "./themeOptions";
import { Transaction } from "web3/Types";
import { Pair, Pair_GetRes } from "types/tradePairs";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";


const baseOptions = {
  direction: "ltr",
  typography: {
    fontFamily: "'Bai Jamjuree', 'Inter', sans-serif",
    fontSize: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};

export type themeSettingsTypes = {
  activeLayout?: string;
  theme: string;
  direction: "ltr" | "rtl";
  responsiveFontSizes?: boolean;
  lang?: string,
  currentPair?: Pair | Pair_GetRes | null,
  favoritePairs?: string[],
  baseAsset?: string,
  allowedSlippage?: string,
  orderLiveTime?: number,
  hideOtherSymbols?: boolean
};



export type Leverage = {
  pair?: string;
  lvg?: number
}
export type tradeSettingsTypes = {
  priceMode?: 'MARKET' | 'LIMIT';
  currentTradeMode: 'ISOLATED' | 'CROSS';
  leverages?: Leverage[],
  hideOtherSymbols?: boolean
};

export type userContextTypes = {
  liveTransactions?: Transaction[],
  accessToken?: string | null,
  refreshToken?: string | null,
  address?: string | null
}

export const createCustomTheme = (settings: themeSettingsTypes) => {
  /**
   * settings.theme value is 'light' or 'dark'
   * update settings in contexts/settingsContext.tsx
   */
  let themeOptions: any = themesOptions[settings.theme];

  if (!themeOptions) {
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const mergedThemeOptions = merge({}, baseOptions, themeOptions, {
    direction: settings.direction,
  });

  let theme = createTheme(mergedThemeOptions);

  // set shadows
  theme.shadows = shadows(theme);
  // set components
  theme.components = components(theme);
  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
