import { styled, Stack, ButtonBase, Typography, useTheme } from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import { TradeSettingsContext } from "contexts/tradeSettingsContext";
import Decimal from "decimal.js";
import usePairInfo from "hooks/usePairInfo";
import useQuoter from "hooks/useQuote";
import useSettings from "hooks/useSettings";
import useUserBalance from "hooks/useUserBalance";
import { t } from "i18next";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fCurrency, formatBigIntToReadable } from "utils/format";
import { useAccount, useBalance, useChainId } from "wagmi";
// component props interface
interface BalanceOverviewProps {}

const WalletButton = styled(ButtonBase)(({ theme }) => ({
  height: 25,
  padding: "0px 0.5rem",
  fontWeight: 600,
  borderRadius: 2,
  color: theme.palette.text.disabled,
  "&:hover": {
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.text.secondary,
  },
  "&.active": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.text.primary
        : theme.palette.warning.main,
    backgroundColor: theme.palette.action.active,
  },
}));

const BalanceOverview: FC<BalanceOverviewProps> = (props) => {
  const {} = props;
  const [tabIndex, setTabIndex] = useState(0);
  const handleActiveTab = (index: number) => {
    setTabIndex(index || 0);
  };
  return (
    <Fragment>
      <Stack flexGrow={1}>
        <Stack direction={"row"} spacing={0.5}>
          <WalletButton
            onClick={() => handleActiveTab(0)}
            className={tabIndex === 0 ? "active" : ""}
          >
            {t("Funding")}
          </WalletButton>
          <WalletButton
            onClick={() => handleActiveTab(1)}
            className={tabIndex === 1 ? "active" : ""}
          >
            {t("Wallet")}
          </WalletButton>
        </Stack>
        <Stack>{tabIndex === 0 ? <FundingBalance /> : <WalletBalance />}</Stack>
      </Stack>
    </Fragment>
  );
};

export default BalanceOverview;

const FundingBalance = () => {
  const theme = useTheme();
  const { settings } = useContext(SettingsContext);
  const { currentPair } = settings;
  const { pairInfo } = usePairInfo();

  const quote = useQuoter(
    currentPair?.baseTokenAddress,
    currentPair?.quoteTokenAddress,
    1,
    currentPair?.baseTokenDecimal,
    currentPair?.quoteTokenDecimal,
    currentPair?.uniswapFee
  );

  const APY = new Decimal(
    pairInfo?.reserveToken1?.currentLiquidityRate?.toString() ?? 0
  ).div(Decimal.pow(10, 25));
  /// Base Collateral
  const marginBalance = new Decimal(
    (pairInfo?.userInfo?.[0] ?? BigInt(0)).toString()
  )
    .mul(quote?.priceExactOutputToken1 || 0)
    .div(
      Decimal.pow(
        10,
        (currentPair?.baseTokenDecimal ?? 0) -
          (currentPair?.quoteTokenDecimal ?? 0)
      )
    )
    /// USDC Collateral
    .add((pairInfo?.userInfo?.[2] ?? BigInt(0)).toString())
    /// USDC Debt
    .sub((pairInfo?.userInfo?.[3] ?? BigInt(0)).toString())
    /// Base Debt
    .sub(
      new Decimal((pairInfo?.userInfo?.[1] ?? BigInt(0)).toString())
        .mul(quote?.priceExactOutputToken1 || 0)
        .div(
          Decimal.pow(
            10,
            (currentPair?.baseTokenDecimal ?? 0) -
              (currentPair?.quoteTokenDecimal ?? 0)
          )
        )
    );
  return (
    <Stack px={1} py={2}>
      <Typography fontWeight={500} color={theme.palette.text.disabled}>
        {t("Total balance")}
      </Typography>
      <Typography fontWeight={500} fontSize={"1.3rem"}>
        {formatBigIntToReadable(
          marginBalance.toHex(),
          currentPair?.quoteTokenDecimal,
          6
        )}
        <span
          style={{
            fontSize: "1rem",
            marginLeft: "5px",
            color: theme.palette.text.disabled,
          }}
        >
          {currentPair?.quoteToken}
        </span>
      </Typography>
    </Stack>
  );
};

const WalletBalance = () => {
  const theme = useTheme();
  const address = useAccount();
  const { settings } = useSettings();
  const chainId = useChainId();
  const { currentPair } = settings;
  const userBalance = useUserBalance();

  return (
    <Stack px={1} py={2}>
      <Typography fontWeight={500} color={theme.palette.text.disabled}>
        {t("Total balance")}
      </Typography>
      {
        <Typography fontWeight={500} fontSize={"1.3rem"}>
          {formatBigIntToReadable(
            userBalance ?? 0,
            currentPair?.quoteTokenDecimal,
            4
          )}{" "}
          <span
            style={{ fontSize: "1rem", color: theme.palette.text.disabled }}
          >
            {currentPair?.quoteToken}
          </span>
        </Typography>
      }
    </Stack>
  );
};
