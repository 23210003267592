import {
  Button,
  ButtonBase,
  Divider,
  IconButton,
  MenuItem,
  OutlinedInput,
  Stack,
  Switch,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Iconify from "components/Iconify";
import { SettingsContext } from "contexts/settingsContext";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { themeSettingsTypes } from "theme";
import PopoverBase from "./PopoverBase";
import { OrderLiveTime } from "../../../constants";
import { NumberInput } from "components/input-fields/NumberInput";

// dummy language options
const languageOptions: {
  [key: string]: { icon: string; label: string };
} = {
  en: {
    icon: "/static/flags/usa.png",
    label: "English",
  },
  es: {
    icon: "/static/flags/spain.png",
    label: "Spanish",
  },
};

const SettingPopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [slippage, setSlippage] = useState("");
  const [currentLiveTime, setCurrentLiveTime] = useState(7);
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const { settings, saveSettings } = useContext(SettingsContext);
  const { lang, allowedSlippage, orderLiveTime } = settings;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeLanguage = (language: string) => {
    saveSettings({ ...settings, lang: language });
  };
  const handleChangeTheme = (value: "light" | "dark") => {
    saveSettings({ ...settings, theme: value } as themeSettingsTypes);
  };

  const handleSaveSettings = () => {
    saveSettings({
      ...settings,
      allowedSlippage: slippage,
      orderLiveTime: currentLiveTime,
    });
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (allowedSlippage && open) {
      setSlippage(allowedSlippage);
    }
  }, [allowedSlippage, open]);

  useEffect(() => {
    if (orderLiveTime && open) setCurrentLiveTime(orderLiveTime);
  }, [orderLiveTime, open]);

  return (
    <>
      <StyledIconButton onClick={handleOpen} ref={anchorRef}>
        <Iconify icon={"fluent:settings-28-filled"} />
      </StyledIconButton>
      <PopoverBase open={open} onClose={handleClose} anchorRef={anchorRef}>
        <Stack direction={"row"} p={2} pt={3} pr={3} mb={1} spacing={2}>
          <Stack minWidth={150}>
            <Title color={theme.palette.text.disabled} px={2} pb={2}>
              Language
            </Title>
            {Object.keys(languageOptions).map((language: string) => (
              <MenuItem
                key={languageOptions[language].label}
                onClick={() => handleChangeLanguage(language)}
                sx={{
                  marginBottom: "2px",
                  borderRadius: `${theme.shape.borderRadius}px`,
                  background:
                    language === lang ? theme.palette.action.active : "",
                }}
              >
                <Stack
                  flexGrow={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>{languageOptions[language].label}</Typography>
                  {language === lang && (
                    <Iconify
                      sx={{ color: theme.palette.primary.main, opacity: 0.8 }}
                      icon={"vaadin:dot-circle"}
                    />
                  )}
                </Stack>
              </MenuItem>
            ))}
          </Stack>
          <Stack spacing={2} mb={1}>
            <Title color={theme.palette.text.disabled}>
              {t("Allowed Slippage")}
            </Title>
            <SlippageInput
              InputProps={{
                endAdornment: "%",
              }}
              size="small"
              value={slippage}
              onChange={(e) => setSlippage(e.target.value)}
            />
            <Title color={theme.palette.text.disabled}>
              {t("Default Order Lifetime")}
            </Title>
            <Stack direction={"row"} spacing={1}>
              {OrderLiveTime.map((item: number, index: number) => (
                <StyleTimeOption
                  onClick={() => setCurrentLiveTime(item)}
                  className={currentLiveTime === item ? "active" : ""}
                  key={index}
                >
                  {item}D
                </StyleTimeOption>
              ))}
            </Stack>
            <Button variant="outlined" onClick={() => handleSaveSettings()}>
              Save
            </Button>
          </Stack>
        </Stack>
        {/* <Divider />
        <Stack py={2}>
          <Title px={4} color={theme.palette.text.disabled}>
            {t("Theme settings")}
          </Title>
          <Stack pl={4} pr={2} mt={1} spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>{t("Dark mode")}</Typography>
              <Switch
                disabled={true}
                checked={Boolean(theme.palette.mode === "dark")}
                onChange={(e, value) =>
                  handleChangeTheme(value ? "dark" : "light")
                }
              />
            </Stack>
          </Stack>
        </Stack> */}
      </PopoverBase>
    </>
  );
};

export default SettingPopover;

// custom styled components
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.button,
  width: 40,
  height: 40,
  color: theme.palette.text.secondary,
  "&:hover": { backgroundColor: theme.palette.background.buttonActive },
}));

const Title = styled(Typography)(() => ({
  fontWeight: 600,
}));

const SlippageInput = styled(NumberInput)(({ theme }) => ({
  height: 30,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    background: theme.palette.action.disabledBackground,
  },
  "& input": {
    textAlign: "right",
    paddingRight: "1rem",
  },
}));

const StyleTimeOption = styled(ButtonBase)(({ theme }) => ({
  height: 30,
  fontWeight: 600,
  padding: "0px 1rem",
  borderRadius: "0.25rem",
  "&:hover,&.active": {
    background: theme.palette.action.active,
  },
}));
