import { replace } from 'lodash';
import numeral from 'numeral';
import { format, formatDistanceToNow } from 'date-fns';
import { BigNumberish } from '@ethersproject/bignumber';
import Decimal from 'decimal.js';

// Number ----------------------------------------------------------------------

export function fCurrency(number: number | string, fix: number) {
  if (!number || Number.isNaN(number) || number === 'NaN') number = 0
  try {
    let str = new Decimal(number).toFixed(18)
    const deleteText = str.replace(/[^\d.]/g, ""); //clear text
    const x = deleteText.split(".");
    let x1 = x[0];
    const x2 = x[1];
    const x3 = x.length > 1 && fix > 0 ? ("." + x2.slice(0, fix || 8)) : "";
    if (!x1) x1 = "0";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1,$2");
    }
    let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
    return `${Number(number) < 0 ? "-" : ""}${result}`;
  } catch (e) {
    return "0.00";
  }
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fShortenNumber(number: number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: number) {
  return numeral(number).format('0.0 b');
}

export function fRoundDown(number: number, decimals: number) {
  decimals = decimals || 0;
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}


// Date time ----------------------------------------------------------------------

export function fDate(date: any) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date: any) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm');
}

export function fDateTimeSuffix(date: any) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: any) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

// bigint to readable 

export function formatBigIntToReadable(value: BigNumberish | undefined, decimal: number | undefined, digits: number = 6) {
  if (!value || !decimal) return '0'
  return new Intl.NumberFormat('en-EN', { maximumSignificantDigits: digits ?? 6 }).format(
    new Decimal(value.toString()).div(Decimal.pow(10, decimal)).toNumber(),
  );
}


export function shortenNumber(number:any, decimal:any, threshold = 1000, suffixes = ['', 'K', 'M', 'B', 'T']) {
  const input = parseFloat(number) || 0
  if (input <= threshold) return fCurrency(number, decimal || 0)
  const absNum = Math.abs(input);
  const suffixIndex = Math.floor(Math.log10(absNum) / Math.log10(threshold));
  const formattedNum = input / Math.pow(threshold, suffixIndex);
  const suffix = suffixes[suffixIndex];
  return fCurrency(formattedNum, 2) + suffix;
}

// String ----------------------------------------------------------------------

export function fDisplayName(displayName = "") {
  const length = displayName.length
  if (length > 15) {
    return `${displayName.substring(0, 5)}...${displayName.substring(length - 5, length)}`
  }
  return displayName
}

/// Float to Readable number 

export function ReadableNumber(value: BigNumberish, digits: number = 6) {
  if (!value) return '0'
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: digits ?? 6 }).format(
    new Decimal(value.toString()).toNumber(),
  );
}


