import { SwipeableDrawer, Popover, Hidden, styled, SxProps } from "@mui/material";
import React, { FC, Fragment } from "react";
// component props interface
interface PopoverBaseProps {
  open: boolean,
  children: React.ReactNode,
  onClose: () => void,
  onOpen?: () => void,
  anchorRef: React.MutableRefObject<null>;
}

const Puller = styled('div')(({ theme }) => ({
  width: 40,
  height: 6,
  backgroundColor: theme.palette.background.default,
  borderRadius: 3,
  position: 'absolute',
  top: 13,
  left: 'calc(50% - 20px)',
}));

const SwipeablePaperStyle: SxProps = {
  borderRadius: '1rem 1rem 0px 0px',
  paddingTop: 3,
  overflowX: 'hidden'
}

const PopoverBase: FC<PopoverBaseProps> = (props) => {
  const {
    children,
    open,
    onClose,
    onOpen,
    anchorRef,
  } = props;
  return (
    <Fragment>
      <Hidden mdDown>
        <Popover
          open={open}
          onClose={onClose}
          anchorEl={anchorRef.current}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          sx={{ mt: 1 }}
        >
          {children}
        </Popover>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          container={document.body}
          anchor="bottom"
          open={Boolean(open)}
          onClose={onClose}
          onOpen={onOpen ? onOpen : () => { }}
          disableSwipeToOpen={false}
          PaperProps={{
            sx: SwipeablePaperStyle,
          }}
        >
          <Puller />
          {children}
        </SwipeableDrawer>
      </Hidden>

    </Fragment>
  );
};

export default PopoverBase;
