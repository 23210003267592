import { useQuery } from "@tanstack/react-query";
import { useAccount } from "wagmi";
import useUserSections from "./useUserSections";
import { QueriesKey } from "utils/constants";
import useAxios from "./useAxios";

const useUserEmail = () => {
  const { address } = useAccount();
  const { userSections } = useUserSections();
  const { callApi } = useAxios();
  const { data: userEmail, isLoading } = useQuery({
    enabled: !!address && !!userSections?.accessToken,
    queryKey: [QueriesKey.UserEmail, userSections?.accessToken],
    queryFn: async () => {
      const response = await callApi<
        any,
        { email: string; refCode: string; status: string }[]
      >("get", "auth/user-info");
      return response;
    },
  });
  return userEmail;
};

export default useUserEmail;
