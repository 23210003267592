import { useQuery } from "@tanstack/react-query";
import { SettingsContext } from "contexts/settingsContext";
import { useContext } from "react";
import { getContract } from "viem";
import { useAccount, useChainId, usePublicClient } from "wagmi";
import { aaveABI } from "../contracts/abis/aavePool";
import { isolatedMarginABI } from "../contracts/abis/isolatedMargin";

import { TradeSettingsContext } from "contexts/tradeSettingsContext";
import { crossMarginABI } from "contracts/abis/crossMargin";
import {
  getAAVEPoolAddress,
  getCrossMarginAddress,
  getIsolatedMarginAddress,
} from "contracts/contractAddress";
import Decimal from "decimal.js";
import { QueriesKey } from "utils/constants";
import { Pair } from "types/tradePairs";

const usePairInfo = (pair?: Pair) => {
  const { settings } = useContext(SettingsContext);
  const { currentPair } = settings;
  const { address } = useAccount();
  const publicClient = usePublicClient();
  const chainId = useChainId();
  const { tradeSettings } = useContext(TradeSettingsContext);
  const { currentTradeMode } = tradeSettings;
  const pairToGet = pair ?? currentPair;

  const { data: pairInfo, isLoading } = useQuery({
    enabled: !!currentTradeMode && !!address && !!pairToGet,
    queryKey: [
      QueriesKey.MarginBalance,
      chainId,
      address,
      pairToGet?.baseTokenAddress,
      pairToGet?.quoteTokenAddress,
      currentTradeMode,
    ],
    queryFn: async () => {
      if (!publicClient || !address) return;
      const isolatedContract = getContract({
        abi: isolatedMarginABI,
        address: getIsolatedMarginAddress(chainId) as `0x${string}`,
        client: {
          public: publicClient,
        },
      });

      const crossContract = getContract({
        abi: crossMarginABI,
        address: getCrossMarginAddress(chainId) as `0x${string}`,
        client: {
          public: publicClient,
        },
      });

      const aavePoolContract = getContract({
        abi: aaveABI,
        address: getAAVEPoolAddress(chainId) as `0x${string}`,
        client: {
          public: publicClient,
        },
      });

      const results = await publicClient.multicall({
        contracts: [
          {
            ...isolatedContract,
            functionName: "getUserData",
            args: [address, pairToGet?.id as number],
          },
          {
            ...crossContract,
            functionName: "getUserData",
            args: [address, pairToGet?.id as number],
          },
          {
            ...crossContract,
            functionName: "getUserData",
            args: [address, 0],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveData",
            args: [pairToGet?.baseTokenAddress as `0x${string}`],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveData",
            args: [pairToGet?.quoteTokenAddress as `0x${string}`],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveNormalizedIncome",
            args: [pairToGet?.baseTokenAddress as `0x${string}`],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveNormalizedVariableDebt",
            args: [pairToGet?.baseTokenAddress as `0x${string}`],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveNormalizedIncome",
            args: [pairToGet?.quoteTokenAddress as `0x${string}`],
          },
          {
            ...aavePoolContract,
            functionName: "getReserveNormalizedVariableDebt",
            args: [pairToGet?.quoteTokenAddress as `0x${string}`],
          },
        ],
      });

      const userInfo =
        currentTradeMode === "ISOLATED" ? results[0].result : results[1].result;
      const crossCollateral =  results[2].result;
      const reserveToken0 = results[3].result;
      const reserveToken1 = results[4].result;
      const baseReserveNormalizedIncome = results[5].result;
      const baseReserveNormalizedVariableDebt = results[6].result;
      const quoteReserveNormalizedIncome = results[7].result;
      const quoteReserveNormalizedVariableDebt = results[8].result;
      const formattedAvailable = new Decimal(
        (userInfo?.[4] ?? BigInt(0)).toString()
      )
        .div(new Decimal(10).pow(currentPair?.quoteTokenDecimal ?? 0))
        .toFixed();
      return {
        userInfo: userInfo,
        formattedAvailable: formattedAvailable,
        crossCollateral,
        reserveToken0,
        reserveToken1,
        baseReserveNormalizedIncome,
        baseReserveNormalizedVariableDebt,
        quoteReserveNormalizedIncome,
        quoteReserveNormalizedVariableDebt,
      };
    },
  });
  return { pairInfo, isLoading };
};

export default usePairInfo;
