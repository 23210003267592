import {
  Divider,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CustomTable, { ColumFormat } from "components/CustomTable";
import Decimal from "decimal.js";
import useAxios from "hooks/useAxios";
import useResponsive from "hooks/useResponsive";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import { fCurrency, formatBigIntToReadable } from "utils/format";
import { useChainId } from "wagmi";
import Address from "web3/Address";
const ReactGridLayout = WidthProvider(Responsive);

export const LeaderBoard = () => {
  const theme = useTheme();
  const isMobile = useResponsive("down", "sm");
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const { callApi } = useAxios();
  const chainId = useChainId();
  const { data: topTraders, isLoading } = useQuery({
    enabled: !!chainId,
    queryKey: [],
    queryFn: async () => {
      const res = callApi<any, any>("get", "trading/top-traders", { k: 10 });
      return res;
    },
  });

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, [window.innerWidth]);

  const layout = {
    lg: [
      { i: "top-volume", x: 0, y: 0, w: 12, h: 34, static: true },
      { i: "top-profit", x: 12, y: 0, w: 12, h: 34, static: true },
    ],
    md: [
      { i: "top-volume", x: 0, y: 0, w: 24, h: 25, static: true },
      { i: "top-profit", x: 0, y: 25, w: 24, h: 25, static: true },
    ],
    sm: [
      { i: "top-volume", x: 0, y: 0, w: 24, h: 20, static: true },
      { i: "top-profit", x: 0, y: 20, w: 24, h: 20, static: true },
    ],
  };

  const defaultHeaderVolume: ColumFormat[] = [
    {
      id: "rank",
      label: "Rank",
      render: (row: any) => (
        <Typography lineHeight={"40px"}>{row.rank}</Typography>
      ),
    },
    {
      id: "user",
      label: "User",
      render: (row: any) => (
        <Stack direction={"row"} alignItems={"center"}>
          <Address size={isMobile ? 7 : 15} address={row.trader} />
        </Stack>
      ),
    },
    {
      id: "volume",
      label: "Volume (USDC)",
      align: "right",
      render: (row: any) => (
        <Typography>
          {" "}
          {fCurrency(new Decimal(row.totalCaluationClosed).toNumber(), 4)}
        </Typography>
      ),
    },
  ];
  const defaultHeaderProfit: ColumFormat[] = [
    {
      id: "profit-rank",
      label: "Rank",
      render: (row: any) => (
        <Typography lineHeight={"40px"}>{row.rank}</Typography>
      ),
    },
    {
      id: "profit-user",
      label: "User",
      render: (row: any) => (
        <Stack direction={"row"} alignItems={"center"}>
          <Address size={isMobile ? 7 : 15} address={row.trader} />
        </Stack>
      ),
    },
    {
      id: "profit-profit",
      label: "Profit (USDC)",
      align: "right",
      render: (row: any) => (
        <Typography>
          {fCurrency(new Decimal(row.totalPNL).toNumber(), 4)}
        </Typography>
      ),
    },
  ];

  return (
    <GridContainer>
      <ReactGridLayout
        className="Grid-99218"
        layouts={layout}
        cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
        rowHeight={20}
        width={innerWidth}
        containerPadding={[0, 2]}
        margin={[2, 2]}
      >
        <GridItem key="top-volume" p={{ xs: 0, md: 4 }}>
          <Typography
            variant="h5"
            px={{ xs: 2, md: 0 }}
            mt={{ xs: 1, md: 0 }}
            mb={{ xs: 1, md: 3 }}
          >
            {t("Top 10 volume")}
          </Typography>
          <Divider />
          <CustomTable
            maxHeight={1000}
            header={defaultHeaderVolume}
            data={topTraders?.volumeIndex || []}
            rowBg={alpha(theme.palette.action.hover, 0.03)}
          />
        </GridItem>
        <GridItem key="top-profit" p={{ xs: 0, md: 4 }}>
          <Typography
            variant="h5"
            px={{ xs: 2, md: 0 }}
            mt={{ xs: 1, md: 0 }}
            mb={{ xs: 1, md: 3 }}
          >
            {t("Top 10 profit")}
          </Typography>
          <Divider />
          <CustomTable
            maxHeight={1000}
            header={defaultHeaderProfit || []}
            data={topTraders?.pnlIndex}
            rowBg={alpha(theme.palette.action.hover, 0.03)}
          />
        </GridItem>
      </ReactGridLayout>
    </GridContainer>
  );
};

const GridContainer = styled(Stack)(({ theme }) => ({
  "& .Grid-99218": {
    background: theme.palette.background.paper,
  },
}));

const GridItem = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  overflow: "hidden",
}));
