import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box, FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  styled,
  useTheme
} from "@mui/material";
import AppSelect from "components/AppSelect";
import Loader from "components/Loader";
import { SettingsContext } from "contexts/settingsContext";
import { UserContext } from "contexts/userContext";
import Decimal from "decimal.js";
import { Form, FormikProvider, useFormik } from "formik";
import useAxios from "hooks/useAxios";
import { t } from "i18next";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import {
  fCurrency
} from "utils/format";
import {
  getWithdrawSignatureData, WithdrawData
} from "utils/signature";
import { delay, getTradeMods, TradeMods } from "utils/utils";
import {
  useAccount, useChainId, usePublicClient,
  useWalletClient
} from "wagmi";
import * as Yup from "yup";
import PairSelector from "./trading/PairSelector";
import { TradeSettingsContext } from "contexts/tradeSettingsContext";
import Iconify from "components/Iconify";
import { MaxUint256 } from "utils/constants";
import usePairInfo from "hooks/usePairInfo";
import { WithdrawRequest, WithdrawResponse } from "types/withdraw";
import GradientButton from "components/buttons/GradientButton";
import { Pair } from "types/tradePairs";

// ------------------------------------------------------------------

type WithdrawProps = {
  children?: ReactNode;
  account?: string;
  background?: string;
  variant?: "permanent" | "persistent" | "temporary";
  anchor?: "left" | "right";
};

const Withdraw: FC<WithdrawProps> = ({
  children,
  background,
  variant,
  anchor,
}) => {
  const theme = useTheme();
  const { isConnected, address } = useAccount();
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    isConnected && (
      <Stack>
        <Box onClick={() => handleOpenModal()}>{children}</Box>
        <LayoutDrawer
          open={open}
          onClose={() => handleCloseModal()}
          anchor={anchor || "right"}
          variant={variant || "persistent"}
          paperSx={{
            border: "none",
            width: "100%",
            maxWidth: 500,
            background: background || theme.palette.background.default,
            position: "absolute",
          }}
          title={t("Withdraw") || ""}
        >
          <WithdrawForm onClose={handleCloseModal} address={`0x${address?.replace('0x', '')}`} />
        </LayoutDrawer>
      </Stack>
    )
  );
};

export default Withdraw;

export const WithdrawForm = ({ onClose, address }: { onClose?: () => void, address: `0x${string}` }) => {
  const theme = useTheme();
  const chainId = useChainId();
  const { enqueueSnackbar } = useSnackbar();
  const { userSections, saveUserSections } = useContext(UserContext);
  const { tradeSettings, saveTradeSettings } = useContext(TradeSettingsContext);
  const { settings } = useContext(SettingsContext);
  const { callApi } = useAxios();
  const { data: walletClient } = useWalletClient({ account: address });
  const { currentTradeMode } = tradeSettings;
  const { liveTransactions } = userSections;
  const [submitting, setSubmitting] = useState(false);
  const [confirmStep, setConfirmStep] = useState(0);
  const publicClient = usePublicClient();
  const { currentPair } = settings;
  const [pairSelected, setPairSelected] =
    useState<typeof currentPair>(currentPair);
  const { pairInfo } = usePairInfo(pairSelected as Pair ??  null);
  console.log("pairInfo",pairInfo)

  const [marginModeSelected, setMarginModeSelected] = useState<{
    key: "CROSS" | "ISOLATED";
    title: string;
  }>({
    key: currentTradeMode ?? "ISOLATED",
    title: currentTradeMode === "ISOLATED" ? "Isolated" : "Cross",
  });
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const Schema = Yup.object().shape({
    amount: Yup.number()
      .required(`${t("Please input amount as a number.")}`)
      .min(1)
      .max(parseFloat(pairInfo?.formattedAvailable || "0")),
  });

  const handleChangeTradeMode = (value: TradeMods) => {
    setMarginModeSelected(value);
    saveTradeSettings({ ...tradeSettings, currentTradeMode: value.key });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Schema,
    validateOnBlur: false,
    onSubmit: async () => {
      if (
        !walletClient ||
        !publicClient ||
        !pairSelected ||
        !marginModeSelected ||
        !address
      )
        return;
      setSubmitting(true);
      try {
        const nonce = BigInt(Date.now());
        const deadline = (BigInt(Date.now()) + 10000000000n) / 1000n;
        const amount = new Decimal(formik.values.amount).gte(
          pairInfo?.formattedAvailable ?? 0
        )
          ? MaxUint256
          : BigInt(
            new Decimal(formik.values.amount)
              .mul(new Decimal(10).pow(pairSelected?.quoteTokenDecimal || 0))
              .toHex()
          );
        //Start confirm

        handleConfirm();
        setConfirmStep(0);
        await delay(1000);

        setConfirmStep(1);

        const withdraw: WithdrawData = {
          pairId:
            marginModeSelected.key === "ISOLATED" ? pairSelected.id : undefined,
          trader: address,
          amountToWithdraw: amount,
          nonce,
          deadline,
        };

        const withdrawData = await getWithdrawSignatureData(
          chainId,
          withdraw,
          marginModeSelected.key
        );

        const signature = await walletClient.signTypedData(withdrawData);

        await delay(1000);

        setConfirmStep(2);

        const response = await callApi<WithdrawRequest, WithdrawResponse>(
          "post",
          "trading/withdraw",
          {
            pairId:
              marginModeSelected.key === "ISOLATED" ? currentPair?.id ?? 0 : 0,
            marginType: marginModeSelected?.key ?? "ISOLATED",
            nonce: nonce.toString(),
            amount: amount.toString(),
            deadline: deadline.toString(),
            chainId: chainId,
            signature: signature,
          }
        );

        setSubmitting(false);

        enqueueSnackbar("The transaction has been created. Please wait!", {
          variant: "success",
          autoHideDuration: 1000,
        });

        saveUserSections({
          ...userSections,
          liveTransactions: liveTransactions?.concat({
            id: response.id,
            type: "WITHDRAW",
            status: "PENDING",
            amount: `${formik.values.amount}`,
            token: currentPair?.quoteToken ?? "USDC",
          }),
        });

        handleRejectConfirm();

        await delay(500);
        if (onClose)
          onClose();
      } catch (e) {
        console.log("e", e);
        setSubmitting(false);
        setTimeout(() => {
          handleRejectConfirm();
        }, 500);
      }
    },
  });



  const handleConfirm = () => {
    setShowConfirm(true);
  };

  const handleRejectConfirm = () => {
    setShowConfirm(false);
    setConfirmStep(0);
  };

  const { handleSubmit, getFieldProps, errors, handleReset } = formik;

  useEffect(() => {
    handleReset({ amount: "" });
  }, [handleReset]);

  const tradeModes = getTradeMods(currentPair?.marginType).filter(
    (mode) => mode.disabled === false
  );

  const withdrawInfo = {
    asset: pairSelected?.quoteToken || currentPair?.quoteToken,
    amount: formik.values.amount,
    margin: marginModeSelected?.key,
  };

  return <>
    <Stack
      flexGrow={1}
      alignItems={"center"}
      p={onClose ? 2 : 0}
      spacing={5}
      sx={{ position: "relative" }}
    >
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Stack minWidth={"100%"} spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel sx={{ mb: 0.5 }}>{t("Margin mode")}</FormLabel>
                <AppSelect
                  options={tradeModes}
                  selectedValue={marginModeSelected}
                  onSelect={(value: any) => handleChangeTradeMode(value)}
                  btnSx={{
                    background: theme.palette.background.button,
                    height: onClose ? 45 : 50,
                    borderRadius: "3px",
                    fontSize: "0.95rem",
                    justifyContent: "space-between",
                    color: theme.palette.text.primary,
                    "&:hover": {
                      background: theme.palette.background.button,
                    },
                  }}
                />
              </FormControl>
              {marginModeSelected?.key === "ISOLATED" && (
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel sx={{ mb: 0.5 }}>
                    {t("Select pair")}
                  </FormLabel>
                  <PairSelector
                    miniMode
                    btnSx={{
                      background: theme.palette.background.button,
                      height: onClose ? 45 : 50,
                      borderRadius: "3px",
                      fontSize: "0.9rem",
                      justifyContent: "space-between",
                      color: theme.palette.text.primary,
                      "&:hover": {
                        background: theme.palette.background.button,
                      },
                    }}
                    onChange={(pair) => setPairSelected(pair)}
                    variant="persistent"
                  />
                </FormControl>
              )}
            </Stack>
            <Stack>
              <FormControl>
                <FormLabel sx={{ mb: 0.5 }}>{t("Amount")}</FormLabel>
                <CustomInput
                  sx={{ height: onClose ? 45 : 50 }}
                  inputProps={{ sx: { padding: "0px 1rem" } }}
                  placeholder={`${t("Minimum")}: 10.00000`}
                  endAdornment={
                    <Typography color={theme.palette.text.disabled}>
                      {currentPair?.quoteToken}
                    </Typography>
                  }
                  type="number"
                  {...getFieldProps("amount")}
                  error={Boolean(errors.amount)}
                />
                <FormHelperText error>{errors.amount}</FormHelperText>
              </FormControl>
              <Stack direction={"row"} mt={1} spacing={0.5}>
                <Typography
                  sx={{
                    color: theme.palette.text.disabled,
                    fontWeight: 600,
                  }}
                >
                  {t("Available")}:
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.text.secondary,
                    fontWeight: 600,
                  }}
                >
                  {pairInfo?.formattedAvailable}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    cursor: "pointer",
                    marginLeft: "2rem !important",
                  }}
                  onClick={() =>
                    formik.setValues({
                      ...formik.values,
                      amount: pairInfo?.formattedAvailable || "",
                    })
                  }
                >
                  Max
                </Typography>
              </Stack>
            </Stack>
            <GradientButton disabled={submitting} sx={{ height: onClose ? 45 : 50, fontSize: onClose ? '' : '1rem', textTransform: onClose ? '' : 'uppercase' }} type="submit">
              {t("Withdraw")}
            </GradientButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Loader open={submitting} />
    </Stack>
    <ConfirmWithdraw
      activeStep={confirmStep}
      open={showConfirm}
      info={withdrawInfo}
    />
  </>
}

type ConfirmProps = {
  open: boolean;
  background?: string;
  info?: {
    asset: string | undefined;
    amount: number | string | undefined;
    fee?: number | undefined;
    margin?: string;
  };
  activeStep: number;
};

const ConfirmWithdraw: FC<ConfirmProps> = ({
  open,
  background,
  info,
  activeStep,
}) => {
  const theme = useTheme();

  const steps = [
    {
      label: "Sign message",
      description: t("Please confirm on your wallet"),
    },
    {
      label: "Withdraw progress",
      description: t("Please wait"),
    },
  ];
  return (
    <LayoutDrawer
      open={open}
      anchor={"right"}
      variant={"persistent"}
      paperSx={{
        border: "none",
        width: "100%",
        maxWidth: 500,
        background: background || theme.palette.background.default,
        position: "absolute",
      }}
    >
      <Stack alignItems={"center"} p={2}>
        <Typography variant="h6" color={"text.secondary"}>
          {t("Confirm withdraw")}
        </Typography>
        <Stack
          mt={3}
          alignItems={"center"}
          p={3}
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            width: "100%",
            borderRadius: "0.7rem",
          }}
        >
          <Typography variant="h6">
            {t("Withdraw")} {fCurrency(info?.amount || 0, 5)} {info?.asset}
          </Typography>
          <Stack
            mt={2}
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography color={"text.disabled"}>{t("Margin")}</Typography>
            <Typography>{info?.margin}</Typography>
          </Stack>
          <Stack
            mt={2}
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography color={"text.disabled"}>{t("Est. Fee")}</Typography>
            <Typography>--</Typography>
          </Stack>
        </Stack>
        <StyledStepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepIcon}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography fontWeight={600} className="title" pl={1}>
                    {step.label}
                  </Typography>
                  <Iconify
                    className="completed"
                    icon={"line-md:confirm"}
                    sx={{ color: theme.palette.success.main }}
                  />
                </Stack>
              </StepLabel>
              <StepContent>
                <Typography variant="body2" color={"text.secondary"}>
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </StyledStepper>
      </Stack>
    </LayoutDrawer>
  );
};

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const theme = useTheme();

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <Iconify
        icon="material-symbols-light:order-approve-rounded"
        size={20}
        sx={{ color: `text.${active ? "primary" : "secondary"}` }}
      />
    ),
    2: (
      <Iconify
        icon="mdi:sign"
        size={20}
        sx={{ color: `text.${active ? "primary" : "secondary"}` }}
      />
    ),
    3: (
      <Iconify
        icon="fa6-solid:download"
        size={15}
        sx={{ color: `text.${active ? "primary" : "secondary"}` }}
      />
    ),
  };

  return (
    <StyledStepIcon className={active ? "active" : ""}>
      {icons[String(props.icon)]}
    </StyledStepIcon>
  );
}

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
  height: 45,
  background: theme.palette.background.input,
  borderRadius: "3px",
  overflow: "hidden",
  fontWeight: 500,
  border: "1px solid",
  borderColor: "transparent",
  "&:hover, &.Mui-focused": {
    borderColor: theme.palette.primary.main,
  },
  "&.Mui-disabled": {
    borderColor: "transparent",
  },
  "& fieldset": {
    border: "none",
  },
  "& .iconify": {
    width: 20,
    height: 20,
  },
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  width: "100%",
  padding: "2rem 1rem",
  "& .MuiStepConnector-root, & .MuiStepContent-root": {
    marginLeft: "19px",
    borderLeft: `2px solid ${theme.palette.divider}`,
    paddingLeft: "2.2rem",
    "& .MuiStepConnector-line": {
      border: "none",
    },
  },
  "& .MuiStepContent-last ": {
    borderLeft: "none !important",
  },
  "& .iconify.completed": {
    opacity: 0,
  },
  "& .Mui-completed": {
    "& .title": {
      opacity: 0.4,
    },
    "& .iconify.completed": {
      opacity: 1,
    },
  },
}));

const StyledStepIcon = styled(Stack)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 40,
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.action.hover,
  position: "relative",
  "&.active": {
    background: theme.palette.primary.dark,
    color: "#fff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 0.6s infinite ease-in-out",
      border: `2px solid ${theme.palette.primary.main}`,
      content: '""',
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.9)",
        opacity: 0.4,
      },
      "100%": {
        transform: "scale(1.4)",
        opacity: 0,
      },
    },
  },
}));
