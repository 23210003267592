import { AppBar, Button, Hidden, Stack, styled, useTheme } from "@mui/material"
import { navigation } from "./navigation";
import { useNavigate } from "react-router-dom";

export const MobileMenu = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    return <>
        <AppBar position="fixed" sx={{ bottom: 0, top: 'auto', background: theme.palette.background.default }}>
        </AppBar>
    </>
}

const MenuItem = styled(Button)(({ theme }) => ({
    fontWeight: 600,
    fontSize: "0.85rem",
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    "&:hover": {
        color: theme.palette.warning.main,
    },
}));