import {
  alpha,
  Box,
  Divider,
  Drawer,
  DrawerProps,
  Hidden,
  IconButton,
  Stack,
  styled,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import Iconify from "components/Iconify";
import { FC, Fragment, ReactNode } from "react";

// ------------------------------------------------------------------
type LayoutDrawerProps = {
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  drawerWidth?: number;
  children: ReactNode;
  variant?: "permanent" | "persistent" | "temporary" | undefined;
  anchor?: "bottom" | "left" | "right" | "top" | undefined;
  paperSx?: DrawerProps["sx"];
  title?: string;
  sx?: DrawerProps["sx"];
};
// ------------------------------------------------------------------
const SwipeablePaperStyle: any = {
  borderRadius: "1rem 1rem 0px 0px",
  paddingTop: 3,
};
const Puller = styled("div")(({ theme }) => ({
  width: 40,
  height: 6,
  backgroundColor: theme.palette.background.default,
  borderRadius: 3,
  position: "absolute",
  top: 13,
  left: "calc(50% - 20px)",
}));

const LayoutDrawer: FC<LayoutDrawerProps> = (props) => {
  const theme = useTheme();
  const {
    children,
    open,
    onClose,
    drawerWidth = 300,
    onOpen,
    anchor,
    variant,
    paperSx,
    title,
    sx,
  } = props;

  return (
    <Fragment>
      <Hidden mdDown>
        <Drawer
          anchor={anchor}
          open={open}
          onClose={onClose}
          sx={{ margin: "0px !important", ...sx }}
          PaperProps={{ sx: { ...paperSx, overflowX: "hidden" } }}
          variant={variant}
        >
          {title && (
            <Stack
              direction={"row"}
              p={0.5}
              spacing={2}
              height={45}
              alignItems={"center"}
              sx={{
                position: "relative",
                borderBottom: `1px solid ${alpha(theme.palette.divider, 0.05)}`,
              }}
            >
              {anchor === "right" && (
                <IconButton
                  sx={{ padding: 0.3 }}
                  onClick={() => onClose && onClose()}
                >
                  <Iconify
                    icon={"iconamoon:arrow-left-2-light"}
                    sx={{
                      width: 30,
                      height: 30,
                      color: theme.palette.text.disabled,
                    }}
                  />
                </IconButton>
              )}
              <Typography
                flexGrow={1}
                sx={{
                  fontWeight: 600,
                  color: theme.palette.text.secondary,
                  fontSize: "0.9rem",
                  paddingLeft: anchor === "left" ? "0.8rem" : "0px",
                }}
              >
                {title}
              </Typography>
              {anchor === "left" && (
                <IconButton
                  sx={{ padding: 0.3 }}
                  onClick={() => onClose && onClose()}
                >
                  <Iconify
                    icon={"iconamoon:arrow-right-2-light"}
                    sx={{
                      width: 30,
                      height: 30,
                      color: theme.palette.text.disabled,
                    }}
                  />
                </IconButton>
              )}
            </Stack>
          )}
          {children}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          container={document.body}
          anchor="bottom"
          open={Boolean(open)}
          onClose={onClose ? onClose : () => {}}
          onOpen={onOpen ? onOpen : () => {}}
          disableSwipeToOpen={false}
          PaperProps={{
            sx: SwipeablePaperStyle,
          }}
          // ModalProps={{
          //   keepMounted: false,
          // }}
        >
          <Puller />
          {title && (
            <Stack
              direction={"row"}
              p={0.5}
              height={45}
              alignItems={"center"}
              sx={{ position: "relative" }}
            >
              <Typography
                flexGrow={1}
                sx={{ fontWeight: 600, color: theme.palette.text.disabled }}
                textAlign={"center"}
              >
                {title}
              </Typography>
            </Stack>
          )}
          {children}
        </SwipeableDrawer>
      </Hidden>
    </Fragment>
  );
};

export default LayoutDrawer;
