import {
  ButtonBase,
  styled,
  Typography,
  Stack,
  useTheme,
  Grid,
} from "@mui/material";
import { FC } from "react";
import { t } from "i18next";
import Iconify from "components/Iconify";
import Deposit from "sections/Deposit";
import Withdraw from "./Withdraw";

type InitProps = {
  anchor?: "left" | "right";
};

const StyledActionButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  padding: "0px",
  borderRadius: 10,
  height: 50,
  width: 50,
  "&:hover": { backgroundColor: theme.palette.action.active },
  "& .iconify": {
    width: 20,
    height: 20,
    marginBottom: "0.3rem",
    color: theme.palette.text.secondary,
  },
}));
const UserFunction: FC<InitProps> = ({ anchor }) => {
  const theme = useTheme();
  return (
    <Grid container columns={16} py={4} px={2}>
      <Grid item xs={4}>
        <Withdraw
          anchor={anchor}
          variant="temporary"
          background={theme.palette.background.paper}
        >
          <Stack spacing={1} alignItems={"center"}>
            <StyledActionButton>
              <Iconify icon={"fa6-solid:upload"} />
            </StyledActionButton>
            <Typography
              variant="body2"
              fontWeight={500}
              color={theme.palette.text.disabled}
            >
              {t("Withdraw")}
            </Typography>
          </Stack>
        </Withdraw>
      </Grid>
      <Grid item xs={4}>
        <Deposit
          anchor={anchor}
          variant="temporary"
          background={theme.palette.background.paper}
        >
          <Stack spacing={1} alignItems={"center"}>
            <StyledActionButton>
              <Iconify icon={"fa6-solid:download"} />
            </StyledActionButton>
            <Typography
              variant="body2"
              fontWeight={500}
              color={theme.palette.text.disabled}
            >
              {t("Deposit")}
            </Typography>
          </Stack>
        </Deposit>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1} sx={{ opacity: 0.3 }} alignItems={"center"}>
          <StyledActionButton disabled>
            <Iconify icon={"fa6-solid:repeat"} />
          </StyledActionButton>
          <Typography
            variant="body2"
            fontWeight={500}
            color={theme.palette.text.disabled}
          >
            {t("Swap")}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1} sx={{ opacity: 0.3 }} alignItems={"center"}>
          <StyledActionButton disabled>
            <Iconify icon={"fa6-solid:ellipsis"} />
          </StyledActionButton>
          <Typography
            variant="body2"
            fontWeight={500}
            color={theme.palette.text.disabled}
          >
            {t("More")}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UserFunction;
