import {
  BadgeProps,
  Button,
  ButtonBase,
  ButtonProps,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Iconify from "components/Iconify";
import TabPanel from "components/TabPanel";
import { t } from "i18next";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import { FC, ReactNode, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useAccount } from "wagmi";
import { TransactionStatus, FundingFeeHistory } from "web3/Types";
import FlexBetween from "components/flexbox/FlexBetween";
import moment from "moment";
import { fCurrency } from "utils/format";

// ------------------------------------------------------------------

type HistoryProps = {
  children?: ReactNode;
  background?: string;
  btnSx?: ButtonProps["sx"];
};

// const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     right: 3,
//     top: 5,
//     padding: "0 4px",
//   },
// }));

const History: FC<HistoryProps> = ({ children, btnSx, background }) => {
  // const { userSections, saveUserSections } = useContext(UserContext);
  // const { liveTransactions } = userSections;
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { isConnected } = useAccount();
  const theme = useTheme();
  // const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  // const { on, off } = useSocket();
  // const queryClient = useQueryClient();

  return (
    isConnected && (
      <Stack>
        <Button
          onClick={() => handleOpenModal()}
          sx={{
            ...btnSx,
            pr: 1,
            "&:hover": { color: theme.palette.warning.main },
          }}
        >
          <Iconify
            icon={"mdi:clipboard-text-history-outline"}
            size={18}
            sx={{ marginRight: "5px" }}
          />
          {t("History")}
        </Button>
        <LayoutDrawer
          open={open}
          onClose={() => handleCloseModal()}
          anchor="right"
          variant="temporary"
          paperSx={{
            border: "none",
            width: "400px",
            background: background || theme.palette.background.default,
            position: "fixed",
          }}
          title={`${t("History")}`}
        >
          <Stack direction={"row"} py={2} px={1} spacing={1}>
            <WalletButton
              onClick={() => setTabIndex(0)}
              className={tabIndex === 0 ? "active" : ""}
            >
              {t("Funding Fee")}
            </WalletButton>
          </Stack>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={Number(tabIndex)}
          >
            <TabPanel
              value={tabIndex}
              sx={{ padding: 0 }}
              index={0}
              dir={theme.direction}
            >
              <TransactionList
                data={[
                  {
                    id: "1",
                    base: "BTC",
                    quote: "USDT",
                    date: "2024-07-10 15:00:03",
                    fee: "5.0291",
                  },
                  {
                    id: "2",
                    base: "BTC",
                    quote: "USDT",
                    date: "2024-07-10 15:00:03",
                    fee: "5.0291",
                  },
                  {
                    id: "3",
                    base: "BTC",
                    quote: "USDT",
                    date: "2024-07-10 15:00:03",
                    fee: "5.0291",
                  },
                  {
                    id: "4",
                    base: "BTC",
                    quote: "USDT",
                    date: "2024-07-10 15:00:03",
                    fee: "5.0291",
                  },
                  {
                    id: "5",
                    base: "BTC",
                    quote: "USDT",
                    date: "2024-07-10 15:00:03",
                    fee: "5.0291",
                  },
                ]}
                status={["SUCCESS"]}
              />
            </TabPanel>
          </SwipeableViews>
        </LayoutDrawer>
      </Stack>
    )
  );
};

export default History;

type TransactionListType = {
  data: FundingFeeHistory[];
  status: TransactionStatus[];
};

const TransactionList: FC<TransactionListType> = ({ ...props }) => {
  const theme = useTheme();
  const { data = [] } = props;
  const filteredList = data;
  return (
    <Stack divider={<Divider />} pb={3} maxHeight={"calc(100vh - 70px)"}>
      {filteredList.length > 0 ? (
        filteredList.map((item) => <TransactionItem {...item} key={item.id} />)
      ) : (
        <Stack flexGrow={1} alignItems={"center"} justifyContent={"center"}>
          <Typography color={theme.palette.text.disabled}>
            {t("Not found")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

const TransactionItem: FC<FundingFeeHistory> = ({ ...props }) => {
  const { base, fee, date, quote } = props;

  return (
    <TransactionItemStyled spacing={2}>
      <FlexBetween width={"100%"}>
        <Typography>{quote}</Typography>
        <Typography color={"text.secondary"}>
          {moment(date).format("YYYY-MM-DD hh:mm")}
        </Typography>
      </FlexBetween>
      <Stack mt={2} spacing={1}>
        <FlexBetween width={"100%"}>
          <Typography color={"text.secondary"}>Symbol</Typography>
          <Typography>{`${base}${quote}`}</Typography>
        </FlexBetween>
        <FlexBetween width={"100%"}>
          <Typography color={"text.secondary"}>Fee</Typography>
          <Typography>{fCurrency(fee, 2)}</Typography>
        </FlexBetween>
      </Stack>
    </TransactionItemStyled>
  );
};

const TransactionItemStyled = styled(Stack)<BadgeProps>(({ theme }) => ({
  cursor: "pointer",
  padding: "0.5rem 1rem",
  width: "100%",
  "&:hover": {
    background: theme.palette.action.active,
  },
  "& .iconify": {
    width: 30,
    height: 30,
    "&.receive": {
      color: theme.palette.success.main,
    },
  },
  "& .MuiCardHeader-root": {
    padding: 0,
    "& .MuiCardHeader-title": {
      fontSize: "0.8rem",
      fontWeight: 500,
      color: theme.palette.text.disabled,
    },
    "& .MuiCardHeader-subheader": {
      fontWeight: 500,
    },
  },
}));

const WalletButton = styled(ButtonBase)(({ theme }) => ({
  height: 25,
  padding: "0px 0.5rem",
  fontWeight: 600,
  borderRadius: 2,
  color: theme.palette.text.disabled,
  "&:hover": {
    backgroundColor: theme.palette.action.disabled,
    color: theme.palette.text.secondary,
  },
  "&.active": {
    color:
      theme.palette.mode === "light"
        ? theme.palette.text.primary
        : theme.palette.warning.main,
    backgroundColor: theme.palette.action.active,
  },
}));
