import {
  Backdrop,
  BackdropProps,
  CircularProgress,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import { ReactNode, CSSProperties } from "react";
import { Props } from "simplebar-react";

// props type
type LoaderProps = {
  sx?: BackdropProps["sx"];
  children?: ReactNode;
  style?: CSSProperties;
  open: boolean;
};

const LoaderStyled = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[200],
  width: 45,
  height: 45,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "7px",
  transform: "rotate(45deg)",
  boxShadow: "0px 0px 3px #000",
  color: theme.palette.text.secondary,
}));
const IconStyle = styled(CircularProgress)(({ theme }) => ({
  maxWidth: 20,
  maxHeight: 20,
  color: theme.palette.text.secondary,
}));

const Loader = ({
  children,
  style,
  sx,
  open,
  ...props
}: LoaderProps & Props) => {
  const theme = useTheme();
  return (
    <Backdrop open={open} sx={{ position: "absolute", ...sx }}>
      <Stack spacing={2}>
        <LoaderStyled>
          <IconStyle thickness={5} />
        </LoaderStyled>
      </Stack>
    </Backdrop>
  );
};

export default Loader;
