import { Pair_GetRes } from "types/tradePairs";
import { FETCH_OPEN_ORDER, UPDATE_BALANCE, UPDATE_PNL_ANALYSIS } from "./reducers/UserReducer";
import { FETCH_MARKET } from "./reducers/TradeReducer";

// User actions -----------------------------------------------------
export const user_UpdateBalance = (result: any) => ({
    type: UPDATE_BALANCE,
    payload: result
})

export const user_FetchPnlAnalysis = (result: any) => ({
    type: UPDATE_PNL_ANALYSIS,
    payload: result
})

export const user_FetchOpenOrder = (result: any) => ({
    type: FETCH_OPEN_ORDER,
    payload: result
})

// Trade actions -----------------------------------------------------

export const trade_FetchMarkets = (result: Pair_GetRes[]) => ({
    type: FETCH_MARKET,
    payload: result
})
