import useLocalStorage from "hooks/useLocalStorage";
import { createContext, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { user_FetchPnlAnalysis } from "../redux/actions";
import { SiweMessage } from "siwe";
import { userContextTypes } from "theme";
import { PnlAnalysis_GetReq, PnlAnalysis_GetRes } from "types/user";
import useAxios from "hooks/useAxios";
import { queryStringToJson } from "utils/utils";

export const PriceModes = [
  {
    key: "Market",
    title: "Market",
  },
  {
    key: "Limit",
    title: "Limit",
  },
];

const initialSections: userContextTypes = {
  liveTransactions: [],
  accessToken: null,
  refreshToken: null,
  address: null,
};

export const UserContext = createContext({
  userSections: initialSections,
  isLoggedIn: false || true,
  saveUserSections: (arg: userContextTypes) => { },
  logout: () => { },
  login: (
    message: SiweMessage,
    signature: string,
    address?: string,
    successCallback?: () => void
  ) => Promise.resolve(),
  getPnlAnalysis: (arg: PnlAnalysis_GetReq) => { },
});

// component props type
type SettingsProviderProps = {
  children: ReactNode;
};

const UseSectionsProvider = ({ children }: SettingsProviderProps) => {
  const dispatch = useDispatch();
  const searchParams = queryStringToJson(window.location.search)
  const localTemp = JSON.parse(window.localStorage.getItem('userSections') || '{}')
  const { callApi } = useAxios(localTemp?.accessToken);

  const { data: userSections, storeData: setStoreSettings } = useLocalStorage(
    "userSections",
    initialSections
  );

  const saveUserSections = (updateSettings: userContextTypes) => {
    setStoreSettings(updateSettings);
  };

  const logout = () => {
    saveUserSections({
      ...userSections,
      accessToken: null,
      refreshToken: null,
      address: null,
    });
  };

  const login = async (
    message: SiweMessage,
    signature: string,
    address?: string,
    successCallback?: () => void
  ) => {
    const params = {
      signature,
      message,
      refCode: searchParams?.refCode
    };
    const response = await callApi<any, any>(
      `post`,
      `auth/siwe/verify`,
      params
    );
    if (response && response?.tokens) {
      if (successCallback) successCallback();
      saveUserSections({
        ...userSections,
        accessToken: response.tokens.accessToken,
        refreshToken: response.tokens.refreshToken,
        address: address,
      });
    }
  };

  const getPnlAnalysis = async (params: PnlAnalysis_GetReq) => {
    if (userSections?.accessToken) {
      const response = await callApi<PnlAnalysis_GetReq, PnlAnalysis_GetRes>(
        "get",
        "dashboard/get-pnl-analysis",
        params
      );
      dispatch(user_FetchPnlAnalysis(response));
    }
  };

  return (
    <UserContext.Provider
      value={{
        userSections,
        saveUserSections,
        login,
        logout,
        isLoggedIn: Boolean(userSections.accessToken),
        getPnlAnalysis,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UseSectionsProvider;
