import {
  Box,
  ButtonBase,
  ButtonProps,
  Divider,
  OutlinedInput,
  Stack,
  StackProps,
  Typography,
  styled,
} from "@mui/material";
import Iconify from "components/Iconify";
import Scrollbar from "components/ScrollBar";
import { SettingsContext } from "contexts/settingsContext";
import { t } from "i18next";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import FavoriteButton from "./FavoriteButton";
import { useSelector } from "react-redux";
import { Pair, Pair_GetRes } from "types/tradePairs";
import useTrade from "hooks/useTrade";
import { RootState } from "redux/reducers";
import { TradeSettingsContext } from "contexts/tradeSettingsContext";
import { getTradeMods } from "utils/utils";
import PopoverBase from "layouts/layout-parts/popovers/PopoverBase";

// ------------------------------------------------------------------

type PairSelectorProps = {
  miniMode?: boolean;
  onChange?: (pair: Pair_GetRes | Pair) => void;
  btnSx?: ButtonProps["sx"];
  variant?: "permanent" | "persistent" | "temporary" | undefined;
};

const PairSelector: FC<PairSelectorProps> = ({
  miniMode,
  onChange,
  btnSx,
  variant,
}) => {
  const { tradeSettings, pairs } = useTrade();
  const anchorRef = useRef(null);
  const { markets } = useSelector((state: RootState) => state.trade);
  const { settings, saveSettings } = useContext(SettingsContext);
  const { saveTradeSettings } = useContext(TradeSettingsContext);
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState("");
  const { currentPair } = settings;

  const [pairSelected, setPairSelected] = useState(currentPair);

  const handleSelectPair = (pair: Pair) => {
    const tradeMods = getTradeMods(pair?.marginType).filter(
      (mode) => !mode.disabled
    );
    const { currentTradeMode } = tradeSettings;
    const found =
      tradeMods.find((mode) => mode.key === currentTradeMode) || tradeMods[0];
    if (onChange && miniMode) {
      onChange(pair);
    } else {
      saveTradeSettings({ ...tradeSettings, currentTradeMode: found?.key });
      saveSettings({ ...settings, currentPair: pair });
    }
    setPairSelected(pair);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (pairs && pairs[0] && !currentPair) {
      handleSelectPair(pairs[0]);
    }
  }, [pairs]);

  const FilteredList = markets.filter((item) =>
    activeIndex
      ? item.marginType.toLocaleLowerCase() === activeIndex.toLocaleLowerCase()
      : item
  );

  return (
    <Fragment>
      <StyledButtonBase ref={anchorRef} sx={{ ...btnSx }} onClick={handleOpen}>
        {miniMode ? pairSelected?.label : pairSelected?.name}
        <Iconify
          icon="iconamoon:arrow-down-2-light"
          sx={{ width: 25, height: 25, marginLeft: "0.5rem", opacity: 0.6 }}
        />
      </StyledButtonBase>
      {miniMode ? (
        <PopoverBase anchorRef={anchorRef} open={open} onClose={handleClose}>
          <List
            contentSx={{ minWidth: 150 }}
            FilteredList={FilteredList}
            handleSelectPair={(pair) => handleSelectPair(pair)}
            miniMode
          />
        </PopoverBase>
      ) : (
        <LayoutDrawer open={open} onClose={handleClose}>
          <List
            contentSx={{ minWidth: 350 }}
            FilteredList={FilteredList}
            handleSelectPair={(pair) => handleSelectPair(pair)}
          />
        </LayoutDrawer>
      )}
    </Fragment>
  );
};

export default PairSelector;

const List = ({
  FilteredList,
  handleSelectPair,
  contentSx,
  miniMode,
}: {
  FilteredList: Pair_GetRes[];
  handleSelectPair: (pair: Pair) => void;
  contentSx?: StackProps["sx"];
  miniMode?: boolean;
}) => {
  return (
    <Stack sx={{ ...contentSx }}>
      <Stack p={1.5}>
        <SearchInput
          placeholder={`${t("Symbol, pair")}`}
          startAdornment={<Iconify icon={"iconamoon:search-bold"} />}
        />
      </Stack>
      <Divider />
      <Scrollbar>
        {FilteredList.map((pair) => (
          <StyledPair
            onClick={() => handleSelectPair(pair)}
            direction={"row"}
            alignItems={"center"}
            key={pair.id}
            spacing={1}
          >
            <Stack spacing={0.5}>
              <Typography>{pair.label}</Typography>
              <Typography variant="caption" color={"text.disabled"}>
                {pair.marginType}
              </Typography>
            </Stack>
            <Box flexGrow={1} />
            <Stack direction={"row"} spacing={1}>
              <FavoriteButton pair={pair} disabled />
            </Stack>
          </StyledPair>
        ))}
      </Scrollbar>
    </Stack>
  );
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  height: "100%",
  fontSize: "1.2rem",
  color: theme.palette.text.secondary,
  padding: "0px 1rem",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  height: 35,
  background: theme.palette.action.disabledBackground,
  "& .iconify": {
    width: 20,
    height: 20,
    marginRight: "0.5rem",
    color: theme.palette.text.disabled,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const StyledPair = styled(Stack)(({ theme }) => ({
  padding: "0.6rem 0.8rem",
  cursor: "pointer",
  "& .MuiTypography-root": {
    fontWeight: 600,
  },
  "&:hover": {
    background: theme.palette.action.disabledBackground,
  },
  "& .iconify": {
    color: theme.palette.action.disabled,
    width: 20,
    height: 20,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.warning.main,
    },
  },
}));
