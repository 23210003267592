export enum ChainId {
    ETHEREUM = 1,
    GOERLI = 5,
    BSC = 56,
    BSC_TESTNET = 97,
    ZKSYNC_TESTNET = 280,
    ZKSYNC = 324,
    OPBNB_TESTNET = 5611,
    OPBNB = 204,
    POLYGON_ZKEVM = 1101,
    POLYGON_ZKEVM_TESTNET = 1442,
    ARBITRUM_ONE = 42161,
    ARBITRUM_GOERLI = 421613,
    ARBITRUM_SEPOLIA = 421614,
    SCROLL_SEPOLIA = 534351,
    LINEA = 59144,
    LINEA_TESTNET = 59140,
    BASE = 8453,
    BASE_TESTNET = 84531,
    BASE_SEPOLIA = 84532,
    SEPOLIA = 11155111
}


export const PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3'

export const MaxUint48 = BigInt('0xffffffffffff')
export const MaxUint160 = BigInt('0xffffffffffffffffffffffffffffffffffffffff')
export const MaxUint256 = BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')

// alias max types for their usages
// allowance transfer types
export const MaxAllowanceTransferAmount = MaxUint160
export const MaxAllowanceExpiration = MaxUint48
export const MaxOrderedNonce = MaxUint48

// signature transfer types
export const MaxSignatureTransferAmount = MaxUint256
export const MaxUnorderedNonce = MaxUint256
export const MaxSigDeadline = MaxUint256

export const InstantExpiration = BigInt(0)

export enum QueriesKey {
    UserEmail = 'UserEmail',
    MarginBalance = 'MarginBalance',
    UserData = 'UserData',
    TotalRef = 'TotalRef',
    CapitalYield = 'CapitalYield',
    CapitalInterest = 'CapitalInterest',
    UserCapitalVolume = 'UserCapitalVolume',
    TotalCommission = 'TotalCommission',
    UserQuoteBalance = 'UserQuoteBalance',
    TradePair = 'TradePair',
    TradingPosition = 'TradingPosition',
    TradingPair = 'TraingPair',
    OpenOrder = 'OpenOrder',
    TradingHistories = 'TradingHistories',
    UserPoint = 'UserPoint',
    CapitalProviderInfo = 'CapitalProviderInfo',
    TradingVolumeAnalysis = 'TradingVolumeAnalysis',

}
interface ErrorListType {
    [key: string]: string;
}

export const ErrorList: ErrorListType = {
    "1": "Invalid Super Admin",
    "2": "Invalid Isolated Margin",
    "3": "Invalid Cross Margin",
    "4": "Invalid Margin",
    "5": "Invalid Address Provider",
    "6": "Is Not Contract",
    "7": "Pair Already Added",
    "8": "Pair Not Added",
    "9": "Pair Not Dead",
    "10": "Super Admin Cannot Be Zero",
    "11": "Caller Not Isolated Admin",
    "12": "Caller Not Cross Admin",
    "13": "Caller Not Pair Admin",
    "14": "Caller Not Liquidator",
    "15": "Caller Not Executor Admin",
    "16": "Address Zero",
    "17": "Invalid Amount",
    "18": "Pair Is Dead",
    "19": "Pair 0 Not For Trading",
    "20": "Invalid Function Executor",
    "21": "Invalid Leverage",
    "22": "Exceed Maximum Value",
    "23": "Trader Has Not Deposited",
    "24": "Balance Insufficient",
    "25": "Caller Not Vault Admin",
    "26": "Too Little Input",
    "27": "Deadline Expired",
    "28": "Length Mismatched",
    "29": "Gas Fee Exceeded Balance",
    "30": "Caller Not Owner",
    "31": "Too Much Output",
    "32": "Margin Level Lower Than Liquidity Threshold",
    "33": "Trader Has Not Long",
    "34": "Trader Has Not Short",
    "35": "Trader Is Not Liquidatable",
    "36": "Margin Level Not Liquidatable",
    "37": "Amount Liquidated Too Large",
    "38": "Invalid Capital Provider",
    "39": "Caller Not Capital Provider Admin",
    "40": "Supply Cap Exceeded"
};
