import { alpha, Box, Hidden, Modal, ModalProps, Stack, styled, SwipeableDrawer, SwipeableDrawerProps } from "@mui/material";
import { FC } from "react";

// ---------------------------------------------------------

const AppModal: FC<ModalProps & SwipeableDrawerProps> = ({
  ...props
}) => {
  const handleClose = () => {

  }
  const handleOpen = () => {

  }
  return <>
    <Hidden mdDown>
      <Modal {...props}>
        <Wrapper className="modal-wrapper">
          <Stack className="container" position={'relative'} width={'100%'}>
            {
              props.children
            }
          </Stack>
        </Wrapper>
      </Modal>
    </Hidden>
    <Hidden mdUp>
      <SwipeableDrawerStyled
        {...props}
        anchor="bottom"
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: false,
        }}
      />
    </Hidden>
  </>;
};

export default AppModal;

const SwipeableDrawerStyled = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    paddingTop: '30px',
    borderRadius: '15px 15px 0px 0px',
    padding: '10px',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '10px',
      width: '50px',
      height: '5px',
      borderRadius: "5px",
      left: 'calc(50% - 25px)',
      background: alpha(theme.palette.text.disabled, 0.4)
    },

  }
}));

const Wrapper = styled(Stack)(({
  theme
}) => ({
  top: "50%",
  left: "50%",
  maxWidth: 500,
  width: "100%",
  borderRadius: "0px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  background: "linear-gradient(30deg,#FA00FF,#B580FF 15%,#FFFFFF 35%,#FFFFFF 80%,#4475F3)",
  clipPath: "polygon(40px 0px, 100% 0px,100% calc(100% - 40px), calc(100% - 40px) 100%,0px 100%, 0px 40px )",
  padding: "1px",
  '& .container': {
    clipPath: "polygon(40px 0px, 100% 0px,100% calc(100% - 40px), calc(100% - 40px) 100%,0px 100%, 0px 40px )",
    padding: 24,
    background: theme.palette.background.paper
  },
  '& form:focus': {
    outline: 'none !important',
  }
}));