import { Theme } from "@mui/material";

export const THEMES = { LIGHT: "light", DARK: "dark" };

export const lightTheme = (theme: Theme) => theme.palette.mode === "light";

export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

export const StatusColor = {
    "PENDING": '#FBC02C',
    "QUEUED": '#656A70',
    "SUCCESS": '#28CE88',
    "FAILED": '#F23445'
}
export const OrderLiveTime = [3, 7, 14, 30]

export const TIFOptions = [
    { key: 'GTC', label: 'GTC' },
    { key: 'GTD', label: 'GTD' },
]