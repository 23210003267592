import {
  ButtonBase,
  ButtonProps,
  Divider,
  Popover,
  Stack,
  styled,
} from "@mui/material";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import Iconify from "./Iconify";
import PopoverBase from "layouts/layout-parts/popovers/PopoverBase";

type sxProps = { btnSx?: ButtonProps["sx"] };
type AppSelect = { selectedValue?: any; options: any[]; renderButton?: any };

const AppSelect: FC<
  sxProps &
    AppSelect &
    React.DetailedHTMLProps<
      React.HTMLProps<HTMLSelectElement>,
      HTMLSelectElement
    >
> = ({
  children,
  btnSx,
  options,
  selectedValue,
  onSelect,
  renderButton,
  ...props
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState(
    selectedValue ?? options[0]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: any) => {
    setOptionSelected(value);
    handleClose();
  };

  useEffect(() => {
    if (onSelect) onSelect(optionSelected);
  }, [optionSelected]);

  useEffect(() => {
    if (selectedValue && selectedValue.key != optionSelected.key) {
      handleSelect(selectedValue);
    }
  }, [selectedValue]);

  return (
    <>
      <StyledButtonBase ref={anchorRef} sx={{ ...btnSx }} onClick={handleOpen}>
        {renderButton ? (
          renderButton(optionSelected)
        ) : (
          <>
            {optionSelected?.title ||
              optionSelected?.label ||
              optionSelected?.key}
          </>
        )}
        <Iconify
          icon="iconamoon:arrow-down-2-light"
          sx={{ width: 25, height: 25, marginLeft: "0.5rem", opacity: 0.6 }}
        />
      </StyledButtonBase>

      <PopoverBase anchorRef={anchorRef} open={open} onClose={handleClose}>
        <Stack minWidth={150} divider={<Divider />}>
          {options.map((item, index) => (
            <StyledItem onClick={() => handleSelect(item)} key={index}>
              {item?.title || item?.label || item?.key}
            </StyledItem>
          ))}
        </Stack>
      </PopoverBase>
    </>
  );
};

export default AppSelect;

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  height: "100%",
  fontSize: "1.2rem",
  color: theme.palette.text.secondary,
  padding: "0px 1rem",
  background: theme.palette.background.button,
  "&:hover": {
    background: theme.palette.background.buttonActive,
  },
}));

const StyledItem = styled(ButtonBase)(({ theme }) => ({
  height: 45,
  fontSize: "0.85rem",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));
