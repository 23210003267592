import { ButtonBase, Stack, styled, Typography } from "@mui/material"
import FlexRowAlign from "components/flexbox/FlexRowAlign"
import GradientBorder from "components/GradientBorder"
import { useState } from "react"
import { DepositForm } from "./DepositForm"
import { WithdrawForm } from "sections/Withdraw"
import { useAccount } from "wagmi"
import { WithdrawCapitalForm } from "./WithdrawalForm"

export const BalanceManagement = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const { address } = useAccount();
    const handleChangeTab = (index: number) => {
        setTabIndex(index)
    }
    return (
        <>
            <GradientBorder sx={{ width: { xs: "100%", md: "600px" } }} borderRadius={'0.7rem'}>
                <FlexRowAlign>
                    <StyledTabItem onClick={() => handleChangeTab(0)} className={tabIndex === 0 ? 'active' : ''}>
                        <Typography>Deposit</Typography>
                    </StyledTabItem>
                    <StyledTabItem onClick={() => handleChangeTab(1)} className={tabIndex === 1 ? 'active' : ''}>
                        <Typography>Withdraw</Typography>
                    </StyledTabItem>
                    {/* <StyledTabItem disabled onClick={() => handleChangeTab(2)} className={tabIndex === 2 ? 'active' : ''}>
                        <Typography>Swap</Typography>
                    </StyledTabItem> */}
                </FlexRowAlign>
                <Stack minHeight={300} p={{ xs: 2, sm: 4 }}>
                    {
                        tabIndex === 0 && <DepositForm />
                    }
                    {
                        tabIndex === 1 && <WithdrawCapitalForm address={`0x${address?.replace('0x', '')}`} />
                    }
                </Stack>
            </GradientBorder>
        </>
    )
}

const StyledTabItem = styled(ButtonBase)(({ theme }) => ({
    height: 45,
    fontWeight: 'bold',
    fontStyle: 'italic',
    background: 'rgba(255,255,255,0.1) !important',
    flex: 1,
    '&.active': {
        background: `${theme.palette.background.default} !important`

    }
}));