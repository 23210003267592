import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import SecondaryButton from "components/buttons/SecondaryButton";
import CapitalBalanceChart from "components/charts/CapitalBalanceChart";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import GradientText from "components/GradientText";
import Decimal from "decimal.js";
import { motion } from "framer-motion";
import useCapitalInfo from "hooks/useCapitalInfo";
import useCapitalInterest from "hooks/useCapitalInterest";
import useCapitalYield from "hooks/useCapitalYield";
import { BalanceManagement } from "sections/CapitalProvider/BalanceManagement";
import { fCurrency } from "utils/format";

export default function DepositCapitalProviderPage() {
  const capitalInfo = useCapitalInfo()

  const capitalInterest = useCapitalInterest()

  const currentAAveRate = new Decimal(
    capitalInfo?.usdcReserveData?.currentLiquidityRate?.toString() ?? 0
  )
    .div(Decimal.pow(10, 25)).toDecimalPlaces(2, Decimal.ROUND_UP)
    .toNumber();
  const capitalYield = useCapitalYield()

  const flaexRate = Decimal.max(new Decimal(capitalYield?.apy ?? 0 ).sub(currentAAveRate),0).toNumber();

  const total = new Decimal(currentAAveRate).add(flaexRate).toNumber();

  return (
    <Container sx={{ py: 10 }}>
      <Stack
        spacing={10}
        sx={{
          "& section": {
            position: "relative",
            scrollSnapAlign: "center",
          },
        }}
      >
        <FlexBetween gap={4} flexWrap={'wrap'} sx={{ justifyContent: 'center' }}>
          <Stack flex={1} maxWidth="564px" gap="48px" display="grid">
            <GradientText
              text="Deposit to Capital Provider Earn DOUBLE Yield + Points"
              background="linear-gradient(25deg,#FA00FF,#B580FF 25%,#FFFFFF 35%,#FFFFFF 80%)"
              fontSize={{ xs: 24, md: 30 }}
              fontWeight={600}
              textAlign={"center"}
            />
            <SecondaryButton
              sx={{
                borderRadius: "9px",
                padding: { xs: "17px 24px", md: "17px 34px" },
              }}
            >
              <FlexBetween width={"100%"}>
                <FlexBox
                  sx={{
                    gap: { xs: "6px", md: "16px" },
                    alignItems: "center",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                >
                  <Typography
                    fontSize={{ xs: 14, xdm: 20 }}
                    color="#BFBCCD"
                    fontWeight={600}
                  >
                    Total Yield
                  </Typography>
                  <FlexBox gap={2} alignItems={"center"}>
                    <img
                      src="/static/coins/USDC.svg"
                      style={{ width: 30, height: 30 }}
                      alt="usdc"
                    />
                    <Typography fontSize={{ xs: 14, xdm: 20 }} fontWeight={600}>
                      USDC
                    </Typography>
                  </FlexBox>
                </FlexBox>
                <Typography
                  fontSize={{ xs: 14, xdm: 24 }}
                  ml={1}
                  fontWeight={600}
                >
                  {total}%
                </Typography>
              </FlexBetween>
            </SecondaryButton>
            <FlexBetween gap={"24px"} width={"100%"}>
              <FlexBetween
                sx={{
                  flex: 1,
                  padding: "16px 24px",
                  border: "1px solid #fff",
                  borderRadius: "9px",
                }}
              >
                <FlexBox gap="12px" alignItems="center">
                  <Stack
                    sx={{
                      width: { xs: "26px", md: "38px" },
                      height: { xs: "26px", md: "38px" },
                    }}
                  >
                    <img
                      src="/static/coins/AAVE.svg"
                      style={{ width: "100%", height: "100%" }}
                      alt="aave"
                    />
                  </Stack>
                  <Typography
                    fontSize={{ xs: 12, md: 17 }}
                    fontWeight={600}
                    color="#BFBCCD"
                  >
                    AAVE
                  </Typography>
                </FlexBox>
                <Typography fontSize={{ xs: 12, md: 17 }} fontWeight={600}>
                  {currentAAveRate}%
                </Typography>
              </FlexBetween>
              <FlexBetween
                sx={{
                  flex: 1,
                  padding: "16px 24px",
                  border: "1px solid #fff",
                  borderRadius: "9px",
                }}
              >
                <FlexBox gap="12px" alignItems="center">
                  <Stack
                    sx={{
                      width: { xs: "26px", md: "38px" },
                      height: { xs: "26px", md: "38px" },
                    }}
                  >
                    <img
                      src="/static/coins/FLAEX.svg"
                      style={{ width: "100%", height: "100%" }}
                      alt="flaex"
                    />
                  </Stack>
                  <Typography
                    fontSize={{ xs: 12, md: 17 }}
                    fontWeight={600}
                    color="#BFBCCD"
                  >
                    FLAEX
                  </Typography>
                </FlexBox>
                <Typography fontSize={{ xs: 12, md: 17 }} fontWeight={600}>
                 {flaexRate}%
                </Typography>
              </FlexBetween>
            </FlexBetween>
            <motion.div
              style={{
                position: "absolute",
                width: "55%",
                height: "65%",
                top: 0,
                left: 0,
                zIndex: -1,
              }}
              initial={{
                filter: "blur(200px)",
              }}
              animate={{
                filter: `blur(15px)`,
              }}
              transition={{
                duration: 1,
              }}
            >
              <motion.img
                src="/logo.svg"
                style={{
                  width: "60%",
                  height: "60%",
                  position: "absolute",
                  top: "20%",
                  left: "20%",
                  objectFit: "contain",
                  zIndex: -1,
                  opacity: 0.15,
                  filter: "blur(15px)",
                  transform: "scale(1)",
                }}
                animate={{
                  rotate: [0, -360],
                }}
                transition={{
                  duration: 20,
                  ease: "linear",
                  times: [0, 1],
                  repeat: Infinity,
                }}
              />
            </motion.div>
          </Stack>
          <BalanceManagement />
        </FlexBetween>
        <Grid container>
          <Grid item xs={12} md={8}>
            <CapitalBalanceChart />
          </Grid>
          <Grid item xs={12} md={4} pl={{ xs: 0, md: 4 }} pt={{ xs: 4, md: 0 }}>
            <Stack justifyContent={'space-between'} height={'100%'} spacing={4}>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                py={{ xs: 2, md: 0 }}
                sx={{
                  flexGrow: 1,
                  borderRadius: '0.5rem',
                  border: "1px solid white",
                  background:
                    "linear-gradient(to bottom left, #915EFF00 35%, #6934FF81, #FA00FF)",
                  overflow: "hidden",
                }}
              >
                <Typography
                  fontSize={{ xs: 12, md: 16 }}
                  fontWeight={600}
                  color="#BFBCCD"
                  marginBottom="20px"
                  textTransform={{ xs: "none", md: "uppercase" }}
                >
                  Total Fl Token Minted
                </Typography>
                <Typography fontSize={{ xs: 14, md: 36 }} fontWeight={600}>
                  $ {fCurrency(capitalInterest?.totalCapital ?? 0,2)}
                </Typography>
              </Stack>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                py={{ xs: 2, md: 0 }}
                sx={{
                  flexGrow: 1,
                  borderRadius: '0.5rem',
                  border: "1px solid white",
                  background:
                    "linear-gradient(to bottom left, #915EFF00 35%, #6934FF81, #FA00FF)",
                  overflow: "hidden",
                }}
              >
                <Typography
                  fontSize={{ xs: 12, md: 16 }}
                  fontWeight={600}
                  color="#BFBCCD"
                  marginBottom="20px"
                  textTransform={{ xs: "none", md: "uppercase" }}
                >
                  open interest
                </Typography>
                <Typography fontSize={{ xs: 14, md: 36 }} fontWeight={600}>
                  $ {fCurrency(capitalInterest?.volume ?? 0,2)}
                </Typography>
              </Stack>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                py={{ xs: 2, md: 0 }}
                sx={{
                  flexGrow: 1,
                  borderRadius: '0.5rem',
                  border: "1px solid white",
                  background:
                    "linear-gradient(to bottom left, #915EFF00 35%, #6934FF81, #FA00FF)",
                  overflow: "hidden",
                }}
              >
                <Typography
                  fontSize={{ xs: 12, md: 16 }}
                  fontWeight={600}
                  color="#BFBCCD"
                  marginBottom="20px"
                  textTransform={{ xs: "none", md: "uppercase" }}
                >
                  health factor
                </Typography>
                <FlexBox gap="16px" alignItems="center" justifyContent="center">
                  <Box
                    sx={{
                      width: { xs: 24, md: 35 },
                      height: { xs: 24, md: 35 },
                      borderRadius: "100px",
                      background: "#68FF42",
                    }}
                  ></Box>
                  <Typography fontSize={{ xs: 14, md: 36 }} fontWeight={600}>
                  {fCurrency(capitalInterest?.heathFactor ?? 0,2)}
                  </Typography>
                </FlexBox>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack
          sx={{
            background: 'url("/static/layouts/background-grid.svg")',
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        ></Stack>
      </Stack>
    </Container>
  );
}
