import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import SnackBar from "layouts/layout-parts/SnackBar";
import { FC, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { deserialize, serialize } from "wagmi";
import Web3Provider from "web3/Web3Provider";
import "./i18n";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import { HelmetProvider } from "react-helmet-async";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import ReactGA from "react-ga4";

if (process.env.REACT_APP_IS_LOCAL !== "true") {
  ReactGA.initialize(process.env.REACT_APP_GA_FE_MEASUREMENT_ID || "DUMMY");
}

const App: FC = () => {
  const router = createBrowserRouter(routes());
  const { settings } = useSettings();

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  const persister = createSyncStoragePersister({
    serialize,
    storage: window.localStorage,
    deserialize,
  });

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackBar>
            <Web3Provider>
              <HelmetProvider>
                <RTL>
                  <CssBaseline />
                  <RouterProvider router={router} />
                </RTL>
              </HelmetProvider>
            </Web3Provider>
          </SnackBar>
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistQueryClientProvider>
  );
};

export default App;
